@import 'message-variables';

.message {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1350px;
    padding: $message__padding;
    margin: $message__margin;
    background-color: $message__background;
    border-color: $message__border-color;
    border-width: $message__border-width;
    border-style: $message__border-style;
    border-radius: $message__border-radius;
    color: $message__color;
    font-family: $message__font-family;
    font-size: $message__font-size;
    transition: $message__transition;

    &[style="display: block;"] {
        display: flex !important; // sass-lint:disable-line no-important
    }

    p {
        color: $message__color;
        font-size: $message__font-size;
        line-height: inherit;
        margin: 0;
    }

    @include mq($screen-m) {
        padding: $message__padding\@medium;
    }

    &:after {
        content: '\0021';
        position: relative;
        order: $message__icon-order;
        height: $message__icon-spacer;
        width: $message__icon-spacer;
        margin: $message__icon-margin;
        background: $orange;
        color: $white;
        font-weight: $font-weight-bold;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }

    &--success {
        background-color: $message__background--success;
        border-color: $message__border-color--success;
        color: $message__color--success;

        &:after {
            background: $secondary;
        }
    }

    &--error {
        background-color: $message__background--error;
        border-color: $message__border-color--error;
        color: $message__color--error;

        &:after {
            background: $primary;
        }
    }

    .link {
        text-decoration: underline;
    }
}

.messages {
    &__wrap {
        position: fixed !important;
        left: 0;
        z-index: 51;
    }
}

//extend messages generated in backend or 3rd party modules
.message-success {
    @extend .message--success;
}

.message-error {
    @extend .message--error;
}
