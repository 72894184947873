.page-layout-landings-pb {
    #maincontent {
        margin-top: 0;
        @include mq($screen-m) {
            margin-top: 107px;
            position: relative;
        }
    }
    .columns {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @include mq($screen-m) {
            flex-direction: row;
        }
    }
    .column.main {
        max-width: 100%;
        flex: initial;
        order: 1;
        @include mq($screen-m) {
            max-width: 73%;
        }
    }
    .sidebar.sidebar-additional {
        max-width: 100%;
        margin: 0;
        min-width: 22rem;
        flex: 1 0 25%;
        order: 1;
        @include mq($screen-m) {
            max-width: 23.5%;
            order: 0;
            margin-right: 2rem;
            margin-left: 3rem;
        }
    }
    .cms-page__content {
        padding: 0 1.5rem 3rem;
        @include mq($screen-m) {
            padding: 0 3rem 3rem;
        }
    }
    .heading--page.heading--page-full-width {
        margin: 2rem 0 0 0!important;
        text-align: center;
        @include mq($screen-m) {
            margin: 0!important;
            text-align: left;
        }
    }
    .quicksearch-pb {
        width: auto;
        padding: 24px;
        display: block;
        box-shadow: none;
        position: initial;
        background: $beige1;
    
        .quicksearch-heading-pb {
            @include h4;
            color: $primary;
            font-weight: 700;
        }
        .quicksearch-links-pb {
            margin-left: 6px;
            padding: $margin-s / 2 0;
    
            a {
                @include t3;
                font-size: $font-size-md;
                color: $primary;
                display: block;
                padding-bottom: 15px;
                text-decoration: none;
    
                &:hover {
                    color: $black;
                    font-weight: 700;
                }
                &:before {
                    content: url('../images/icons/arrow-down-gold.svg');
                    width: 10px;
                    height: 10px;
                    transform: rotate(-90deg);
                    display: inline-block;
                    vertical-align: bottom;
                    margin-right: 15px;
                }
            }
        }
    }
    
    #maincontent {
        div.info-price__conteiner {
            .category-pdp-like {
                &__custom-text {
                    font-weight: bold;
                    width: 100%;
                    text-align: center;
                    @include mq($screen-m) {
                        width: auto;
                        text-align: left;
                    }
                }
                &__attributes {
                    .product-view__attributes {
                        border: none;
                        padding: 0 0;
                        margin: 0;
                        margin-left: 30px;
                        ul {
                            list-style: none!important;
                            padding: 0;
                            margin: 0;
                            li {
                                font-size: 10px;
                                line-height: 1.6;
                                color: #333;
                                padding: 0 !important;
                                margin: 0 !important;
                                list-style-image: none !important;
                                * {
                                    font-size: 10px!important;
                                    line-height: 1.6!important;
                                    color: #333;
                                }
                                &::before,
                                &::marker {
                                    display: none!important;
                                    visibility: hidden!important;
                                    list-style-type: none;
                                }
                            }
                        }
                        .attribute-shipping {
                            font-weight: bold;
                            .attribute-label {
                                font-weight: normal;
                            }
                        }
                        .attribute-stock {
                            .attribute-value {
                                color: #229300;
                            }
                        }
                    }
                    &-wrapper {
                        display: flex!important;
                        align-items: center;
                        margin-left: 25px;
                        .product-view__price {
                            margin: 0;
                            display: block;
                            .product-view__price-unit {
                                margin: 0;
                                color: $gray6;
                                font-size: 1.6rem;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .datefinder {
        margin: 3rem 0 1rem 0;
        &-heading {
            @include h4;
            color: $white;
            margin: $margin-xs 0 0 $margin-s;
        }
        &-date {
            @include mq($screen-l) {
                .datefinder-top {
                    width: 100%;
                }
                .datefinder-picker__full {
                    width: 70%;
                }
            }
            @include mq($screen-xlg) {
                .datefinder-top {
                    width: 30%;
                }
                .datefinder-picker__full {
                    width: 45%;
                }
            }
        }
        @include mq($screen-l) {
            .datefinder-button {
                width: 20%;
            }
        }
        &-top {
            width: unset;
        }
    }
    
    
    .pagebuilder-mobile-hidden {
        display: none;
        @include mq($screen-l) {
            display: block;
        }
    }
    
    .pagebuilder-mobile-only {
        display: block;
        @include mq($screen-m) {
            display: none;
        }
    }

    .breadcrumbs.container {
        position: absolute;
        top: -107px;
        left: 0;
        width: 100%;
        padding: 3rem 0 3rem 3.2rem;
    }

    .slider--products {
        margin: 30px 0;
        .product-item {
            border: solid 2px $secondary;
            box-shadow: 0 4px 8px 0 rgba(199, 161, 122, 0.5);
            margin: 10px 60px;
            @include mq($screen-m) {
                margin: 10px;
            }
        }
        .product-grid-item__name {
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 5px;

            a {
                font-size: 18px;
                font-weight: 700;
                line-height: 26px;
                text-align: center;
                font-family: $font-open-sans;
                color: $black;
            }
        }
        .product-grid-item__details-bottom {
            display: block;
        }
        .product-grid-item__price {
            width: 100%;
        }
        .product-grid-item__actions {
            width: 100%;
            display: block;
        }
        .product-grid-item__primary-action {
            margin: 0;
            form {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }
        .ratio-container {
            aspect-ratio: 1 / 1;
            height: 100%;
        }
        .personalize {
            font-size: 1.2rem;
            margin-right: 0;
            padding: 0.4rem 4rem;
            margin-bottom: 0;
        }
        .button--add-to {
            background: $secondary;
            padding: 0 33px;
            margin-bottom: 0;
            &::after {
                display: none;
            }
        }
        .product-grid-item__image-wrapper:after {
            display: none!important;
        }
        .product-grid-item__details {
            padding: 0 10px;
            margin-bottom: 0;
        }
        .product-view__price-unit {
            margin: 0;
            line-height: 1.2rem;
        }
        .slider__nav-button.slick-arrow {
            border-radius: 50%;
            width: 25px;
            height: 25px;
            line-height: 48px;
            font-size: 0;
            background: url(../images/slider-arr.svg) center no-repeat;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            margin: 0;
            border: none;
            .slider__nav-icon {
                display: none;
            }
        }
        .slider__nav-button.slick-arrow.slider__prev {
            left: -35px;
            transform: translate(0, -50%) rotate(180deg);
        }
        .slider__nav-button.slick-arrow.slider__next {
            right: -35px;
        }
        .slider__dots li {
            margin: 0 0 0 1rem;
        }
        .slider__dots button {
            width: 8px;
            height: 8px;
            line-height: 8px;
            min-height: 8px;
            background: #ccc;
            margin: 0;
        }
        .slider__dots li.slick-active:before {
            background: $primary;
        }
    }
}