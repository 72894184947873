.banner {
    z-index: 0;
    .swiper-slide {
        img {
            width: $full-width;
            height: $full-width;
            object-fit: cover;
            aspect-ratio: 2.64 / 1;
        }
    }
}
