// Flex layout
@mixin flex($direction: column, $justify: center) {
    display: flex;
    justify-content: $justify;
    flex-direction: $direction;
}

// Typography

// Headings
@mixin h1 {
    color: $primary;
    font-size: 4rem;
    line-height: 5.6rem;
    font-family: $font-libre;
}

@mixin h2 {
    color: $white;
    font-size: 5.6rem;
    line-height: 7.2rem;
    font-family: $font-libre;
}

@mixin h3 {
    color: $primary;
    font-size: 4rem;
    line-height: 5.6rem;
    font-family: $font-libre;
}

@mixin h4 {
    font-size: 2rem;
    line-height: 3rem;
    font-family: $font-libre;
}

@mixin heading($primary) {
    font-family: $font-libre-bold;
    font-size: $font-size-xxlg;
    line-height: $line-height-xxl;
    color: $primary;
}
@mixin heading2($color) {
    font-family: $font-libre-bold;
    font-size: $font-size-lg;
    line-height: $line-height-extra-large;
    color: $color;
}

// Text
@mixin t2 {
    color: $white;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-family: $font-open-sans;
}

@mixin t3 {
    font-size: $font-size-normal;
    line-height: $line-height-normal;
    font-family: $font-open-sans;
}

@mixin paragraph {
    font-size: 1.4rem;
    line-height: $line-height-normal;
    font-family: $font-open-sans;
}

@mixin menu-typography {
    color: $white;
    margin: auto 0;
    font-weight: 700;
    font-size: 1.4rem;
}

// buttons
@mixin btn-text {
    color: $white;
    font-weight: $font-weight-bold;
    font-size: $font-size-md;
    text-align: center;
    line-height: 3.2rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    font-family: $font-open-sans-condensed;
}

@mixin  btn-primary {
    background: $primary;
    text-decoration: none;
    cursor: pointer;
    transition: $transition;
}

@mixin btn-primary-hover {
    background: $primary-hover;
    box-shadow: 0 0 0.5rem 0 $gray5;
    &::after {
        display: none;
    }
    a {
        text-decoration: none;
    }
}

@mixin  btn-secondary {
    background: $secondary;
    text-decoration: none;
    cursor: pointer;
    transition: $transition;
}

@mixin btn-secondary-hover {
    background: $secondary-hover;
    box-shadow: 0 0 0.5rem 0 $gray5;
    a {
        text-decoration: none;
    }
}

@mixin list-bullet {
    content: '';
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: $primary;
}

@mixin divider {
    border-bottom: solid 1px $secondary;
}

@mixin price ($size, $color) {
    font-family: $font-open-sans-condensed;
    font-size: $size;
    font-weight: bold;
    color: $color;
}

@mixin centerItem {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin infoIcon {
    content: 'i';
    width: 1.6rem;
    height: 1.6rem;
    font-family: monospace;
    font-weight: $font-weight-bold;
    font-size: $font-size-root;
    border: solid 1px #c7a17a;
    border-radius: 50%;
    color: #c7a17a;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem 0 0 0;
}

@mixin input {
    width: $full-width;
    height: 4rem;
    border: $border-gold-bold;
    background: $white;
    font-family: $font-open-sans;
    font-size: $font-size-lg;
}

@mixin placeholder {
    font-family: $font-open-sans-bold;
    font-size: $font-size-normal;
    color: $gray2;
}