.cms-jobs {
    #maincontent {
        margin: -2rem auto 0 auto;
    }
    .cms-page__content {
        padding: $padding-lg;
    }
    .breadcrumbs.container {
        padding: 0;
        @include mq($screen-l) {
            padding: $padding-xlarge 0 0 0;
        }
    }
    .cms-jobs__video {
        position: relative;
        width: $full-width;
        padding-bottom: 56.25%;
        iframe {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            max-width: $full-width;
            height: 100%;
            max-height: 100%;
            border: 0;
        }
    }
    hr {
        border: $border-gold;
        margin: $margin-xlarge 0;
    }
    h2 {
        margin: $margin-s 0;
    }
}
