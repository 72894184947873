$radio__size                    : 20px !default;
$radio__dot-size                : 10px !default;
$radio__margin                  : 0 0 $spacer--medium 0 !default;
$radio__label-font-size         : 1.2rem !default;
$radio__label-color             : black !default;
$radio__label-color-active      : black !default;
$radio__label-font-weight-active: $font-weight-normal !default;
$radio__label-line-height       : $font-line-height !default;
$radio__label-margin            : $spacer--small 0 !default;
$radio__icon-margin-right       : $spacer !default;
$radio__icon-border             : 2px solid #b21d2b !default;
$radio__icon-background         : #b21d2b !default;
$radio__icon-transition         : $transition-base !default;
$radio__legend-margin           : $radio__margin !default;
$radio__text-width              : calc(100% - #{$radio__icon-margin-right + $radio__size}) !default;
