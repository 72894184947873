.futurenext_zakeke-customize-add {
    #maincontent {
        max-width: 1368px;
        margin: 3rem auto 1rem auto;
        padding: 1rem 3rem;
    }
}

.zakeke-wrapper {
    position: relative;
    padding: 2rem 4rem;
    background: #f9f5f1;
    @include mq($screen-ml) {
        padding: 1.5rem 1.5rem 1.5rem 4.5rem;
    }

    &::before {
        background: url(../images/icons/options-before.svg) no-repeat;
        background-size: contain;
        content: "";
        height: 5.2rem;
        left: 1rem;
        position: absolute;
        top: 0;
        width: 2.4rem;
        z-index: 1;
    }

    .choice-field {
        margin: 1rem 0 1.5rem 0;
    }

    .control-choice {
        display: flex;
        align-items: stretch;

        @include mq($screen-s) {
            align-items: center;
            justify-content: flex-start;
        }
    }

    label.label {
        font-family: $font-open-sans;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 2.2rem;
        margin: 0 0 1rem!important;
        &::after {
            display: none;
        }
    }

    .choice-input {
        position: absolute;
        left: -99999px;
    }

    .choice-label {
        padding: 1rem 2rem;
        background: transparent;
        border: 2px solid $secondary;
        color: #777;
        font-family: $font-open-sans-condensed;
        font-size: 1.4rem;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        margin: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mq($screen-s) {
            height: auto;
            padding: 1rem 2.5rem;

        }
    }

    .control-choice{
        .choice {
            &:first-child {
                margin-right: -2px;
            }
        }
    }

    .choice-field .choice-input:checked + .choice-label {
        background-color: $secondary;
        color: #fff;
    }


    .choice-field .choice-input:checked + .choice-label {
        background-color: $secondary;
        color: #fff;
    }
}
.btn-primary#zakeke-customize-button {
    border: none;
    border-radius: 0;
    padding: 0;
    background-color: $primary;
    min-height: 4rem;
    height: 4rem;
    line-height: 4rem;
    margin: 0 1rem;
    font-size: 1.2rem;
    font-family: $font-open-sans-condensed;
    font-weight: 700;
    border-radius: 0;
    justify-content: center;

    &:hover {
        background-color: $primary-hover;
    }
}
#zakeke-customize-button {
    display: none;
    order: 1;
    min-width: 144px;
}
#zakeke-customize-button.active {
    display: flex;
}
.product-view__add-to-cart.hide {
    display: none!important;
}

// zakeke-cart
.checkout-cart-index {

    .group_products {
        display: none;
    }

    .cart_group_products {
        display: flex;
        flex-direction: column-reverse;
        border-top: 1px solid #ccc;
        padding-top: 2rem;
        @include mq($screen-ml) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr;
            padding-top: 3.5rem;
        }

        .group_products {
            display: block;
        }

        .cart-list-item {
            border: 1px solid #ccc;
            padding: 2.5rem;
            display: block;
            @include mq($screen-ml) {
                padding: 0 1.5rem;
                &:first-of-type {
                    order: 1;
                }
                &:first-of-type {
                    border-bottom: 0;
                    position: relative;
                    @include mq($screen-ml) {
                        border-left: 0;
                        border-bottom: 1px solid #ccc;
                    }
    
                    &::after {
                        content: ' + ';
                        display: block;
                        position: absolute;
                        width: 24px;
                        padding: 0 5px;
                        font-size: 22px;
                        line-height: 22px;
                        border-radius: 50%;
                        z-index: 5;
                        color: #252525;
                        border: 1px solid #ccc;
                        background: #fff;
                        bottom: -12px;
                        left: 50%;
                        transform: translate(-50%, 0);
    
                        @include mq($screen-ml) {
                            display: block;
                            left: -12px;
                            top: 50%;
                            transform: translate(0, -50%);
                            bottom: auto;
                            right: auto;

                        }
                    }
                }
            }

            &:nth-of-type(2) {
                border-bottom: 0;
                position: relative;
                @include mq($screen-ml) {
                    border-bottom: 1px solid #ccc;
                }

                &::after {
                    content: ' + ';
                    display: block;
                    position: absolute;
                    width: 24px;
                    padding: 0 5px;
                    font-size: 22px;
                    line-height: 22px;
                    border-radius: 50%;
                    z-index: 5;
                    color: #252525;
                    border: 1px solid #ccc;
                    background: #fff;
                    bottom: -12px;
                    left: 50%;
                    transform: translate(-50%, 0);

                    @include mq($screen-ml) {
                        display: none;
                    }
                }
            }

            &__left {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                @include mq($screen-ml) {
                    flex-direction: row;
                    align-items: flex-start;
                }
            }

            &__right {
                display: none;
            }

            &__content {
                margin: 0;
                flex-basis: calc(100% - 84px);
                padding-top: 2.5rem;
            }

            &__name {
                font-size: 1.7rem;
                line-height: 2.3rem;
                @include mq($screen-ml) {
                    font-size: 2rem;
                    line-height: 3rem;
                }
            }

            &__attributes {
                display: none;
            }

            &__price {
                font-size: 1.8rem;
                line-height: 2.4rem;
            }

            &__image {
                
            }

        }

        .cart-grid__button.action.update {
            display: none;
        }
    
    }

    .zakeke-cart-custom__footer {
        border-bottom: 1px solid #ccc;
        padding-bottom: 2rem;
        @include mq($screen-ml) {
            padding-bottom: 3.5rem;
        }

        .cart-list-item {
            &__qty {
                justify-content: flex-end;
                @include mq($screen-ml) {
                    justify-content: normal;
                }
                .cart-list-item__label {
                    margin: 0;
                }
            }
        }
        
    }

    .zakeke-cart-custom__footer-top {
        display: grid;
        grid-template-columns: 2fr 1fr;
        text-align: right;
        margin: 2.5rem 0;

        @include mq($screen-ml) {
            display: flex;
            justify-content: flex-end;
            gap: 0;
        }
    }

    h2#summary {
        display: none;
    }
}