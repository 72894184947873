html,
body {
    margin: 0;
    color: $black;
    font-size: 10px;
    font-family: "OpenSans", monospace;
    width: 100%;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

ol, ul {
    padding: 0;
    list-style: none;
}

input:focus,
input:active {
    border: 0;
    outline: 0;
}

figure {
    margin: auto 0 auto 0;
}

a {
    &:hover {
        color: initial;
    }
}

h1,
h2,
h3,
h4,
h5 {
    text-transform: none;
}

h1 {
    @include h1();
    font-weight: 700;
}

h3 {
    @include h3();
    font-weight: 700;
}

h4 {
    @include h4();
    font-weight: 700;
}

img {
    max-width: initial;
}

.page-wrapper {
    margin-bottom: 0;
}
.bold {
    font-weight: 700;
}
.primary-color {
    color: $primary;
}
.secondary-color {
    color: $secondary;
}
.white-color {
    color: $white;
}
.wrapper-v {
    @include flex(column, flex-start);
}
.wrapper-h {
    @include flex(row, flex-start);

    figure {
        margin: 0 8px 0 0;
    }
}
.favourite-products {
    background: #00000010;

    p {
        @include h3;
    }
}
main.container.homepage {
    margin: 0;
}
.container {
    max-width: 1428px;
}
.body-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-repeat: repeat;
    background-image: url("../images/bg-image.webp");
    background-color: $secondary;
    background-blend-mode: luminosity;
    z-index: -1;
}
.page-layout-landing-pages-3-col {
    .body-overlay {
        display: none;
    }
}
#maincontent {
    width: $full-width;
    max-width: $page-wrapper-container-width;
    background: $white;
    margin: 0 auto;
}

@media screen and (min-width: $screen-s) {
    main.container.homepage {
        width: 90%;
        margin: 0 auto;
    }
}
@media screen and (min-width: $screen-l) {
    main.container.homepage {
        width: 100%;
    }
}

#trustbadge-container-98e3dadd90eb493088abdc5597a70810, #usercentrics-button, #minimized-trustbadge-98e3dadd90eb493088abdc5597a70810 {
    z-index: 9 !important;
    right: 10px!important;
    bottom: 95px!important;
}

.umm-1alicbp {
    z-index: 10!important;
    right: 23px!important;
    bottom: 15px!important;
}

@media screen and (max-width: $screen-m) {

    body.catalog-product-view {
        .umm-1alicbp {
            right: 10px!important;
            bottom: 130px!important;
        }

        #trustbadge-container-98e3dadd90eb493088abdc5597a70810, #usercentrics-button, #minimized-trustbadge-98e3dadd90eb493088abdc5597a70810 {
            bottom: 130px!important;
        }
    }
}

@media screen and (max-width: $screen-sm) {
    h1 {
        font-size: 3rem;
        text-align: center;
    }
}

.block-category-link, .block-product-link, .trusted-shops__review-header-text {
    display: inline-block;

    a {
        color: $secondary !important;
        font-weight: bold;
    }
}

.block-category-link-inline, .block-product-link-inline {
    a {
        color: $secondary !important;
        font-weight: bold;
    }
    
}

a {
    text-decoration: none !important;
    font-family: OpenSans,sans-serif !important;
}
