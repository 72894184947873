.customer-address-form,
.customer-account-edit {
    .dashboard-nav__mobile {
        &::after {
            display: none;
        }
    }
    .dashboard-items__subtitle {
        margin: $margin-md 0;
        padding: 0 10rem $padding-normal 0;
    }
    .dashboard-form__divider {
        @include flex(column, flex-start);
        width: $full-width;
        flex-wrap: wrap;
    }
    .customer-name-prefix {
        margin: 0;
        &> label {
            display: none;
        }
    }
    .form__fieldset {
        width: $full-width;
        border: none;
        padding: 0;
    }
    .input {
        @include mq($screen-m) {
            @include flex(row, flex-start);
            align-items: center;
            flex-wrap: wrap;
        }
        ._has-datepicker {
            background: url('../images/icons/calendar@3x.svg') no-repeat 93% center;
            background-size: 2rem 2rem;
            @include mq($screen-m) {
                width: 26rem;
            }
        }
        &.new.password {
            flex-wrap: wrap;
        }
        &.confirmation.password {
            @include flex(column, flex-start);
            @include mq($screen-m) {
                @include flex(row, flex-start);
                align-items: center;
            }
        }
    }
    .input__label,
    .label {
        width: 16rem;
        font-family: $font-open-sans-bold;
        font-size: $font-size-normal;
        color: $black;
        &[for="city"] {
            width: auto;
            margin: 0 $margin-mxl 0 0;
        }
    }
    .mage-error {
        color: $primary;
        @include mq($screen-m) {
            margin: 0.8rem 0 0 0;
            width: $full-width;
        }
    }
    .checkbox__label {
        width: $full-width;
        &::after {
            background-color: $secondary;
        }
    }
    .required {
        label:not(.checkbox__label) {
            &::after {
                color: $primary;
            }
        }
    }
    .input__field {
        width: $full-width;
        max-width: $customer-input-width;
        font-family: $font-open-sans-bold;
        font-size: $font-size-normal;
        color: $black;
        height: $input-height;
        border: $border-gold;
        padding: $padding-sn;
        margin: 0;
        &[name="city"] {
            @include mq($screen-m) {
                max-width: 24rem;
            }
        }
        @include mq($screen-l) {
            max-width: $width-40;
        }
        @include mq($screen-xl) {
            max-width: 50rem;
        }
    }
    select {
        position: relative;
        width: $full-width;
        max-width: $customer-input-width;
        height: $input-height;
        margin: 0;
        border: $border-gold;
        appearance: none;
        font-family: $font-open-sans-bold;
        font-size: $font-size-normal;
        line-height: 1.2rem;
        background: $white url('../images/icons/menu-down.svg') no-repeat 95% center;
        background-size: 1rem;
        &[name="prefix"] {
            max-width: 26rem;
        }
    }
    .validate-zip-international {
        @include mq($screen-m) {
            max-width: 15rem;
        }
    }
    .actions-group {
        flex-flow: row nowrap;
        justify-content: flex-start;
        &__handler {
            margin: $margin-s $margin-m $margin-m 0;
        }
    }
    .save {
        @include btn-primary;
        font-family: $font-open-sans-condensed;
        font-weight: $font-weight-bold;
        font-size: $font-size-md;
        margin: 0;
        min-width: 16rem;
        &:hover,
        &:focus {
            @include btn-primary-hover;
        }
    }
    .back {
        font-family: $font-open-sans;
        font-size: $font-size-large;
        color: $primary;
        text-decoration: none;
    }
}
.customer-account-edit {
    .input__field {
        &[type="email"] {
            max-width: $full-width;
            @include mq($screen-m) {
                max-width: $customer-input-width;
            }
            @include mq($screen-l) {
                max-width: $width-40;
            }
            @include mq($screen-xl) {
                max-width: 50rem;
            }
        }
    }
}
.customer-address-form {
    .dashboard-form__divider {
        @include mq($screen-m) {
            @include flex(row, flex-start);
        }
    }
    .customer-name-prefix,
    .company,
    .telephone,
    .input {
        @include mq($screen-m) {
            width: $full-width;
        }
        .input__field {
            @include mq($screen-xl) {
                max-width: $customer-input-width;
            }
        }
    }
    .customer-name-prefix {
        .select {
            padding: $padding-sn;
        }
    }
    .zip {
        @include mq($screen-m) {
            width: 45%;
        }
        @include mq($screen-l) {
            width: $full-width;
        }
        @include mq($screen-xl) {
            width: 45%;
        }
        .input__field {
            @include mq($screen-l) {
                max-width: $width-40;
            }
            @include mq($screen-xl) {
                max-width: $width-15;
            }
        }
        @include mq($screen-xlg) {
            width: 35.5rem;
        }
    }
    .city {
        @include mq($screen-m) {
            width: 48%;
        }
        @include mq($screen-l) {
            width: $full-width;
        }
        @include mq($screen-xl) {
            width: 48%;
        }
        .label {
            @include mq($screen-xl) {
                margin: 0 4.2rem 0 0;
            }
        }
        .input__field {
            max-width: $full-width;
            @include mq($screen-m) {
                max-width: 28rem;
                margin: 0;
            }
            @include mq($screen-l) {
                max-width: $width-40;
                margin: 0 0 0 9rem;
            }
            @include mq($screen-xl) {
                max-width: $width-25;
                margin: 0;
            }
        }
    }
    .region,
    .country {
        .select--native {
            @include mq($screen-m) {
                min-width: $customer-input-width;
                width: $customer-input-width;
            }
            @include mq($screen-l) {
                min-width: $width-40;
                width: $width-40;
            }
            @include mq($screen-xl) {
                min-width: $customer-input-width;
                width: $customer-input-width;
            }        
        }
        .input__field {
            @include mq($screen-m) {
                margin: 0 0 0 16rem;
            }
        }
    }
    .checkbox {
        &.set {
            width: $full-width;
        }
    }
}
.customer-address-index {
    .modals-wrapper {
        width: 90%;
        max-width: 60rem;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $white;
        padding: $padding-xlarge $padding-md;
        box-shadow: $box-shadow;
    }
    .modal-content {
        font-size: $font-size-md;
    }
    .modal-footer {
        .button--secondary {
            @include btn-secondary;
            font-family: $font-open-sans-condensed;
            font-weight: $font-weight-bold;
            color: $white;
            border: none;
            margin: $margin-xlarge $margin-root 0 0;
            &:hover,
            &:focus {
                @include btn-secondary-hover;
            }
        }
        .button.action-primary {
            @include btn-primary;
            font-family: $font-open-sans-condensed;
            font-weight: $font-weight-bold;
            margin: 0;
            &:hover,
            &:focus {
                @include btn-primary-hover;
            }
        }
    }
    .modal__close-button {
        top: 0;
        right: 0;
        padding: 0;
        margin: 0;
        .button__icon {
            fill: $secondary;
        }
    }
}