
.page-layout-landing-pages-2-col,
.page-layout-landing-pages-3-col {
    #maincontent {
        max-width: 100%;
    }
    .heading--page {
        display: none;
    }
    div[data-appearance="full-width"] {
        width: 100%;
        padding: 0;
        margin: 0;
        position: relative;
        .row-full-width-inner {
            max-width: 1368px;
            width: 100%;
            padding: 0;
            margin: 0 auto;
        }
    }
    div[data-appearance="contained"] {
        max-width: 1368px;
        width: 100%;
        padding: 0;
        margin: 0 auto;
        position: relative;
    }
    div[data-content-type="row"] {
        padding: 3rem 1.5rem;
        @include mq($screen-s) {
            padding: 4rem 3rem;
        }
        @include mq($screen-xxl) {
            padding: 6rem 0;
        }
    }
    .text-col div[data-element="inner"]  {
        width: 100%;
        @include mq($screen-m) {
            width: 80%;
        }
        @include mq($screen-l) {
            width: 65%;
        }
        @include mq($screen-xxl) {
            width: 50%;
        }
    }
    .pagebuilder-column-line {
        gap: 1.5rem;
        flex-direction: column;
        @include mq($screen-xl) {
            gap: 2.5rem;
            flex-direction: row;
        }
        @include mq($screen-xxl) {
            gap: 4.5rem;
        }
        .pagebuilder-column {
            @include mq($max-screen: $screen-xl) {
                width: 100%!important;
            }
            @include mq($screen-m) {
                width: auto;
            }
        }
        .pagebuilder-column.mobile-first {
            @media (max-width: 600px) {
                order: -1;
            }
        }
    }
    .landings-desktop-only {
        display: none!important;
        @include mq($screen-m) {
            display: block!important;
        }
    }
    .landings-mobile-only {
        display: block!important;
        @include mq($screen-m) {
            display: none!important;
        }
    }
    .breadcrumbs.container {
        display: none!important;
        @include mq($screen-m) {
            max-width: 1368px;
            position: absolute;
            left: 50%;
            top: 45px;
            z-index: 1;
            width: 100%;
            transform: translate(-50%, 0);
            background: transparent;
            padding: 0;
        }
        .breadcrumbs__item::after {
            color: $black;
            content: url('../images/bread.svg');
            transform: rotate(-0);
            margin: 0px 10px -5px 10px;

        }
        .breadcrumbs__item, .breadcrumbs__item:last-child:not(:first-child) {
            color: $black;
        }
    }
    .cms-page__content {
        padding: 0;
        position: relative;
        p {
            margin-bottom: 1rem!important;
            @include mq($screen-m) {
                margin-bottom: 1.5rem!important;
            }
        }
        a {
            color: $primary;
            font-weight: 400;
            @include mq($screen-m) {
                font-weight: 600;
            }
        }
        h1 {
            font-family: $font-libre-bold;
            color: $primary;
            font-size: 2.4rem;
            line-height: 3rem;
            margin: 0 0 1rem 0!important;
            @include mq($screen-m) {
                font-size: 5rem;
                line-height: 6rem;
                margin: 0 0 3rem 0!important;
            }
        }
        h2 {
            color: $black;
            text-align: center;
            font-family: $font-libre-bold;
            font-size: 2.2rem;
            line-height: 3.2rem;
            margin-bottom: 2.3rem;
            @include mq($screen-m) {
                font-size: 3.5rem;
                line-height: 4.8rem;
                margin-bottom: 3rem;
            }
        }
        h3 {
            color: $black;
            font-family: $font-libre-bold;
            font-size: 1.8rem;
            line-height: 2.4rem;
            margin: 0 0 1rem 0;
            @include mq($screen-m) {
                font-size: 2.4rem;
                line-height: 3.2rem;
                margin: 0 0 2.4rem 0;
            }
        }
        li {
            padding-left: 5px!important;
        }
        ul, ol {
            li {
                color: $black;
                font-size: 1.4rem;
                line-height: 2.2rem;
                margin-bottom: 0.8rem;
                @include mq($screen-m) {
                    font-size: 1.8rem;
                    line-height: 3.2rem;
                }
                &::marker {
                    color: $primary;
                }
                * {
                    font-size: 1.4rem!important;
                    line-height: 2.2rem!important;
                    @include mq($screen-m) {
                        font-size: 1.8rem!important;
                        line-height: 3.2rem!important;
                    }
                }
            }

        }
        .list-disk {
            ul {
                list-style-type: disc;
                li {
                    list-style-image: none!important;
                }
            }
        }
        .h1,h2,h3 {
            &.birthday-title {
                text-align: left;
                padding: 0 0 0 65px;
                position: relative;
                margin-bottom: 16px;
                @include mq($screen-m) {
                    padding: 40px 0 40px 130px;
                }
                &::before {
                    content: '';
                    display: inline-block;
                    width: 48px;
                    height: 58px;
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    left: 0;
                    background: url('../images/birthday-after.png') center no-repeat;
                    background-size: contain;
                    @include mq($screen-m) {
                        width: 99px;
                        height: 118px;
                    }
                }
            }
        }
        div[data-content-type="button-item"] {
            display: block;
        }
        .pagebuilder-button-primary {
            background: $primary;
            color: $white;
            text-align: center;
            font-family:  $font-open-sans;
            font-size: 1.6rem;
            font-weight: 700;
            line-height: 2.4rem;
            text-transform: uppercase;
            &:hover {
                background: $primary;
            }
            &::before,
            &::after {
                display: none;
            }
        }
        .pagebuilder-button-secondary {
            background: $secondary;
            color: $white;
            text-align: center;
            font-family:  $font-open-sans;
            font-size: 1.6rem;
            font-weight: 700;
            line-height: 2.4rem;
            text-transform: uppercase;
            border-color: $secondary;
            &:hover {
                background: $secondary;
            }
            &::before,
            &::after {
                display: none;
            }
        }

    }
    .collaps-container {
        padding: 2.5rem 1.5rem!important;
        @include mq($screen-s) {
            padding: 3.5rem 3rem 3.5rem 3rem!important;
        }
        @include mq($screen-xxl) {
            padding: 6.5rem 0 9.5rem 0!important;
        }
    }
    .datefinder {
        position: relative;
        margin-top: 40px;
        margin-bottom: 0;
        @include mq($screen-m) {
            margin-top: 100px;
            margin-bottom: 0;
        }
        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            top: -36px;
            left: 50%;
            transform: translate(-50%,0);
            width: 72px;
            height: 72px;
            background: url(../images/date-finder-after.svg) center no-repeat;
            background-size: contain;
            @include mq($screen-m) {
                width: 112px;
                height: 112px;
                top: -56px;
            }
        }
        .datefinder-icon {
            display: none;
        }
        .datefinder-top {
            margin: 0;
        }
        .datefinder-heading {
            color: $white;
            text-align: center;
            font-family: $font-libre-bold;
            font-size: 2rem;
            line-height: 3.2rem;
            margin: 2.5rem 0 2.5rem 0;
            @include mq($screen-m) {
                font-size: 2.5rem;
                line-height: 4.8rem;
                margin: 4rem 0 2.2rem 0;
            }
        }
        .datefinder-picker.datefinder-picker__full {
            margin: 0;
            flex-direction: column;
            width: 100%;
            gap: 16px;
            margin-bottom: 16px;
            gap: 16px;
            @include mq($screen-m) {
                flex-direction: row;
                flex-wrap: nowrap;
                width: 100%;
                gap: 1rem;
                margin: 0;
            }
        }
        .datefinder-picker {
            margin: 0;
            flex-direction: column;
            width: 100%;
            gap: 16px;
            margin-bottom: 16px;
            gap: 16px;
            @include mq($screen-m) {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
                gap: 15px;
                margin: 0;
            }
            .datefinder-picker__year {
                margin-bottom: 15px;
                @include mq($screen-m) {
                    width: auto;
                    min-width: 25%;
                    margin: 0;
                }
            }
        }
        .datefinder-picker .datefinder-picker__select {
            width: 100%;
            @include mq($screen-m) {
                width: 100%;
            }
        }
        .datefinder-form .datefinder-button {
            width: 100%;
            margin: 0;
            @include mq($screen-m) {
                width: auto;
                min-width: 25%;
                flex-shrink: 0;
            }
        }
        .datefinder-form {
            display: flex;
            justify-content: center;
            flex-direction: row;
            column-gap: 4rem;
        }
        .datefinder-form.datefinder-form__full {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            column-gap: 0;
        }
        .datefinder-picker__year>select,
        .datefinder-picker .datefinder-picker__select > select {
            border: solid 2px #fff;
            color: #70787A;
            font-size: 1.6rem;
            line-height: 3.2rem;
            height: 4.8rem;
            background: #fff url(../images/arrow.svg) no-repeat 95% center;
        }

        .datefinder-button__submit {
            line-height: 4.8rem;
            padding: 0 1rem;
            font-size: 1.4rem;
        }
    }
    .datefinder-full-box {
        .datefinder {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            column-gap: 0;
            .datefinder-picker,
            .datefinder-picker.datefinder-picker__full {
                margin: 0;
                flex-direction: column;
                width: 100%;
                gap: 16px;
                margin-bottom: 16px;
                gap: 16px;
            }
            .datefinder-picker__year {
                width: 100%;
            }
            .datefinder-button {
                min-width: 100%;
            }
        }
    }
    .loader {
        position: fixed;
    }
    .product-sku__item {
        .product_price {
            display: none;
            width: 100%;
            text-align: right;
            margin-bottom: 1rem;
            @include mq($screen-m) {
                margin-bottom: 3rem;
            }
            .price {
                font-family: $font-open-sans-condensed;
                font-weight: bold;
                font-size: 2.5rem;
                line-height: 2.5rem;
                color: $primary;
            }
            .unit_price {
                width: 100%;
                font-family: $font-open-sans;
                font-weight: bold;
                font-size: 1.2rem;
                line-height: 1.8rem;
                text-align: right;
                color: #c0c0c0;
            }
        }
    }
    .product-sku__item:has(.product_price) {
        .product-sku__title {
            margin: 1rem 0!important;
        }
    }
    .with-price .product-sku__item {
        .product_price {
            display: block;
        }
    }

    .slider--products {
        margin: 30px 0;
        .product-item {
            border: none;
            box-shadow: none;
            margin: 0 1.5rem;
            padding: 0;
            &:hover {
                box-shadow: none;
                .product-item-info {
                    box-shadow: none;
                }
            }
        }
        .product-grid-item {
            border: none;
        }
        .product-grid-item__primary-action,
        .product-grid-item__rating {
            display: none;
        }
        .product-item-info__mark {
            top: 2px;
            right: auto;
            left: 10px;
            width: 24px;
            height: 40px;
        }
        .product-grid-item__name {
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 5px;
            min-height: auto;
            a {
                font-size: 1.8rem;
                font-weight: 400;
                line-height: 2.4rem;
                text-align: center;
                font-family: $font-open-sans;
                color: #1a1b1d;
            }
        }
        .price-box {
            .price__value {
                .product-view__price-unit {
                    margin: 0;
                    color: #7b7b7b;
                    font-size: 1.6rem;
                    font-family: $font-open-sans;
                    margin: 0;
                    line-height: 1.2rem;
                }
            }
        }
        .product-grid-item__details-bottom {
            display: block;
        }
        .product-grid-item__actions {
            display: none;
        }
        .product-grid-item__price {
            width: 100%;
        }
        .product-grid-item__actions {
            width: 100%;
            display: block;
        }
        .product-grid-item__primary-action {
            margin: 0;
            form {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }
        .ratio-container {
            aspect-ratio: 1 / 1;
            height: 100%;
        }
        .personalize {
            font-size: 1.2rem;
            margin-right: 0;
            padding: 0.4rem 4rem;
            margin-bottom: 0;
        }
        .button--add-to {
            background: $secondary;
            padding: 0 33px;
            margin-bottom: 0;
            &::after {
                display: none;
            }
        }
        .product-grid-item__secondary-action {
            top: 10px;
            z-index: 1;
            right: 5px;
            left: auto;
            position: absolute;
            padding: 0;
            .add-to-wishlist {
                display: block;
                padding: 0;
                min-width: auto;
                min-height: auto;
                margin: 0;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.69);
                .button__icon {
                    margin: auto;
                }
            }
        }
        .product-grid-item__image-wrapper:after {
            display: none!important;
        }
        .product-grid-item__details {
            padding: 0;
            margin-bottom: 0;
        }
        .slider__nav-button.slick-arrow {
            border-radius: 50%;
            width: 25px;
            height: 25px;
            line-height: 48px;
            font-size: 0;
            background: url(../images/slider-arr.svg) center no-repeat;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            margin: 0;
            border: none;
            .slider__nav-icon {
                display: none;
            }
        }
        .slider__nav-button.slick-arrow.slider__prev {
            left: -35px;
            transform: translate(0, -50%) rotate(180deg);
        }
        .slider__nav-button.slick-arrow.slider__next {
            right: -35px;
        }
        .slider__dots li {
            margin: 0 0 0 1rem;
        }
        .slider__dots button {
            width: 8px;
            height: 8px;
            line-height: 8px;
            min-height: 8px;
            background: #ccc;
            margin: 0;
        }
        .slider__dots li.slick-active:before {
            background: $primary;
        }
    }

    ol.product-items {
        padding: 0;
        margin: 0;
        .slick-list {
            width: 100%;
            padding: 0 1rem;
        }
        .slick-arrow {
            border-radius: 50%;
            width: 48px;
            height: 48px;
            line-height: 48px;
            font-size: 0;
            background: #F5E6D3 url("../images/slider-arr.svg") center no-repeat;
            position: absolute;
            top: 50%;
            transform: translate(0,-50%);
            margin: 0;
        }
        .slick-dots {
            display: flex;
            justify-content: center;
            align-items: center;
            li {
                list-style: none;
                margin: 0 8px;
                button {
                    font-size: 0;
                    width: 8px;
                    height: 8px;
                    line-height: 8px;
                    min-height: 8px;
                    border-radius: 50%;
                    padding: 0;
                    background-color: $white;
                    border: 1px solid #D8D8D8;
                }
            }
            li.slick-active button {
                background-color: $primary;
                border: 1px solid $primary;
            }
        }
        .slick-next {
            left: 100%;
        }
        .slick-prev {
            right: 100%;
            transform: translate(0,-50%) rotate(180deg);
        }
        .product-item-details {
            padding: 0;
        }
        .product-item {
            border: none;
            box-shadow: none;
            margin: 0 1.5rem;
            padding: 0!important;
            &:hover {
                box-shadow: none;
                .product-item-info {
                    box-shadow: none;
                }
            }
            .product-item-inner {
                display: block;
            }
            .product-item-name {
                margin: 1.3rem 0 1rem 0;
                justify-content: center;
                a {
                    font-size: 1.6rem;
                    line-height: 2.2rem;
                    font-weight: 400;
                    text-align: center;
                    color: #1a1b1d;
                    @include mq($screen-m) {
                        font-size: 1.8rem;
                        line-height: 2.4rem;
                    }
                }
            }
            .product-item-details .price-final_price {
                text-align: right;
                margin-bottom: 0.5rem;
                .price__value * {
                    font-size: 2.5rem!important;
                    line-height: 2.4rem!important;
                }
            }
            .product-item-details .price-final_price .product-view__price-unit * {
                margin: 0;
                color: #7b7b7b;
                font-size: 1.6rem!important;
                line-height: 1.2rem!important;
                font-family: $font-open-sans;
            }
            .product-item-inner {
                display: none!important;
            }
        }
        .product-item-photo {
            .lazyload-wrapper {
                min-height: auto;
            }
            picture {
                width: 100%;
            }
            img {
                max-width: none!important;
                width: 100%;
                margin: 0;
                border: none;
                @include mq($screen-m) {
                    max-width: max-content;
                }
            }
        }
        .product-item-info {
            width: 100%;
        }
        .product-item-info__mark {
            top: 2px;
            right: auto;
            left: 10px;
            width: 24px;
            height: 40px;
        }
        .actions-secondary {
            top: 10px;
            z-index: 1;
            right: 5px;
            left: auto;
            position: absolute;
            padding: 0;
            .button--tocompare {
                display: none;
            }
            .action.towishlist {
                display: inline-flex;
                padding: 0;
                min-width: auto;
                min-height: auto;
                margin: 0;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.69);
                .button__icon {
                    margin: auto;
                }
            }
        }
    }
    // ***** new-categories-pages *****
    &.catalog-category-view {
        .category-image {
            display: none;
        }
        .category-pdp-like {
            &__wrapper {
                width: 100%;
            }
            &__header {
                display: none;
            }
        }
        #maincontent {
            padding: 0!important;
        }
        h1[data-content-type="heading"] {
            font-family: $font-libre-bold;
            color: $primary;
            font-size: 2.4rem;
            line-height: 3rem;
            margin: 0 0 1rem 0!important;
            @include mq($screen-m) {
                font-size: 5rem;
                line-height: 6rem;
                margin: 0 0 3rem 0!important;
            }
        }
        div.row-bottom .category-seo-text,
        div.category-description {
            .datefinder .datefinder-heading {
                color: $white;
                text-align: center;
                font-family: $font-libre-bold;
                font-size: 2rem;
                line-height: 3.2rem;
                margin: 2.5rem 0 2.5rem 0;
                @include mq($screen-m) {
                    font-size: 2.5rem;
                    line-height: 4.8rem;
                    margin: 4rem 0 2.2rem 0;
                }
            }
            p {
                font-family: $font-open-sans;
                color: $black;
                font-size: 1.4rem;
                line-height: 2.2rem;
                margin-bottom: 1rem!important;
                @include mq($screen-m) {
                    font-size: 1.8rem;
                    line-height: 2.6rem;
                    margin-bottom: 1.5rem!important;
                }
            }
            a {
                color: $primary;
                font-weight: 400;
                @include mq($screen-m) {
                    font-weight: 600;
                }
            }
            h1 {
                font-family: $font-libre-bold;
                color: $primary;
                font-size: 2.4rem;
                line-height: 3rem;
                margin: 0 0 1rem 0!important;
                @include mq($screen-m) {
                    font-size: 5rem;
                    line-height: 6rem;
                    margin: 0 0 3rem 0!important;
                }
            }
            h2 {
                color: $black;
                text-align: center;
                font-family: $font-libre-bold;
                font-size: 2.2rem;
                line-height: 3.2rem;
                margin-bottom: 2.3rem;
                @include mq($screen-m) {
                    font-size: 3.5rem;
                    line-height: 4.8rem;
                    margin-bottom: 3rem;
                }
            }
            .category-seo-text__header {
                text-align: left;
            }
            h3 {
                color: $black;
                font-family: $font-libre-bold;
                font-size: 1.8rem;
                line-height: 2.4rem;
                margin: 0 0 1rem 0;
                @include mq($screen-m) {
                    font-size: 2.4rem;
                    line-height: 3.2rem;
                    margin: 0 0 2.4rem 0;
                }
            }
            li {
                padding-left: 5px!important;
            }
            ul li {
                font-family: $font-open-sans;
                list-style-image: url("../images/icons/check.svg");
                p {
                    margin: 0;
                }
            }
            ul, ol {
                li {
                    color: $black;
                    font-size: 1.4rem;
                    line-height: 2.2rem;
                    margin-bottom: 0.8rem;
                    @include mq($screen-m) {
                        font-size: 1.8rem;
                        line-height: 3.2rem;
                    }
                    &::marker {
                        color: $primary;
                    }
                    &::before {
                        display: none;
                    }
                    * {
                        font-size: 1.4rem!important;
                        line-height: 2.2rem!important;
                        @include mq($screen-m) {
                            font-size: 1.8rem!important;
                            line-height: 3.2rem!important;
                        }
                    }
                }
    
            }
            .list-disk {
                ul {
                    list-style-type: disc;
                    li {
                        list-style-image: none!important;
                    }
                }
            }
        }
        .category-pdp-like__wrapper {
            .gift-matcher,
            .category-pdp-like__attributes-wrapper {
                display: none!important;


            }
        }
        .row-bottom {
            max-width: 1368px;
            width: 100%;
            padding: 0;
            margin: 0 auto;
            position: relative;
            .quick-search {
                margin-right: 0;
            }
            .quick-search__links a {
                color: $black;
                font-weight: 400;
                font-size: 1.4rem;
            }
        }
    }
    .info-price__conteiner {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        justify-content: center;
        margin: 15px 0;
        @include mq($screen-m) {
            justify-content: flex-start;

        }
    }
    .category-pdp-like {
        &__custom-text {
            font-weight: bold;
            width: 100%;
            text-align: center;
            @include mq($screen-m) {
                width: auto;
                text-align: left;
            }
        }
        &__attributes {
            .product-view__attributes {
                border: none;
                padding: 0 0;
                margin: 0;
                margin-left: 30px;
                ul {
                    list-style: none!important;
                    padding: 0;
                    margin: 0;
                    li {
                        font-size: 10px;
                        line-height: 1.6;
                        color: #333;
                        padding: 0 !important;
                        margin: 0 !important;
                        list-style-image: none !important;
                        * {
                            font-size: 10px!important;
                            line-height: 1.6!important;
                            color: #333;
                        }
                        &::before,
                        &::marker {
                            display: none!important;
                            visibility: hidden!important;
                            list-style-type: none;
                        }
                    }
                }
                .attribute-shipping {
                    font-weight: bold;
                    .attribute-label {
                        font-weight: normal;
                    }
                }
                .attribute-stock {
                    .attribute-value {
                        color: #229300;
                    }
                }
            }
            &-wrapper {
                display: flex!important;
                align-items: center;
                margin-left: 25px;
                .product-view__price {
                    margin: 0;
                    display: block;
                    .product-view__price-unit {
                        margin: 0;
                        color: $gray6;
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }
    #maincontent {
        div.info-price__conteiner {
            .category-pdp-like {
                &__custom-text {
                    font-weight: bold;
                    width: 100%;
                    text-align: center;
                    @include mq($screen-m) {
                        width: auto;
                        text-align: left;
                    }
                }
                &__attributes {
                    .product-view__attributes {
                        border: none;
                        padding: 0 0;
                        margin: 0;
                        margin-left: 30px;
                        ul {
                            list-style: none!important;
                            padding: 0;
                            margin: 0;
                            li {
                                font-size: 10px;
                                line-height: 1.6;
                                color: #333;
                                padding: 0 !important;
                                margin: 0 !important;
                                list-style-image: none !important;
                                * {
                                    font-size: 10px!important;
                                    line-height: 1.6!important;
                                    color: #333;
                                }
                                &::before,
                                &::marker {
                                    display: none!important;
                                    visibility: hidden!important;
                                    list-style-type: none;
                                }
                            }
                        }
                        .attribute-shipping {
                            font-weight: bold;
                            .attribute-label {
                                font-weight: normal;
                            }
                        }
                        .attribute-stock {
                            .attribute-value {
                                color: #229300;
                            }
                        }
                    }
                    &-wrapper {
                        display: flex!important;
                        align-items: center;
                        margin-left: 25px;
                        .product-view__price {
                            margin: 0;
                            display: block;
                            .product-view__price-unit {
                                margin: 0;
                                color: $gray6;
                                font-size: 1.6rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .cms-page__content {
        div[data-content-type="row"]:has(.delivery-footnotes) {
            width: $full-width;
            max-width: $container-desktop-width;
            border-top: $border-gold;
            margin: $margin-mxl auto 0 auto;
            padding: $padding-l;
            @include mq($screen-xlg) {
                padding: $padding-l 0;
            }
            a {
                color: $primary;
                text-decoration: none;
                &:hover,
                &:focus {
                    color: $primary-hover-darker;
                    text-decoration: none;
                }
            }
            .price-footnotes {
                p {
                    @include t3;
                    font-weight: $font-weight-bold;
                    margin-bottom: $margin-root;
                }
                a {
                    font-weight: $font-weight-bold;
                    color: $primary;
                    &:hover,
                    &:focus {
                        color: $primary-hover-darker;
                        text-decoration: none;
                    }
                }
            }
            .delivery-footnotes {
                p {
                    @include t3;
                }
            }
        }
    }
}

.page-layout-landing-pages-3-col {
    .cms-page__content {
        p {
            font-family: $font-open-sans;
            color: $black;
            font-size: 1.4rem;
            line-height: 2.2rem;
            margin: 0;
            @include mq($screen-m) {
                font-size: 1.8rem;
                line-height: 2.6rem;
            }
        }
        ol {
            list-style: auto;
            margin-top: 0;
        }
    }
}

.page-layout-landing-pages-2-col {
    .cms-page__content {
        .pagebuilder-column-line {
            flex-direction: column;
            column-gap: 1rem;
            @include mq($screen-m) {
                flex-direction: row;
                column-gap: 3rem;
            }
            .pagebuilder-column:has(.creative-gift) {
                order: -1;
                @include mq($screen-m) {
                    order: 0;
                }
            }
            &:has(.creative-gift) {
                gap: 15px;
                @include mq($screen-m) {
                    gap: 65px;
                }
            }
            &:has(.original-gift-top),
            &:has(.original-gift-bottom) {
                gap: 15px;
                @include mq($screen-m) {
                    gap: 90px;
                }
            }
        }
        p {
            color: $black;
            font-family: $font-open-sans;
            font-size: 1.4rem;
            line-height: 2.2rem;
            margin: 0 0 1rem 0;
            @include mq($screen-m) {
                font-size: 1.8rem;
                line-height: 3.2rem;
                margin: 0 0 2.4rem 0;
            }
        }
        ul {
            list-style: disc;
            margin-top: 0;
        }
        ol {
            list-style: auto;
            margin-top: 0;
        }
        ul, ol {
            li {
                color: $black;
                font-size: 1.4rem;
                line-height: 2.2rem;
                margin-bottom: 0.8rem;
                // list-style-image: none;
                @include mq($screen-m) {
                    font-size: 1.8rem;
                    line-height: 3.2rem;
                }
                &::marker {
                    color: $primary;
                }
            }
        }
    }
}

.product-grid-admin {
    margin: 15px 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 0.8rem;
    overflow-y: hidden;
    overflow-x: auto;
    @include mq($screen-s) {
        gap: 1.6rem;
        padding: 0 0 15px 0;
    }
    @include mq($screen-l) {
        display: block;
    }
    .product-item {
        min-width: 80%;
        margin: 0 0 4rem 0!important;
        display: flex!important;
        flex-grow: inherit;
        flex-basis: auto;
        @include mq($screen-s) {
            min-width: 40%;
            margin: 0 1.6rem!important;
        }
        @include mq($screen-l) {
            display: inline-block!important;
            min-width: 22.5%;
            width: 22.5%;
            margin: 0 1% 1% 1%!important;
        }
        .product-grid-item__image-wrapper {
            border: 0.5px solid #e8e8e8;
        }
    }
    &::-webkit-scrollbar {
        height: 8px;
        background-color: #f8f8f8;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $primary;
        border-radius: 20px;
    }
}

body > h4.reviews__heading {
    display: none;
}
