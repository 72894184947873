.two-icon-txt {
    // padding: 40px 0;
    @include mq($screen-m) {
        // padding: 100px 0;
    }
    h2 {
        margin-bottom: 3rem!important;
        @include mq($screen-m) {
            margin-bottom: 4rem!important;
        }
    }
    &__head_titles {
        text-align: center;
    }
    &__list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 25px;
        @include mq($screen-m) {
            flex-direction: row;
            gap: 96px;
        }
    }
    &__item {
        margin: 0;
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        flex-wrap: wrap;
        justify-content: flex-start;

        @include mq($screen-m) {
            flex-wrap: nowrap;
            justify-content: flex-start;
        }

        h5 {
            font-family: $font-open-sans;
            font-weight: 600;
            text-align: left;
            margin: 0;
            font-size: 1.6rem;
            line-height: 2.4rem;


        
            @include mq($screen-m) {
                font-size: 2rem;
                line-height: 3.2rem;
                margin: 0 0 1rem 0;
            }
        }
        p {
            text-align: left;
            margin: 0;
            font-size: 1.4rem!important;
            line-height: 2.2rem!important;        
            @include mq($screen-m) {
                font-size: 1.6rem!important;
                line-height: 2.4rem!important;
                margin: 0!important;
            }
        }
    }
    &__img-wrapper {
        border-radius: 50%;
        padding: 13px;
        background-color: $white;
        flex-shrink: 0;
        @include mq($screen-m) {
            padding: 28px;
        }
        img {
            width: 32px;
            @include mq($screen-m) {
                width: 48px;
            }
            height: auto;
            display: block;
            margin: auto;
        }
    }
    &__txt-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 70%;
        @include mq($screen-m) {
            max-width: none;
        }

        >p {
            display: none;
            @include mq($screen-m) {
                display: flex;
            }
        }
    }
    &__only-mob {
        display: block;
        width: 100%;
        @include mq($screen-m) {
            display: none;
        }
        p {
            max-width: none;
        }
    }
}