.three-icon-txt {
    // padding: 40px 0;
    @include mq($screen-m) {
        // padding: 100px 0;
    }
    h2 {
        margin-bottom: 3rem!important;
        @include mq($screen-m) {
            margin-bottom: 4rem!important;
        }
    }
    &__head_titles {
        text-align: center;
    }
    &__list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 25px;
        @include mq($screen-l) {
            flex-direction: row;
            gap: 96px;
        }
    }
    &__item {
        margin: 0;
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        h5 {
            font-family: $font-open-sans;
            font-weight: 600;
            text-align: left;
            margin: 0 0 1rem 0;
            font-size: 1.6rem;
            line-height: 2.4rem;
            @include mq($screen-m) {
                font-size: 2rem;
                line-height: 3.2rem;
            }
        }
        p {
            text-align: left;
            margin: 0;
            max-width: 250px;
            width: 100%;
            font-size: 1.6rem;
            line-height: 2.4rem;
            @include mq($screen-m) {
                font-size: 2rem;
                line-height: 3.2rem;
            }
        }
    }
    &__img-wrapper {
        border-radius: 50%;
        padding: 13px;
        background-color: $white;
        @include mq($screen-m) {
            padding: 28px;
        }
        img {
            width: 40px;
            @include mq($screen-m) {
                width: 48px;
            }
            height: auto;
            display: block;
            margin: auto;
        }
    }
}