.footer {
    @include flex(row, space-between);
    border: 0;
    width: 100%;
    flex-shrink: 1;
    flex-wrap: wrap;
    margin-top: 40px;
    background: $white;
    z-index: 1;

    &__links {
        padding: 0 1.6rem;
        width: 100%;
        max-width: 900px;
        padding: 0 1.6rem;
        @include mq($screen-m) {
            padding: 0
        }

        ul {
            @include flex(row, space-between);
            border: 0;
            width: 100%;
            flex-wrap: wrap;
            background: $white;
            @include mq($screen-m) {
                background: $primary;
            }

            li {
                width: 50%;
                margin: 0;
                justify-content: flex-start;
                @include mq($screen-m) {
                    width: 100%;
                    margin-bottom: 5px;
                }
            }
        }
        a {
            font-size: 1.4rem;
            line-height: 3.2rem;
            color: $black;
            margin: 0;
            padding: 0;
            font-weight: 400;
            @include mq($screen-m) {
                font-size: 1.2rem;
                font-weight: bold;
                padding: 0 5px 0 5px;
                color: $white;
            }
        }
    }
    &__main-content {
        @include flex(row, space-around);
        flex-wrap: wrap;
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        margin: 0 auto 0 auto;
        width: 100%;
        max-width: $container-desktop-width;

        @include mq($screen-m) {
            padding-left: 4rem;
            padding-right: 4rem;
            padding-top: 2rem;

        }

        p,
        a,
        li {
            @include t3;
        }
        a {
            margin: 0;
            padding: 0;
            text-decoration: none;
        }
        ul {
            @include flex(row, center);
            flex-wrap: wrap;

            li {
                display: flex;
                min-width: 100px;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        .wrapper-h {
            margin-bottom: 0;
            padding: 1rem 0;
            @include mq($screen-m) {
                margin-bottom: 2rem;
                padding: 0;
            }

            figure {
                margin: 0rem 0.8rem 0 0;
                @include mq($screen-m) {
                    margin: 0.4rem 0.8rem 0 0;
                }
            }
            h4 {
                font-size: 1.4rem;
                line-height: 2.2rem;
                @include mq($screen-m) {
                    @include h4;
                    display: flex;
                    margin-bottom: 0;
                }
                
            }
        }
    }
    &__delivery,
    &__advantages,
    &__payment,
    &__history,
    &__hotline {
        margin: 0;
        text-align: center;
        min-width: $footer-info-min-width;
        width: 100%;
        padding: 0 1.6rem;
        border-bottom: 1px solid #D8D8D8;
        @include mq($screen-m) {
            margin: 4rem 0;
            width: auto;
            border: none;
            padding: 0;
        }

        .wrapper-h {
            justify-content: center;

            @include mq($screen-m) {
                justify-content: flex-start;
            }
        }
        .wrapper-hl {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 0.5rem;
            padding: 1rem 0;
            figure {
                margin: 0.4rem 0.8rem 0 0;
            }
        }

    }
    &__hotline {
        order: 1;

        ul {
            margin-right: 2rem;
        }

        > a {
            display: none;
            @include mq($screen-m) {
                display: inline-block;
            }
        }

        .contact-form-button {
            display: none;
            @include mq($screen-m) {
                display: block;
            }
        }
    }
    &__advantages {
        order: 2;

        ul {
            @include flex(column, flex-start);

            li {
                @include flex(row, center);
                margin-bottom: 1rem;

                figure {
                    margin-right: 1rem;
                }
            }
        }
    }
    &__delivery {
        order: 3;

        figure {
            margin-bottom: 10px;
        }
    }
    &__payment {
        order: 4;

        li {
            padding: 1rem 0;
        }
    }
    &__history {
        order: 5;

        .wrapper-h {
            margin-top: 0.5rem;
        }
        > a {
            display: none;
            @include mq($screen-m) {
                display: block;
            }
        }
    }
    &__bottom {
        width: 100%;
        background: $white;
        @include mq($screen-m) {
            background: $primary; 
        }

        &-container {
                font-size: 1.4rem;
                line-height: 3.2rem;
                color: $black;
                flex-wrap: wrap;
                font-weight: 400;
                margin: 0 auto 0 auto;
                max-width: $container-desktop-width;
                @include mq($screen-m) {
                    @include t3;
                    @include flex(row, space-around);
                    color: $white;
                    flex-wrap: wrap;
                    font-weight: bold;
                    margin: 0 auto 0 auto;
                    max-width: $container-desktop-width;
                }

            * {
                display: flex;
                justify-content: center;
                margin: 5px auto 5px auto;
            }
            .copyright {
                font-size: 1.4rem;
                line-height: 2.2rem;
                width: 100%;
                min-height: 40px;
                background: #E5E5E5;
                margin-bottom: 0;
                display: flex;
                @include mq($screen-m) {
                    @include t3;
                    width: 100%;
                    min-height: 40px;
                    background: $primary-hover;
                    margin-bottom: 0;
                    display: flex;
                }

                span {
                    margin: auto;
                }
            }
        }
    }

    &__info {
        display: block;
        padding: 1.6rem;
        background: #E5E5E5;
        
        @include mq($screen-m) {
            display: none;
        }

        .info-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 1rem;
            column-gap: 1rem;

            h6 {
                margin-bottom: 0;
                position: relative;
                font-family: $font-open-sans;
                color: $black;
                font-size: 1.4rem;
                font-style: normal;
                font-weight: 400;
                line-height: 2.2rem;
                text-transform: none;
            }
        }
    }

    .only-mobile {
        display: flex;
        margin: 1.6rem 0;
        padding: 0 1.6rem;
        @include mq($screen-m) {
            display: none;
        }

        .contact-form-button {
            width: 100%;
        }
    }

    .dropdown-content {
        visibility: hidden;
        opacity: 0;
        height: 0;
        overflow: hidden;
        transition: visibility 0s, opacity 0.3s linear;
        @include mq($screen-m) {
            visibility: visible;
            opacity: 1;
            height: auto;
            overflow: auto;
            transition: none;
        }
    }
    
    .wrapper-h-dropdown.active ~ .dropdown-content {
        visibility: visible;
        opacity: 1;
        height: auto;
    }

    .wrapper-h-dropdown {
        cursor: pointer;
        position: relative;
        justify-content: flex-start;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        &::after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            content: url(../images/icons/dropdown-footer.svg);
            width: 24px;
            height: auto;
            display: inline-block;
        }
        @include mq($screen-m) {
            cursor: auto;
            touch-action: none;
            &::after {
                display: none;
            }
        }
    }

    .wrapper-h-dropdown.active {
        &::after {
            transform: translate(0, -50%) rotate(180deg);
        }
    }
    
}

.footnotes {
    width: $full-width;
    max-width: $container-desktop-width;
    border-top: $border-gold;
    margin: $margin-mxl auto 0 auto;
    padding: $padding-l;
    @include mq($screen-xlg) {
        padding: $padding-l 0;
    }
    a {
        color: $primary;
        text-decoration: none;
        &:hover,
        &:focus {
            color: $primary-hover-darker;
            text-decoration: none;
        }
    }

    .price-footnotes {
        p {
            @include t3;
            font-weight: $font-weight-bold;
            margin-bottom: $margin-root;
        }

        a {
            font-weight: $font-weight-bold;
            color: $primary;
            &:hover,
            &:focus {
                color: $primary-hover-darker;
                text-decoration: none;
            }
        }
    }

    .delivery-footnotes {
        p {
            @include t3;
        }
    }
}

@media screen and (min-width: $screen-s) {
    .footer {
        &__delivery,
        &__advantages,
        &__payment,
        &__history,
        &__hotline {
            margin: 2rem 0;
            text-align: left;

            .wrapper-h {
                // justify-content: center;
            }
        }
        &__advantages {
            ul li {
                justify-content: flex-start;
            }
        }
        &__main-content {
            @include flex(row, space-evenly);
            p,
            ul {
                max-width: 200px;
                margin: 0;
            }
        }
        &__links {
            ul li {
                width: initial;

                a {
                    margin-right: 10px;
                }
            }
        }
    }
}
@media screen and (min-width: $screen-l) {
    .footer {

        &__links {
            ul {
                @include flex(row, space-between);

                li {
                    margin-bottom: 0;
                }
                padding-left: 20px;
            }
        }
    }
}

@media screen and (min-width: $screen-xl) {
    .footer {
        &__bottom {
            &-container {
                justify-content: space-between;

                * {
                    margin: auto 0 auto 0;
                }

                .copyright {
                    background: $primary;
                    width: 20rem;
                }
            }
        }
    }
}
