.customer-account-login,
.customer-account-create,
.customer-account-forgotpassword,
.customer-account-createpassword {
    #maincontent {
        margin-top: 0;
        padding: $padding-medium;
        background: $white;
        @include mq($screen-m) {
            padding: $padding-xxlarge $padding-large $padding-large $padding-large;
        }
    }
    .heading.heading--page.margin-top-0.margin-bottom-xl.text-center {
        @include heading($primary);
        text-transform: none;
        margin: 0;
        text-align: left;
    }
}
.auth {
    @include flex(column, center);
    margin: $margin-xl 0;
    @include mq($screen-sm) {
        @include flex(row, center);
    }
    @include mq($screen-m) {
        align-items: center;
    }
    @include mq($screen-xl) {
        @include flex(row, space-between);
    }
    &__left {
        position: relative;
        background: $beige1;
        padding: $padding-medium;
        @include mq($screen-sm) {
            width: 60%;
            max-width: 67rem;
        }
        @include mq($screen-l) {
            padding: $padding-xlarge;
        }
    }
    &__right {
        padding: $padding-xxlarge $padding-medium 0 $padding-medium;
        @include mq($screen-sm) {
            width: 40%;
            padding: $padding-medium;
        }
        @include mq($screen-xl) {
            max-width: 47rem;
        }
        @include mq($screen-xlg) {
            padding: 0;
        }
        &-container {
            @include flex(column, center);
            align-items: flex-start;
        }
    }
    &__heading {
        margin: 0;
        h2 {
            @include heading(black);
            width: $full-width;
            @include mq($screen-l) {
                margin: 0;
            }
        }
    }
    &__form-intro {
        margin: $margin-normal 0;
        @include mq($screen-l) {
            margin: $margin-normal 0 $margin-md 0;
        }
    }
    &__form-input,
    .input {
        margin: 0 0 $margin-md 0;
        @include mq($screen-l) {
            @include flex(row, flex-start);
            align-items: center;
            flex-wrap: wrap;
        }
        label {
            font-family: $font-open-sans-bold;
            font-size: $font-size-normal;
            line-height: $line-height-normal;
            color: black;
            @include mq($screen-l) {
                width: 10rem;
            }
        }
        &.required label:not(.checkbox__label):after {
            color: $primary;
        }
        .input__field {
            @include input;
            @include mq($screen-l) {
                width: 34rem;
            }
            @include mq($screen-xl) {
                width: 48rem;
            }
        }
        .input__button-pass {
            top: 0.7rem;
            right: 0;
            height: 4rem;
            min-width: 4rem;
            min-height: 4rem;
            @include mq($screen-l) {
                top: 0;
                right: $margin-small;
                margin: 0;
            }
        }
        .input__button-pass > .icon {
            fill: $secondary;
            &:hover,
            &:focus {
                fill: $primary;
            }
        }
    }
    &__form-fieldset {
        margin: 0;
        width: $full-width;
        @include mq($screen-m) {
            max-width: $full-width;
        }
    }
    &__form-legend {
        font-family: $font-open-sans-bold;
        font-size: $font-size-lg;
        text-transform: uppercase;
        color: $primary;
        margin: $margin-md auto;
    }
    &__form-actions {
        margin: 0;
        @include mq($screen-l) {
            @include flex(row, space-between);
            width: $full-width;
            align-items: center;
        }
    }
    &__form-button {
        @include btn-primary;
        &:hover,
        &:focus {
            @include btn-primary-hover;
            &::after {
                display: none;
            }
        }
        @include mq($screen-l) {
            width: $width-19;
            margin: 0;
        }
        @include mq($screen-xl) {
            margin: 0;
        }
    }
    &__form-forgot-password {
        font-family: $font-open-sans;
        font-size: $font-size-lg;
        line-height: $line-height-large;
        color: $primary;
        &:hover,
        &:focus {
            color: $primary-hover;
            text-decoration: none;
        }
    }
    &__form-newsletter {
        @include flex(row, flex-start);
        position: relative;
        margin: 0 0 $margin-s 0;
        align-items: center;
        .checkbox__label {
            color: $black;
            &::after {
                background-color: $secondary;
            }
        }
        svg {
            stroke: $secondary;
        }
    }
    &__tips {
        font-family: $font-open-sans-bold;
        font-size: $font-size-normal;
        line-height: $line-height-normal;
        @include mq($screen-l) {
            width: $full-width;
            text-align: right;
        }
        @include mq($screen-xl) {
            position: absolute;
            top: 5.4rem;
            right: $margin-xlarge;
            width: fit-content;
        }
        &::before {
            content: '**';
            color: $primary;
        }
    }
    &__link-registration,
    &__link-login {
        @include btn-secondary;
        font-family: $font-open-sans-condensed;
        font-size: $font-size-md;
        font-weight: $font-weight-bold;
        color: $white;
        text-transform: uppercase;
        margin: $margin-small 0;
        &:hover,
        &:focus {
            @include btn-secondary-hover;
            color: $white;
        }
    }
}
.customer-account-create {
    .auth {
        align-items: flex-start;
        &__right {
            @include mq($screen-l) {
                padding: $padding-xlarge $padding-medium;
            }
        }
        &__form-button {
            width: $width-25;
        }
        &__form-input {
            .input {
                .label {
                    @include mq($screen-l) {
                        width: $width-12;
                    }
                }
            }
        }
    }
    .form__fieldset {
        border: none;
        padding: 0;
        width: $full-width;
        .select {
            width: $full-width;
            max-width: $width-25;
            height: 4rem;
            border: $border-gold-bold;
            font-family: $font-open-sans;
            font-size: $font-size-lg;
            appearance: none;
            background: $white url('../images/icons/arrow-down-gold.svg') no-repeat 100% center;
            background-size: $select-icon-size $select-icon-size;
            margin: 0;
            padding: 0 $padding-normal;
        }
    }
    .customer-name-prefix {
        margin: 0;
        &> label {
            display: none;
        }
    }
    .input__field {
        @include mq($screen-l) {
            margin: 0;
        }
    }
    .field-dob {
        margin: 0 0 $margin-md 0;
        @include mq($screen-l) {
            @include flex(row, flex-start);
            align-items: center;
        }
        .input--datepicker {
            margin: 0;
        }
        .input__field {
            &._has-datepicker {
                width: $width-25;
            }
        }
        .label {
            @include mq($screen-l) {
                width: 10rem;
            }
        }
    }
}
.customer-account-login {
    .input__field {
        margin: 0;
    }
}
.customer-account-forgotpassword {
    .auth {
        &__form-intro {
            @include mq($screen-m) {
                text-align: left;
            }
        }
        &__form-button {
            width: $width-25;
        }
        .input__label {
            @include mq($screen-l) {
                width: $width-10;
            }
        }
    }
    .captcha-reload {
        @include btn-secondary;
        color: $white;
        border: none;
        &:hover,
        &:focus {
            @include btn-secondary-hover;
        }
    }
}
.customer-account-createpassword {
    .auth {
        &__form-button {
            width: 100%;
        }
    }
}
.password {
    &-strength {
        @include mq($screen-l) {
            margin: 0 0 $margin-s 10rem;
        }
    }
    &-weak {
        .password-strength__indicator {
            font-weight: $font-weight-bold;
            background: $primary;
            border: none;
        }
    }
    &-medium {
        .password-strength__indicator {
            font-weight: $font-weight-bold;
            background: $orange;
            border: none;
        }
    }
    &-strong {
        .password-strength__indicator {
            font-weight: $font-weight-bold;
            background: $secondary;
            border: none;
        }
    }
}
