.minicart-content {
    &__top {
        border-bottom: $border-gold;
    }
    &__heading {
        @include heading($primary);
    }
    &__middle {
        padding: 24px 0 150px 0;
        @include mq($screen-s) {
            padding: 24px 0;
        }
    }
    &__counter {
        .subtitle.empty {
            @include flex(column, flex-start);
            align-items: center;
            margin: $margin-xl 0;
            span {
                margin: $margin-m 0 0 0;
            }
        }
    }
    &__total-counter {
        font-family: $font-open-sans-condensed;
        font-weight: $font-weight-bold;
        font-size: $font-size-normal;
        color: $gray6;
        span {
            @extend .minicart-content__total-counter;
        }
    }
    &__image {
        width: $width-10;
        height: $width-10;
        img {
            width: $full-width;
            height: auto;
        }
        .ratio-container {
            .ratio-image {
                height: 100%;
                object-fit: contain;
            }
        }
    }
    &__bottom {
        padding: $padding-medium;
        @include mq($screen-m) {
            border-top: $border-gold;
            padding: $padding-medium 0;
        }
    }
    &__summary-label {
        @include paragraph;
        color: $black;
        text-transform: none;
    }
    &__summary-value {
        .price {
            @include price($font-size-xxlarge, $primary);
        }
    }
    &__action-button {
        width: $full-width;
        padding: 0 $padding-md;
        margin: $margin-root 0 !important;
        @include mq($screen-m) {
            width: 48%;
        }
    }
    &__edit {
        @include btn-secondary;
        @include btn-text;
        border: none;
        &:hover,
        &:focus {
            @include btn-secondary-hover;
        }
    }
    &__checkout {
        @include btn-primary;
        @include btn-text;
        &:hover,
        &:focus {
            @include btn-primary-hover;
        }
    }

    &__button-update {
        width: $checkout-quantity-size;
        height: $checkout-quantity-size;
        min-width: $checkout-quantity-size;
        min-height: $checkout-quantity-size;
        border: $border-gold-bold;
        background-color: $white;
        color: $secondary;
        margin: 0;
        padding: 0;
        &:hover,
        &:focus {
            background-color: $secondary;
            color: $white;
            opacity: 0.5;
        }
        &:before,
        &:after {
            display: none!important;
        }
    }

    &__button-update-plus {
        font-size: 2.5rem;
        font-weight: 100;
    }

    .cart-item-qty {
        margin: auto 1rem;
        text-align: center;
        border-width: 2px;
        width: 4.5rem;

        &:hover,
        &:focus {
            border: $border-gold-bold;
        }
    }
}
.minicart-product {
    border-bottom: $border-gold;
    &__link {
        @include h4;
        color: $primary;
        transition: $transition;
        &:hover,
        &:focus {
            color: $primary-hover-darker;
            text-decoration: none;
        }
    }
    &__image {
        flex-basis: 10rem;
    }
    &__attributes-list {
        width: $full-width;
        background: $beige1;
        padding: $padding-s;
        .list__label {
            @include t3;
            color: $black;
        }
        .list__value {
            @include t3;
            font-weight: $font-weight-bold;
            color: $black;
        }
    }
    &__button {
        &:hover {
            svg.button__icon {
                fill: $secondary;
            }
        }
    }
    &__update {
        @include btn-primary;
        @include btn-text;
        border: none;
        &:hover,
        &:focus {
            @include btn-primary-hover;
        }
    }

    &__bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @include mq($screen-xsm) {
            flex-direction: column;
            gap: 1rem;
        }
    }
}
.minicart-price {
    .price {
        @include price($font-size-xxlarge, $black);
    }
}
