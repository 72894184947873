.breadcrumbs {
    &.container {
        max-width: $page-wrapper-container-width;
        margin: 0 auto;
        background: $white;
        padding: 5.3rem 0 3rem 3.2rem;
    }
    &__item {
        font-family: $font-open-sans-bold;
        &::after {
            content: '\276f';
            color: $secondary;
            font-size: $font-size-root;
            margin: 0 1.7rem;
        }
    }
    &__item-home {
        &>a {
            width: 20px;
            height: 20px;
            background: url('../images/icons/home.svg') no-repeat center right;
            background-size: contain;
            text-indent: -9999px;
        }
    }
}

.category-pdp-like-layout {
    &:has(.promotional-banner) {
        .breadcrumbs {
            &.container {
                @include mq($screen-l) {
                    padding-top: 3.5rem;
                }
            }
        }
    }
}

.catalog-product-view {
    &:has(.promotional-banner) {
        .breadcrumbs {
            &.container {
                @include mq($screen-l) {
                    padding-top: 7.5rem;
                }
            }
        }
    }
}