$input__error-margin                    : $spacer 0 0 !default;
$input__error-color                     : $color-error !default;
$input__error-font-size                 : $font-size-small !default;

$input__required-color                  : $color-secondary !default;

$input__validation-advice-margin        : $spacer 0 0 !default;
$input__validation-advice-color         : $color-error !default;
$input__validation-advice-font-size     : $font-size-small !default;

$input__validation-failed-border-color  : $color-error !default;

div {
    &.mage-error {
        margin: $input__error-margin;
        color: $input__error-color;
        font-size: $input__error-font-size;
    }

    &.validation-advice {
        margin: $input__validation-advice-margin;
        color: $input__validation-advice-color;
        font-size: $input__validation-advice-font-size;
    }

    &.required {
        label {
            &:not(.checkbox__label) {
                &:after {
                    content: '**';
                    color: $input__required-color;
                    white-space: nowrap;
                }
            }
        }

        > .checkbox__label {
            & > span,
            & .checkbox__text {
                &:first-of-type {
                    &:after {
                        content: ' **';
                        color: $input__required-color;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}

input,
textarea,
select {
    &.mage-error {
        border-color: $input__error-color;
    }

    &.validation-failed {
        border-color: $input__validation-failed-border-color;
    }
}
