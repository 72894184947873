.wishlist-list {
    &__item {
        @include flex(column, baseline);
        padding: 2.4rem 0;
        &:not(:last-child) {
            border-bottom: $border-gold;
        }
    }
    &__no-item {
        font-family: $font-open-sans;
        font-size: $font-size-lg;
        color: $gray6;
        padding: $padding-md 0;
    }
    .product-view__price-unit {
        text-align: left;
    }
}
.sidebar-block {
    .dashboard-items__subtitle {
        height: 5rem;
        margin: 0;
        padding: 0;
    }
    &__item {
        justify-content: unset;
        &::before {
            display: none;
        }
    }
    &__actions {
        margin-left: 99px;
        justify-content: space-between;
    }
    &__product-image {
        width: $sidebar-image-size;
        height: $sidebar-image-size;
        a {
            display: block;
            width: $sidebar-image-size;
            height: $sidebar-image-size;
        }
        img {
            width: $full-width;
            height: $full-width;
            object-fit: contain;

        }
    }
    &__link {
        font-family: $font-libre-bold;
        font-size: $font-size-lg;
        color: $primary;
        line-height: $line-height-large;
        &:hover {
            color: $primary-hover;
            text-decoration: none;
        }
    }
    &__price {
        font-family: $font-open-sans-condensed;
        font-size: $font-size-xxlarge;
        font-weight: $font-weight-bold;
        line-height: $line-height-large;
    }
    &__action {
        @include btn-secondary;
        color: $white;
        font-family: $font-open-sans-condensed;
        font-weight: $font-weight-bold;
        font-size: $font-size-md;
        line-height: 0;
        padding: 1rem;
        margin: 0 $margin-normal 0 0;
        &:last-child {
            margin: 0;
        }
        &:hover,
        &:focus {
            @include btn-secondary-hover;
            color: $white;
            text-decoration: none;
        }
    }
    &__icon {
        fill: $white;
        width: $sidebar-icon-size;
        height: $sidebar-icon-size;
    }
}
