.products-grid {
    h3 {
        margin-top: 70px;
        text-align: center;
    }
    .favourite-gifts__link {
        @include flex( column, center);
        margin-bottom: 40px;
        .link {
            margin: 0 auto;
            &:hover {
                color: $white;
                text-decoration: none;
            }
        }
    }

}
.product-items {
    @include flex(column, flex-start);

    .product-item {
        width: 295px;
        background: $white;
        margin: 1.6rem auto;
        border: solid 2px $secondary;
        box-shadow: 0 4px 8px 0 rgba(199, 161, 122, 0.5);

        &:hover {
            .product-item-info {
                box-shadow: 0 24px 32px 0 rgba(199, 161, 122, 0.5);
            }
        }
        &-info {
            @include flex(column, flex-start);
            height: 100%;

            &__label {
                top: 24px;
                z-index: 1;
                left: -32px;
                width: 140px;
                height: 27px;
                color: $white;
                font-weight: 700;
                padding: 0.5rem 0;
                line-height: 17px;
                position: absolute;
                text-align: center;
                background: $primary;
                transform: rotate(-45deg);
                text-transform: uppercase;
                font-family: $font-open-sans-condensed;
                clip-path: polygon(19% 0%, 80% 0%, 99% 100%, 0% 100%);

                ::after,
                ::before {
                    content: "\2605";
                    width: 10px;
                    height: 10px;
                }
            }
            &__mark {
                top: -1px;
                z-index: 1;
                right: 15px;
                width: 34px;
                height: 47px;
                position: absolute;
                padding: 0 0 0.3rem 0;
                background: $secondary;
                clip-path: polygon(
                    100% 0,
                    100% 100%,
                    50% 75%,
                    0% 100%,
                    0 50%,
                    0% 0%
                );

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .product-view__price-unit {
            margin: 0;
            color: $gray6;
            font-size: 1.6rem;
        }
        &-photo {
            width: 100%;

            img {
                max-width: 291px;
            }

            .lazyload-wrapper {
                padding-bottom: 100%;
            }
        }
        &-details {
            padding: 0 $padding-m;

            .price-final_price,
            .price__value--special .price-container.price-final_price {
                opacity: 1;
                color: $primary;
                font-size: 25px;
                line-height: 24px;
                text-align: right;
                text-decoration: none;
                font-family: $font-open-sans-condensed;
            }
            .price__value--old .price-final_price {
                color: $gray3;
                font-size: 1.6rem;
            }

            .price__value--old {
                color: $gray3;
            }
        }
        &-inner {
            .actions-primary {
                display: flex;
                justify-content: flex-end;

                .personalize-link {
                    margin-right: auto;
                }
                .personalize {
                    font-size: 1.2rem;
                    margin-right: auto;
                    padding: 0.4rem 4rem;
                    margin-bottom: 1.3rem;
                }
            }
            .button--add-to {
                padding: 0 33px;
                min-height: 40px;
                background: $secondary;
                margin-bottom: $padding-m;

                .button__icon {
                    display: block;
                }
                svg {
                    width: 26px;
                }
                &:hover {
                    background: $secondary-hover;

                    &::after {
                        display: none;
                    }
                }
            }
        }
        &-name {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 5px;

            a {
                font-size: 18px;
                font-weight: 700;
                line-height: 26px;
                text-align: center;
                font-family: $font-open-sans;
            }
        }
        .minimal-price,
        .price.price--between {
            .price__text {
                font-size: 2.5rem;
                font-weight: bold;
                color: $primary;;
                text-align: right;
                text-transform: lowercase;
                vertical-align: top;
            }
            .price__value {
                display: inline-block;
            }
        }
        .bestseller-price {
            display: inline-flex;
        }

    }
}

.product-items.products.list.slick-slider {
    padding: 0;
    margin-left: -4rem;
    margin-right: -4rem;
    gap: 1.6rem;
    @include mq($screen-m) {
        margin-left: -1.6rem;
        margin-right: -1.6rem;
    }
    .slick-list {
        width: 100%;
    }
    .slick-arrow {
        border-radius: 50%;
        width: 48px;
        height: 48px;
        line-height: 48px;
        font-size: 0;
        background: #F5E6D3 url("../images/slider-arr.svg") center no-repeat;
        position: absolute;
        top: 50%;
        transform: translate(0,-50%);
        margin: 0;
    }
    .slick-dots {
        display: flex;
        justify-content: center;
        align-items: center;
        li {
            list-style: none;
            margin: 0 8px;
            button {
                font-size: 0;
                width: 8px;
                height: 8px;
                line-height: 8px;
                min-height: 8px;
                border-radius: 50%;
                padding: 0;
                background-color: $white;
                border: 1px solid #D8D8D8;
            }
        }
        li.slick-active button {
            background-color: $primary;
            border: 1px solid $primary;
        }
    }
    .slick-next {
        left: 100%;
    }
    .slick-prev {
        right: 100%;
        transform: translate(0,-50%) rotate(180deg);
    }
    // &::-webkit-scrollbar {
        // display: none;
    // }
    .product-item-details {
        padding: 0;
    }
    .product-item {
        border: none;
        box-shadow: none;
        margin: 0 1.6rem;
        &:hover {
            box-shadow: none;
            .product-item-info {
                box-shadow: none;
            }
        }
        @include mq($screen-xl) {
            // width: 312px;
            // margin: 0;
        }
        .product-item-name {
            margin: 1.3rem 0 1rem 0;
            justify-content: center;
            a {
                font-size: 1.6rem;
                line-height: 2.2rem;
                font-weight: 400;
                text-align: center;
                @include mq($screen-m) {
                    font-size: 1.8rem;
                    line-height: 2.4rem;
                }
            }
        }
        .product-item-details .price-final_price {
            text-align: right;
            margin-bottom: 0.5rem;
        }
        .product-item-inner {
            display: none!important;
        }
    }
    .product-item-photo {
        .lazyload-wrapper {
            min-height: auto;
        }
        img {
            max-width: 225px;
            aspect-ratio: 1/1;
            margin: 0;
            border: 0.1px solid #f4f4f4;
            @include mq($screen-m) {
                max-width: max-content;
            }
        }
    }
    .product-item-info {
        width: 100%;
    }
    .product-item-info__mark {
        top: 2px;
        right: auto;
        left: 10px;
        width: 24px;
        height: 40px;
    }
    .actions-secondary {
        top: 10px;
        z-index: 1;
        right: 5px;
        left: auto;
        position: absolute;
        padding: 0;
        .button--tocompare {
            display: none;
        }
        .button--wishlist {
            display: block;
            padding: 0;
            min-width: auto;
            min-height: auto;
            margin: 0;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.69);
            .button__icon {
                margin: auto;
            }
        }
    }
}

@media screen and (min-width: $screen-s) {
    .product-items {
        @include flex(row, space-evenly);
        flex-wrap: wrap;
        padding: $padding-s;
    }
}
@media screen and (min-width: $screen-xl) {
    .product-items {

        &.category-list {
            padding-right: 0;
        }
        .product-item {
            width: 315px;
            margin: 1.6rem 0.5rem;

            &-photo {
                img {
                    max-width: 240px;
                }
            }

            &:hover {
                margin-bottom: 46px;
            }
            &-inner {
                left: -2px;
                width: 315px;
                display: none;
                padding: 0 1.3rem;
                position: absolute;
                background: $white;
                border: 2px solid $secondary;
                border-top: none;
                box-shadow: 0 24px 32px 0 rgba(199, 161, 122, 0.5);

                .personalize {
                    padding: 0.4rem 5rem;
                }
            }
            .price--between {
                .price-to {
                    display: none;
                }
            }
            .price-box.price-final_price {
                margin-bottom: 30px;
            }
            .bestseller-price {
                display: inline-flex;
            }
            &:hover {
                z-index: 2;

                .product-item-inner {
                    display: block;
                }
                .price-final_price {
                    margin-bottom: 0;
                }
            }
        }
    }
}
