.input__field.focus-visible {
    outline: 0;
}

.his-input {
    height: 40px;
    margin: 10px 0;
    border: 2px solid $secondary;

    input {
        @include t3;
        font-weight: $font-weight-bold;
        width: $full-width;

        &::placeholder {
            opacity: 0.25;
        }
    }
    .input__field {
        border: 0;
        max-width: 100%;
        max-height: 36px;
    }

    @media screen and (min-width: $screen-s) {
        margin: 0;
        margin-right: 15px;
    }
}

select.his-input {
    color: #1a1b1d!important;
    border: 2px solid $secondary!important;
}
