header.header {
    .header__navigation {
        .quicksearch-wrapper {
            @include mq($screen-l) {
                position: relative;
            }

            .quicksearch {
                position: absolute;
                top: 99px;
                left: 1px;

                @include mq($screen-s) {
                    top: 108px;
                }

                @include mq($screen-l) {
                    left: 2px;
                    top: 0;
                }
            }

            .amount {
                display: none;
            }
        }

        .search-form__wrapper {
            right: 0;
            top: 59px;
            opacity: 1;
            width: 100%;
            position: absolute;
            visibility: visible;
            transition: all 0.1s ease-in-out;

            &.hidden {
                top: 0;
                opacity: 0;
                visibility: hidden;
            }
        }
        .header__search-wrapper {
            padding: 0 24px;
            box-sizing: unset;
            margin: 10px 0 10px 0;
            max-width: $input-width-l;

            .input__field {
                @include t3;
                border: 0;
                max-height: 36px;
                font-weight: 700;
                margin: 0;

                &.focus-visible {
                    outline: 0;
                }
                &::placeholder {
                    opacity: 0.25;
                }
            }
            .search-form__input-wrapper {
                border: 2px solid $secondary;

                > div {
                    width: 100%;
                }
            }
            .search-form__button {
                height: 34px;
                border: none;
                min-height: 34px;
            }
        }
    }

    .header__wrapper .search-visibility-toggle {
        margin: 0;
        padding: 0;
        width: 24px;
        right: 50px;
        height: 24px;
        display: block;
        position: absolute;
        background: transparent;
        top: calc(50% - 20px);

        svg {
            width: 24px;
            height: 24px;
            vertical-align: middle;
        }

        figure {
            width: 24px;
            height: 24px;
        }
    }
}

@media screen and (min-width: $screen-s) {
    header.header {
        .header__wrapper .search-visibility-toggle {
            top: 14px;
        }
        .header__navigation {
            .search-form__wrapper {
                top: 68px;
            }
        }
    }
}

@media screen and (min-width: $screen-l) {
    header.header {
        .header__navigation {
            .search-visibility-toggle {
                display: none;
            }
            .search-form__wrapper {
                top: 0;
                position: static;
                max-width: $input-width-l;

                &.hidden {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

.ea-overlay {
    display: none !important;
}

.ea-search-autocomplete {
    font-size: $font-size-md;

    @media screen and (min-width: $screen-m) {
        left: 0 !important;
        width: 100% !important;
    }

    @media screen and (min-width: $screen-l) {
        position: fixed !important;
    }

    .content {
        top: 3px !important;
        overflow: auto;
        max-height: calc(100vh - 200px);
        background: white;

        @media screen and (min-width: $screen-l) {
            overflow: hidden;
            max-height: initial;
        }

        @media screen and (min-width: $screen-m) {
            margin-top: 0;
            margin-bottom: 0;

            .left, .right {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        @media screen and (min-width: $screen-l) {
            max-width: 1024px;
            margin: 0 auto;
        }
    }

    .wyomind-primary-bgcolor {
        background: $primary !important;
    }

    &.top-arrow-primary:before {
        content: none !important;
    }

    .ea-products-grid {
        .info {
            margin-left: 10px;
        }
    }

    #ea-all-results {
        a {
            display: inline;
        }
    }
}

.ea-search-autocomplete-no-result {
    .wyomind-primary-border {
        border: 1px solid $primary !important;
    }

    &.top-arrow-primary:before {
        content: none !important;
    }
}

body.ea-autocomplete {
    overflow: auto !important;
}
