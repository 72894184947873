.customer-account-edit {
    #maincontent {
        margin-top: 0;
        padding: 0 $padding-normal;
        background: $white;
    }
    .dashboard__content-heading {
        &.heading--page {
            margin: 0 0 $margin-md 0;
        }
    }
    .actions-group__button {
        @include btn-primary;
        font-family: $font-open-sans-condensed;
        font-weight: $font-weight-bold;
        font-size: $font-size-md;
        margin: 0;
        &:hover,
        &:focus {
            @include btn-primary-hover;
        }
    }
    .dashboard-items__title {
        font-family: $font-libre-bold;
        font-size: $font-size-xxlg;
        line-height: $line-height-xxl;
        margin: $margin-md 0;
        text-transform: none;
        border: none;
        padding: 0;
        background: transparent;
    }
    .field-dob {
        @include mq($screen-m) {
            display: flex;
            align-items: baseline;
            margin: 0;
        }
    }
    [data-container="change-email"] {
        @include mq($screen-m) {
            display: flex;
            align-items: baseline;
            margin: 0 0 $margin-normal 0;
        }
    }
    .form-edit-account {
        p {
            font-size: $font-size-normal;
            margin: $margin-m 0 0 0;
            a {
                text-decoration: none;
                font-weight: $font-weight-bold;
            }
        }
    }
}
.customer-account-index {
    .dashboard {
        .row {
            @include mq($screen-xl) {
                max-width: $container-desktop-width;
                padding: 0;
                margin: 0 auto;
            }
        }
    }
}
