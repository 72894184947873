.dashboard-noitem {
    margin: $margin-xl;
    width: $full-width;
    &__container {
        @include flex(column, center);
        align-items: center;
        width: $full-width;
    }
    &__icon {
        width: $wishlist-icon-size;
        height: $wishlist-icon-size;
        svg {
            fill: $secondary;
            width: $full-width;
            height: $full-width;
            object-fit: contain;
        }
    }
    &__button {
        @include btn-primary;
        font-family: $font-open-sans-condensed;
        font-weight: $font-weight-bold;
        font-size: $font-size-md;
        line-height: $line-height-extra-large;
        color: $white;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding: 0.5rem $padding-medium;
        margin: $margin-mxl 0 0 0;
        &:hover,
        &:focus {
            @include btn-primary-hover;
            color: white;
            text-decoration: none;
        }
    }
    h3 {
        font-size: $font-size-xxlg;
        line-height: $line-height-xxl;
        text-align: center;
        margin: $margin-normal 0 0 0;
    }
    p {
        font-size: $font-size-lg;
        text-align: center;
        line-height: $line-height-large;
        margin: $margin-small 0 0 0;
    }
}