.creative-gift {
    position: relative;
    &__list {
        display: flex;
        width: $full-width;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 15px;
        @include mq($screen-m) {
            gap: 32px;
            flex-wrap: nowrap;

        }

        a {
            width: 100%;
        }
    }
    &__list-item {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;
        img {
            width: 100%;
            aspect-ratio: 1/1;
        }
        h5 {
            color: $white;
            font-family: $font-libre-bold;
            display: inline-block;
            font-size: 1.6rem;
            line-height: 4rem;
            margin: 0;
            position: relative;
            text-align: center;
            width: 100%;
            background: $primary;
            @include mq($screen-m) {
                font-size: 1.8rem;
                line-height: 5.6rem;
                margin: 0;
            }
            &::after {
                content: '';
                display: inline-block;
                position: absolute;
                top: 45%;
                right: 5px;
                transform: translate(0, -50%);
                width: 2rem;
                height: 2rem;
                background: url("../images/creative-gift-after.svg") center no-repeat;
                background-size: contain;
                @include mq($screen-m) {
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }

        }
        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 8px;
            width: 24px;
            height: 41px;
            background: url("../images/slider-item-pointer.svg") center no-repeat;

        }
    }
    &__icon {
        width: 100%;
        picture {
            width: 100%;
        }
    }
}

// ========== styles for using sku widget ==========
div.creative-gift:has(.product-sku__item) {
    display: flex;
    width: $full-width;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    @include mq($screen-m) {
        gap: 32px;
        flex-wrap: nowrap;
    }
    .product-sku__item {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;
        .product-sku__image {
            width: 100%;
            img {
                width: 100%;
                aspect-ratio: 1/1;
            }
            a {
                display: block;
            }
        } 
        .product-sku__title {
            color: $white;
            font-family: $font-libre-bold;
            display: inline-block;
            font-size: 1.6rem;
            line-height: 4rem;
            margin: 0;
            position: relative;
            text-align: center;
            width: 100%;
            background: $primary;
            @include mq($screen-m) {
                font-size: 1.8rem;
                line-height: 5.6rem;
                margin: 0;
            }
            a {
                color: $white;
                font-family: $font-libre-bold;
                position: relative;
                max-width: none;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0.8rem auto;
                width: max-content;
                @include mq($screen-m) {
                    max-width: 75%;
                    width: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0.8rem auto;
                }
                &::after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    top: 45%;
                    right: -2.5rem;
                    transform: translate(0, -50%);
                    width: 2rem;
                    height: 2rem;
                    background: url("../images/creative-gift-after.svg") center no-repeat;
                    background-size: contain;
                    @include mq($screen-m) {
                        right: -3rem;
                        width: 2.5rem;
                        height: 2.5rem;
                    }
                }
            }
        }
        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 8px;
            width: 24px;
            height: 41px;
            background: url("../images/slider-item-pointer.svg") center no-repeat;
        }
    }
}

div.creative-gift:has(.category-sku__item) {
    display: flex;
    width: $full-width;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    @include mq($screen-m) {
        gap: 32px;
        flex-wrap: nowrap;
    }
    .category-sku__item {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;
        .category-sku__img {
            width: 100%;
            img {
                width: 100%;
                aspect-ratio: 1/1;
            }
            a {
                display: block;
            }
        } 
        h5 {
            color: $white;
            font-family: $font-libre-bold;
            display: inline-block;
            font-size: 1.6rem;
            line-height: 4rem;
            margin: 0;
            position: relative;
            text-align: center;
            width: 100%;
            background: $primary;
            @include mq($screen-m) {
                font-size: 1.8rem;
                line-height: 5.6rem;
                margin: 0;
            }
            a {
                color: $white;
                font-family: $font-libre-bold!important;
                position: relative;
                max-width: none;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0.8rem auto;
                width: max-content;
                @include mq($screen-s) {
                    min-height: 64px;
                }
                @include mq($screen-m) {
                    max-width: 75%;
                    width: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0.8rem auto;
                }
                @include mq($screen-xl) {
                    min-height: auto;
                }
                &::after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    top: 45%;
                    right: -2.5rem;
                    transform: translate(0, -50%);
                    width: 2rem;
                    height: 2rem;
                    background: url("../images/creative-gift-after.svg") center no-repeat;
                    background-size: contain;
                    @include mq($screen-m) {
                        right: -3rem;
                        width: 2.5rem;
                        height: 2.5rem;
                    }
                }
            }
        }
        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 8px;
            width: 24px;
            height: 41px;
            background: url("../images/slider-item-pointer.svg") center no-repeat;
        }
    }
}