.four-gifts-link {
    position: relative;
    // padding: 40px 0;
    @include mq($screen-m) {
        // padding: 100px 0;
    }
    &__title {
        text-align: center!important;
    }
    &__list {
        display: flex;
        width: $full-width;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 15px;
        @include mq($screen-m) {
            gap: 32px;
            flex-wrap: nowrap;

        }
    }
    a {
        width: 45%;
        @include mq($screen-m) {
            width: 100%;
        }     
    }
    &__list-item {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;
        @include mq($screen-m) {
            width: 100%;
        }
        h5 {
            color: $primary;
            font-family: $font-libre-bold;
            font-size: 1.4rem;
            line-height: 2.2rem;
            margin: 0.8rem 0;
            @include mq($screen-m) {
                font-size: 1.8rem;
                line-height: 4rem;
                margin: 0.8rem 0 0 0;
            }
        }
    }
    &__icon {
        position: relative;
        height: 100%;
        width: 100%;
        aspect-ratio: 1/1;
        background: #fafafa;
        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            bottom: -1px;
            right: -1px;
            width: 24px;
            height: 24px;
            background: url("../images/four-gifts-after.svg") center no-repeat;
            background-size: contain;
            @include mq($screen-m) {
                width: 48px;
                height: 48px;
            }
        }
    }
}



// ========== styles for using product-sku widget ==========
div[data-content-type="row"]:has(.four-gifts-link-block) {
    position: relative;
    padding: 30px 15px;
    @include mq($screen-m) {
        padding: 60px 15px;
    }
}

div.four-gifts-link-block:has(.product-sku__item) {
    position: relative;
    display: flex;
    width: $full-width;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    @include mq($screen-m) {
        gap: 32px;
        flex-wrap: nowrap;
        justify-content: center;
    }
    a {
        display: block;
    }
    .product-sku__item {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 45%;
        @include mq($screen-m) {
            width: 100%;
        }
        .product-sku__title {
            color: $primary;
            font-family: $font-libre-bold;
            font-size: 1.6rem;
            line-height: 2.4rem;
            margin: 0.8rem 0;
            @include mq($screen-m) {
                font-size: 2.2rem;
                line-height: 4rem;
                margin: 0.8rem 0 1.6rem 0;
            }
            a {
                color: $primary;
                font-family: $font-libre-bold!important;
            }
        }
    }
    .product-sku__image {
        position: relative;
        width: 100%;
        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            bottom: -1px;
            right: -1px;
            width: 24px;
            height: 24px;
            background: url("../images/four-gifts-after.svg") center no-repeat;
            background-size: contain;
            @include mq($screen-m) {
                width: 48px;
                height: 48px;
            }
        }
        a {
            width: 100%;
        }
        img {
            width: 100%;
            aspect-ratio: 1/1;
        }
    }
}



div.four-gifts-link-block:has(.category-sku__item) {
    position: relative;
    display: flex;
    width: $full-width;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    @include mq($screen-m) {
        gap: 32px;
        flex-wrap: nowrap;
        justify-content: center;
    }
    a {
        display: block;
    }
    .category-sku__item {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 45%;
        @include mq($screen-m) {
            width: 100%;
        }
        h5 {
            color: $primary;
            font-family: $font-libre-bold;
            font-size: 1.6rem;
            line-height: 2.4rem;
            margin: 0.8rem 0;
            @include mq($screen-m) {
                font-size: 2.2rem;
                line-height: 4rem;
                margin: 0.8rem 0 1.6rem 0;
            }
            a {
                color: $primary;
                font-family: $font-libre-bold!important;
            }
        }
    }
    .category-sku__img {
        position: relative;
        &::after {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: -1px;
        right: -1px;
        width: 24px;
        height: 24px;
        background: url("../images/four-gifts-after.svg") center no-repeat;
        background-size: contain;
        @include mq($screen-m) {
            width: 48px;
            height: 48px;
        }
        }
        a {
            width: 100%;
        }
        img {
            width: 100%;
            aspect-ratio: 1/1;
        }
    }
}