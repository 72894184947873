div.cms.newsletter {
    padding: 1.6rem 1.6rem 7.2rem 1.6rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0;
    width: auto;
    background-color: $primary;
    @include mq($screen-l) {
        padding: 3.2rem 1.5rem 7.2rem 1.5rem;
        margin: 0;
        width: 100%;
    }

    .newsletter__heading {
        justify-content: center;
        margin: 0;
        text-align: center;
        color: $white;

        h4 {
            font-family: $font-libre-bold;
            font-size: 3rem;
            line-height: 3.2rem;
            font-weight: 700;
            margin: 0 0 1rem 0;
            @include mq($screen-l) {
                font-size: 2.8rem;
            }
        }

        h6 {
            font-family: $font-open-sans;
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-weight: 400;
            margin: 0 0 2.4rem 0;
            text-transform: none;
            @include mq($screen-l) {
                font-size: 1.8rem;
                line-height: 3.2rem;
            }
        }

        p {
            color: #D8D8D8;
            font-family: $font-open-sans;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1.6rem;
            margin: 0;
            margin-bottom: 0 !important;
            position: absolute;
            bottom: 1.6rem;
            width: 100%;
            left: 0;
            right: 0;
            text-align: left;
            padding: 0 1.6rem;
            @include mq($screen-m) {
                bottom: 3.2rem;
                text-align: center;
            }
        }
    }

    .newsletter__form {
        width: 100%;
        @include mq($screen-m) {
            width: auto;
        }
    }

    .newsletter__controls {
        display: flex;
        flex-direction: column;
        max-width: none;
        @include mq($screen-m) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        .his-input {
            width: 100%;
            border-color: $white;
            margin: 0 0 1.6rem 0;
            @include mq($screen-m) {
                width: 256px;
                margin: 0 1.6rem 0 0;
            }
        }

        .his-btn-secondary {
            width: 100%;
            background: $white;
            color: $primary;
            @include mq($screen-m) {
                width: 256px;
            }
        }
    }
}

