.cms-page__content {
    padding-bottom: 30px;
    max-width: 100%;
    background-color: $white;
    padding: 0 3.2rem 3rem;
    font-size: 16px;

    .small-grid {
        width: $full-width;
        @include flex(row, center);
        flex-wrap: wrap;
        margin: 0 auto;
        @include mq($screen-m) {
            @include flex(row, flex-start);
        }
        @include mq($screen-xl) {
            max-width: 100rem;
        }
        @include mq($screen-xlg) {
            max-width: 130rem;
        }
        &-cell {
            @include flex(column, center);
            width: $full-width;
            padding: 0 $padding-l;
            @include mq($screen-m) {
                width: $half-width;
            }
            @include mq($screen-l) {
                width: 33%;
            }
            @include mq($screen-xl) {
                width: 25%;
            }
            &__heading {
                width: $full-width;
                margin: 0 auto;
                color: $secondary;
                font-size: $font-size-lg;
                text-align: center;
                font-family: $font-open-sans-condensed;
            }
            &__text {
                font-family: $font-open-sans-bold;
                font-size: $font-size-normal;
                color: $gray6;
                text-align: center;
                height: 3.8rem;
            }
            &__img {
                width: 20rem;
                height: 20rem;
                object-fit: contain;
                margin: 0 auto $margin-root auto;
            }
        }
    }
    figure {
        @include flex(column, center);
        margin: $margin-s 0;
        align-items: center;
    }

    img {
        height: auto;
        margin: 0 auto;
        max-width: 100%;
    }
    table {
        margin-bottom: 20px;
    }
    h1 {
        @include h3;
        font-size: 30px;
        text-align: left;
        margin-top: 20px!important;
        font-weight: 700;
        line-height: 45px;
        text-transform: none;
    }
    h2 {
        @include h4;
        font-weight: 700;
        font-family: $font-open-sans;
    }
    h3 {
        font-size: 30px;
        color: $black;
        margin-top: 50px;
    }
    p {
        margin-bottom: 25px;
    }
    ul li {
        font-size: $font-size-lg;
        line-height: 2.5rem;
        margin: 0 0 0 $margin-md;
        font-family: $font-open-sans;
        list-style-image: url("../images/icons/check.svg");
        p {
            margin: 0;
        }
    }
    ol li {
        font-size: $font-size-lg;
    }
    a {
        font-weight: 700;
        color: $secondary;
        text-decoration: none;

        &:hover {
            color: $secondary-hover;
        }
    }
    iframe {
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: $screen-s) {
    .cms-envoi,
    .cms-shipment,
    .cms-verzending,
    .cms-przesylka,
    .cms-envio,
    .cms-versand {
        .cms-page__content {
            div[data-content-type="html"] {
                overflow-x: auto;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
            table {
                font-size: 15px;
                td {
                    padding: 1px;
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

._1v8owny > div {
    padding-bottom: 10px;
    height: auto !important;
}

@media screen and (min-width: $screen-s) {
    .cms-page__content {
        iframe {
            max-width: 560px;
            max-height: 500px;
        }
    }
}

.homepage {
    background-color: $white;

    &.page-main {
        min-height: 1000px;
    }
}
.home,
.category-cms {
    margin: 35px $padding-l;

    h4 {
        margin-bottom: 2.5rem;
    }

    p {
        font-size: 1.4rem;
    }
    p.sub-heading {
        font-weight: 700;
        line-height: 1.8rem;
        margin-bottom: 40px;
    }
    li {
        font-size: 1.4rem;
        list-style-type: none;
        position: relative;
    }
    ul {
        padding: 0 0 0 20px;
        li::before {
            @include list-bullet;
            margin: 0 0 0 -2rem;
            top: 0.6rem;
            left: 0;
            position: absolute;
        }
    }
    ol {
        padding: 0 0 0 15px;
        > li {
            list-style-type: auto;
            padding-left: 5px;
            &::marker {
                font-weight: bold;
            } 
        }
    }
     
    .cms-page-title {
        margin: 0 auto;
        max-width: 1180px;
    }
    .cms-row {
        @include flex(row, space-between);
        flex-wrap: wrap;

        &__picture {
            order: 1;
            height: 100%;
            margin: auto;
            img {
                width: $full-width;
            }
        }
    }
}
.pb-custom__homepage {
    .home {
        margin: 0;
    }
}
.cms-presse {
    h2 {
        @include h3;
        font-size: 30px;
        text-align: left;
        margin-top: 50px;
        font-weight: 700;
        line-height: 45px;
        text-transform: none;
    }
}
.category-cms {
    margin: 35px 0;

    ul {
        font-size: 16px;
    }
}
.block-cms-link {
    display: inline;
    @include t3;
    font-weight: $font-weight-bold;
    color: $primary;
    &:hover,
    &:focus {
        color: $primary-hover-darker;
        text-decoration: none;
    }
}
.copyrights-list {
    @include mq($screen-s) {
        column-count: 2;
    }
    @include mq($screen-l) {
        column-count: 3;
    }
    @include mq($screen-xl) {
        column-count: 4;
    }
}

.cms-noroute-index {
    .page-main {
        margin-top: 30px !important;

        .heading {
            margin-top: 20px;
        }
    }
}

@media screen and (min-width: $screen-m) {

    .category-cms {
        margin: 35px 2.6rem;

        .cms-page-title {
            text-align: center;
        }
    }
}
@media screen and (min-width: $screen-l) {

    .home,
    .category-cms {
        margin: 70px 35px 35px;

        p.sub-heading {
            text-align: center;
        }
        .cms-page-title {
            text-align: center;
        }
        .cms-row {
            flex-wrap: nowrap;

            &__text {
                max-width: 75%;
                min-width: 50%;

                &-half-space {
                    max-width: 48%;
                }
            }
            &__picture {
                order: unset;
                max-width: 20%;
                margin: auto 0;
            }
        }
    }
    .category-cms {
        .cms-row {
            &__text {
                max-width: 48%;
            }
            &__picture {
                max-width: 48%;
            }
        }
    }
}
@media screen and (max-width: $screen-sm) {
    .cms-page-title {
        h1 {
            font-size: 3rem;
            text-align: center;
        }
    }
}

.pagebuilder-mobile-only {
    display: none !important;
}

@media all and (max-width: 768px) {
    .pagebuilder-mobile-only {
        display: block !important;
    }
    .pagebuilder-mobile-hidden {
        display: none !important;
    }
}
