.gift-matcher {
    @include flex(column, space-between);
    width: 100%;
    margin: 20px 0;
    position: relative;
    padding: $padding-l;
    background: $primary;

    * {
        margin-top: auto;
        margin-bottom: auto;
    }
    &__heading {
        @include flex(row, flex-start);
        align-items: center;
        margin: 0 auto;
        h4 {
            color: $white;
            max-width: 475px;
            min-height: auto;
        }
    }
    &__icon {
        width: 6.5rem;
        height: 6rem;
        object-fit: contain;
        margin: 0 $margin-s 0 0;
    }
    &__controls {
        @include flex(column, space-between);
        margin-top: 20px;
    }
    &__input {
        position: relative;
        margin: 5px 0;

        figure {
            right: 8px;
            position: absolute;
            top: calc(50% - 3px);

            svg {
                height: 6px;
            }
        }

        select {
            width: 100%;
            height: 100%;
            font-size: 16px;
            border-radius: 0!important;
            color: #1a1b1d!important;
        }
    }
    &__button {
        margin-top: 5px;
        line-height: 2rem;
    }
}
@media screen and (min-width: $screen-s) {
    .gift-matcher {
        flex-direction: column;

        &__input {
            min-width: 350px;
            max-width: initial;
        }
        &__heading {
            max-width: 500px;
        }
        &__form {
            margin: auto;
        }
        &__controls {
            @include flex(column, space-between);
            flex-wrap: wrap;
        }
        &__button {
            width: 100%;
        }
    }
}
@media screen and (min-width: $screen-l) {
    .gift-matcher {
        margin-top: -55px;
        flex-direction: row;

        &__input {
            min-width: 130px;
            max-width: 150px;
            margin-right: 15px;
        }
        &__form {
            width: auto;
            margin: auto 0;
        }
        &__controls {
            margin-top: 0;
            max-width: 100%;
            flex-wrap: nowrap;
            flex-direction: row;

            * {
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        &__heading {
            margin: auto 10px auto 0;
        }
        &__button {
            width: auto;
        }
    }
}
