button,
.his-btn-primary {
    &.action-accept {
        &:hover {
            background-color: $primary;
        }
    }
    @include btn-text();
    border: 0;
    min-height: 40px;
    padding: 0.5rem 1.8rem;
    margin: 1.6rem 0 2rem 0;
    background-color: $primary;
    transition: background-color 0.5s;

    &:hover,
    &:focus {
        cursor: pointer;
        background-color: $primary-hover;
    }
    &.button--icon {
        &:focus {
            background: none;
        }
    }
}

.his-btn-secondary {
    @include btn-text();
    border: 0;
    width: 100%;
    color: $white;
    margin: 0 auto;
    padding: 0 15px;
    min-height: 40px;
    background: $secondary;
    @include mq($screen-s) {
        width: auto;
    }
    &:hover,
    &:focus {
        background: $secondary-hover;
        &::after {
            display: none;
        }
    }
}
.button--secondary {
    &:hover {
        background-color: $black;
    }
}
