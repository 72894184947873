.partner-program-form {
    max-width: 872px;
    padding: $padding-l;
    background: $beige1;

    textarea {
        resize: none;
    }

    .row {
        margin: 0;

        .field {
            width: 100%;
        }

        .his-input {
            width: 100%;
        }
    }
    .field {
        display: flex;
        flex-direction: column;
        margin-bottom: $margin-md;

        .label {
            flex-grow: 1;
            margin: auto 0;
            width: 170px;
        }
        .control {
            flex-grow: 25;
            margin: 0 0 0 0;

            .his-input {
                width: 100%;
            }
        }
    }
    .focus-visible {
        outline-color: $secondary-hover;
    }
    .primary {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
        p.required {
            padding-right: 15px;
        }
    }
}
@media screen and (min-width: $screen-m) {
    .partner-program-form {
        .field {
            flex-direction: row;
        }
    }
}
@media screen and (min-width: $screen-l) {
    .partner-program-form {
        padding-right: 15rem;
        .row {
            justify-content: space-between;

            .field {
                width: 50%;
                .control {
                    flex-grow: 1;
                    margin-top: 0;
                }
            }
            .field.nr {
                .label {
                    width: 80px;
                    flex-grow: 0;
                    text-align: right;
                }
                .his-input {
                    margin-left: 15px;
                }
            }

            .his-input {
                max-width: 150px;
            }
        }
    }
}
