.contact-bar {
    display: none;
    min-height: 32px;

    &__container.container {
        font-size: 1.2rem;
        flex-direction: row;
        max-width: $container-desktop-width;

        figure {
            @include flex(column, center);
            margin: 0 8px 0 0;
            @include mq($screen-xlg) {
                margin: 0;
            }

            img {
                max-width: initial;
            }
        }
        ul {
            @include flex(row, space-between);
            margin: 0;
            width: 100%;

            li {
                @include flex(row, center);

                a {
                    margin: auto 0 auto 0;

                    &:hover {
                        color: $black;
                    }
                }
            }
        }
    }
}

header.header {
    background: $white;

    .container {
        margin: 0 0 0 0;
        max-width: $container-desktop-width;
    }
    .header__wrapper {
        @include flex(row, space-between);
        padding: 0;
        flex-wrap: wrap;
        max-height: 80px;

        * {
            margin-top: auto;
            margin-bottom: auto;
        }
        .header__buttons {
            @include flex(row, space-between);
            order: 2;
            margin-right: 0;
            max-width: 120px;
            padding-right: 35px;
            @include mq($screen-s) {
                max-width: 290px;
            }
            @include mq($screen-m) {
                padding: 0 $padding-lg 0 0;
            }
            @include mq($screen-l) {
                max-width: 31rem;
                padding-right: 0;
                justify-content: flex-start;
            }

            .header__minicart {
                border: none;
                display: flex;
                max-height: 40px;

                .cart-title {
                    display: none;

                    .title {
                        @include t3;
                        opacity: 0.5;
                        min-width: 60px;
                        font-weight: 700;
                        line-height: 13px;
                    }
                    .cart-subtotal {
                        margin-top: 3px;
                        font-weight: 700;
                        font-size: 1.6rem;
                        line-height: 1.3rem;
                        font-family: $font-open-sans-condensed;
                    }
                }
                .header-button {
                    margin: 0 0 0 0;
                    max-width: 50px;
                    min-height: 30px;

                    &__counter {
                        top: -4px;
                        right: 4px;
                        width: 18px;
                        height: 18px;

                        &-dot {
                            width: 100%;
                            height: 100%;
                            display: block;
                            position: absolute;
                            border-radius: 50px;
                            background: $primary;
                        }
                    }
                    &__summary-count {
                        margin: 0;
                        clip: unset;
                        width: 100%;
                        z-index: 10;
                        height: 18px;
                        color: $white;
                        display: flex;
                        padding-top: 4px;
                        padding-left: 2px;
                        justify-content: center;
                    }
                }
                svg {
                    width: 26px;
                    height: 25px;
                }
            }
            .minicart {
                position: relative;
                .only_mob {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 0;
                    display: block;
                    @include mq($screen-s) {
                        display: none;
                    }
                }
            }
            .header__customer-link {
                * {
                    margin: auto 10px auto 0;
                }
                a,
                .welcome-msg {
                    @include t3;
                    font-weight: 700;

                    &.account-link {
                        opacity: 0.5;
                        line-height: 1.3rem;
                    }
                    &.login-link {
                        color: $primary;
                    }
                }
                .welcome-msg {
                    color: $primary;
                }
            }
            .header__customer-links {
                display: flex;

                a {
                    font-weight: 700;
                    font-size: 1.2rem;
                    line-height: 1.3rem;
                }
                .account-link {
                    opacity: 0.5;

                    a:hover {
                        color: $black;
                    }
                }
                .login-link a {
                    color: $primary;
                }
            }
            .cart-title {
                display: none;

                .title {
                    min-width: 40px;
                }
            }
            .wrapper-v.customer-links {
                @include flex(row, space-evenly);
                left: 0;
                opacity: 1;
                z-index: 2;
                width: 100%;
                bottom: -40px;
                padding: 1rem;
                margin-right: 0;
                position: absolute;
                background: $white;
                visibility: visible;
                border-bottom: 1px solid $gray;
                transition: all 0.1s ease-in-out;

                &.hidden {
                    bottom: 0;
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
        .header__navigation {
            @include flex(row, space-between);
            order: 5;
            height: 0;
            width: 100%;
            max-width: 1368px;
            z-index: 10;
            margin: -5px auto -3rem auto;
            background: $primary;

            .his-input {
                margin-right: 0;
            }

            .menu-main-desktop {
                margin: 0;
                display: none;
                min-width: 700px;
                &__inner-list {
                    display: none;
                }
                &__list {
                    @include flex(row, space-between);
                    margin: 0;
                    height: 100%;

                    li {
                        height: 100%;
                        display: flex;
                        position: relative;
                        padding-left: 2rem;
                        padding-right: 2rem;
                        &.menu-main-desktop__item--parent {
                            padding-right: 0;
                        }
                        svg {
                            width: 10px;
                            height: 10px;
                            margin-left: 10px;
                        }
                        a {
                            @include menu-typography();

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
                .list {
                    display: none;
                    box-sizing: border-box;
                    background: $primary-hover;

                    li {
                        padding: 5px 24px;

                        a {
                            padding: 0;
                        }
                    }
                }
                .list__item {
                    &:hover {
                        .list {
                            @include flex(column, flex-start);
                            left: 100%;
                            top: 0;
                            padding: 0;
                            flex-wrap: wrap;
                            min-width: 230px;
                            position: absolute;

                            li:hover {
                                background-color: $primary-hover-darker;
                            }
                        }
                    }
                }
                &__item {
                    .menu-main-desktop__inner-list--level1 {
                        li.list__item.menu-main-desktop__inner-item.menu-main-desktop__inner-item--level1:after {
                            content: '';
                            width: 100%;
                            position: absolute;
                            top: -24px;
                            left: 0;
                            height: auto;
                            background: inherit;
                            z-index: -1;
                        }
                        .menu-main-desktop__inner-list--level2 {
                            z-index: 1;
                        }
                    }

                    .menu-main-desktop__inner-list--level1 {
                        display: none;
                        box-sizing: border-box;
                        background: $primary-hover;

                        li {
                            padding: 5px 24px;

                            a {
                                padding: 0;
                            }
                        }
                    }
                    &:hover {
                        background: $primary-hover;

                        .menu-main-desktop__inner-list--level1 {
                            @include flex(column, flex-start);
                            left: 0;
                            top: 60px;
                            padding: 24px 0;
                            flex-wrap: wrap;
                            min-width: 230px;
                            position: absolute;

                            li:hover {
                                background-color: $primary-hover-darker;
                            }
                        }
                    }
                }
            }
        }
        .side-menu {
            order: 3;
            height: 100%;
            display: flex;

            &__trigger {
                border: 0;
                padding: 0;
                max-height: 25px;
            }
        }
        .header__brief-info {
            @include flex(row, space-between);
            flex-grow: 2;
            display: none;
            padding: 0 1rem;
            max-width: 800px;
            @include mq($screen-l) {
                order: 2;
                display: flex;
            }
            @include mq($screen-xl) {
                max-width: 66rem;
            }

            &-awards,
            &-advantages {
                @include flex(row, space-between);

                li {
                    @include flex(row, flex-start);

                    p {
                        opacity: 0.5;
                        font-size: 1.2rem;
                        line-height: 1.3rem;
                    }
                }
            }
            &-advantages {
                li {
                    min-width: 110px;

                    p {
                        width: 100%;
                    }
                }
            }
            &-awards {
                li {
                    margin-right: 8px;
                }
            }
        }
        .logo {
            order: 1;
            max-width: unset;
            padding: 12px 0 12px 8px;
            justify-content: flex-start;
            @include mq($screen-xlg) {
                padding: 12px 0;
                margin: 0 0 0 -0.5rem;
            }

            &__image {
                width: 100%;
                height: auto;
                max-width: 180px;
                padding-right: 20px;
            }
        }
    }
    figure {
        @include flex(column, center);
        margin: 0 8px 0 0;

        img {
            max-width: initial;
        }
    }
}

@media screen and (min-width: $screen-s) {
    header.header {
        .container {
            margin: 0 auto;
        }
        .header__wrapper {
            .header__buttons {
                .header__minicart .cart-title {
                    @include flex(column, flex-start);
                    margin-right: 10px;
                }
                .wrapper-v.customer-links {
                    padding: 0;
                    width: auto;
                    position: static;
                    border-bottom: 0;
                    flex-direction: column;
                    justify-content: space-evenly;

                    &.hidden {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            .logo {
                justify-content: flex-start;

                &__image {
                    height: auto;
                }
            }
        }
    }
}

@media screen and (min-width: $screen-m) {
    #html-body:has(.promotional-banner) {
        header.header {
            padding-bottom: 8rem;
        }
    }
}

@media screen and (min-width: $screen-l) {
    .contact-bar {
        display: flex;
    }
    header.header {
        margin-bottom: 0;
        padding-bottom: 35px;

        .header__wrapper {
            justify-content: space-between;

            .header__brief-info {
                order: 2;
                display: flex;
            }
            .header__navigation {
                height: 60px;

                .menu-main-desktop {
                    display: flex;
                }
            }
            .side-menu {
                display: none;
            }
            .logo {
                flex: unset;

                &__image {
                    height: 55px;
                    min-width: 180px;
                }
            }
        }
    }
}

@media screen and (min-width: $screen-xl) {
    header.header .header__wrapper {
        .logo__image {
            max-width: 100%;
            height: 64px;
        }
    }
}

@media screen and (max-height: 950px) and (min-width: $screen-l) {
    .menu-main-desktop__inner-list--level1 {
        padding: 0 !important;

        li {
            padding: 2px 25px !important;
        }
    }
}
