.datefinder-index-index,
.catalog-category-view,
.catalogsearch-result-index {
    .toolbar.toolbar-products {
        @include t3;
        border: 0;
        padding: 10px 15px;
        display: flex;
        font-weight: 700;
        background: $beige1;
        justify-content: center;

        .toolbar__sorter {
            order: 1;
            width: 100%;
            display: flex;
            padding: 5px 8px 0;
            position: relative;

            .select2 {
                min-width: 168px;
            }

            .select__field {
                border: solid 2px $secondary;
            }

            svg {
                right: 25px;
                width: 10px;
                height: 40px;
                position: absolute;
            }

            &-select {
                @include t3;
                width: 100%;
                font-weight: 700;
                padding: 0 0 0 12px;
            }

            &-label {
                @include t3;
                color: $black;
                font-weight: 700;
                margin: 0 16px 0 0;
            }

            .select2-container {
                .select2-results__option {
                    height: 21px;
                    line-height: 21px;
                    font-size: 12px;
                }
                .select2-selection--single {
                    border: 2px solid $secondary;
                    height: 48px;
                    .select2-selection__rendered {
                        padding: 0 80px 0 16px;
                        line-height: 47px;
                        outline: none;
                    }
                    .select2-selection__arrow {
                        height: 40px;
                        display: none;
                        b {
                            padding: 3px;
                            border-color: $primary;
                        }
                    }
                }
            }

            .select2-container.select2-container--open {
                .select2-selection--single {
                    outline: none;
                    border-radius: 0;
                }
            }
            
            a.toolbar__sorter-link {
                // display: none;
                background: transparent!important;
                padding: 0;
                min-width: auto;
                svg {
                    right: 15px;
                }
            }
        }
        .toolbar__pager {
            width: auto;
            margin: auto 0;
            display: block;

            .pager__list {
                justify-content: flex-end;

                .pager__item {
                    width: 40px;
                    height: 40px;
                    margin: 0 4px;
                    color: $secondary;
                    background: $white;
                    border: 2px solid $secondary;

                    .pager__link {
                        height: 35px;
                        min-width: 35px;
                        min-height: 35px;
                        font-weight: 700;
                        color: $secondary;
                        font-size: 1.4rem;

                        &--prev {
                            transform: rotate(180deg);
                        }
                    }

                    &:first-child {
                        margin: 0 4px 0 0;
                    }
                    &:last-child {
                        margin: 0 0 0 4px;
                    }
                    &.pager__item--current {
                        color: white;
                        background: $secondary;
                    }
                }
            }
        }
        .icon.pager__icon {
            width: 10px;
            transform: rotate(-90deg);
        }
    }
    .toolbar__sorter {
        padding: 0;
        width: auto;
        margin: auto 0;
        border-width: 0;
    }
    @media screen and (min-width: $screen-s) {
        .toolbar.toolbar-products .toolbar__sorter {
            &-select {
                @include t3;
                width: 100%;
                font-weight: 700;
                padding: 0 80px 0 16px;
            }
            svg {
                right: 35px;
            }
        }
    }
    @media screen and (min-width: $screen-l) {
        .toolbar.toolbar-products {
            justify-content: space-between;

            .toolbar__sorter {
                width: auto;
                display: flex;
                padding: 0 8px;
            }
        }
    }
}

body.datefinder-index-index {
    div.toolbar.toolbar-products {
        background-color: $white;
        border: none;
        padding: 0 0 11px 0;
        justify-content: flex-end;
        margin-top: -10px;
        .toolbar__sorter {
            .select2-container {
                .select2-selection--single {
                    .select2-selection__arrow {
                        display: block;
                        top: 5px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $screen-l) {
    body.datefinder-index-index {
        div.toolbar.toolbar-products {
            margin-top: -25px;
            justify-content: flex-end;
        }
    }
}