.newsletter-form {
    margin: 0 0 $margin-xl 0;
    &__checkbox {
        margin: $margin-m 0;
        .checkbox__label {
            color: $black;
            &::after {
                background-color: $secondary;
            }
        }
    }
    &__button {
        @include btn-primary;
        font-family: $font-open-sans-condensed;
        font-weight: $font-weight-bold;
        font-size: $font-size-md;
        padding: $padding-small $padding-xxlarge;
        color: $white;
        &:hover {
            @include btn-primary-hover;
            text-decoration: none;
            color: $white;
        }
    }
}
