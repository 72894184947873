.age-categories {
    &>div,
    &>p {
        @include flex(column, space-evenly);
        width: $full-width;
        padding: 0;
        flex-wrap: wrap;
        padding: $padding-m;
        background: $secondary;
        margin-bottom: $padding-l;
        @include mq($screen-l) {
            @include flex(row, space-evenly);
            flex-wrap: nowrap;
        }
    }
}

a.his-category-widget {
    @include flex(column, flex-start);
    max-height: 90px;
    margin: $padding-m;
    position: relative;
    box-sizing: border-box;
    &:hover {
        text-decoration: none;
    }
    .item-title {
        width: $full-width;
        margin: 0;
        &.his-btn-primary {
            @include centerItem;
            height: 4rem;
            @include mq($screen-l) {
                display: none;
            }
        }
    }
    .item-button {
        left: 10%;
        bottom: 0;
        width: 80%;
        display: none;
        margin-bottom: 25px;
        position: absolute;

        &::before,
        &::after {
            top: 0;
            width: 50px;
            content: " ";
            height: 100%;
            position: absolute;
            background: $primary;
            transition: background-color 0.5s;
        }
        &::after {
            right: -14px;
            clip-path: polygon(
                100% 0%,
                75% 50%,
                100% 100%,
                50% 100%,
                50% 50%,
                50% 0
            );
        }
        &::before {
            left: -14px;
            clip-path: polygon(
                50% 0%,
                50% 50%,
                50% 100%,
                0% 100%,
                25% 50%,
                0% 0%
            );
        }
        
        &:hover {
            &::before,
            &::after {
                background-color: $primary-hover;
            }
        }
    }
    .item-image {
        display: none;
        max-height: 100%;
    }
}

@media screen and (min-width: $screen-l) {
    .age-categories {
        @include flex(row, space-evenly);
        flex-wrap: nowrap;
    }

    a.his-category-widget {
        max-width: 25%;
        max-height: 100%;
        width: 25%;
        .item-title {
            display: none;
        }
        .item-button {
            display: block;
        }
        .item-image {
            display: block;
            width: $full-width;
            img {
                width: $full-width;
            }
        }
    }
}
