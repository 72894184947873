.wishlist {
    @include flex(row, center);
    flex-wrap: wrap;
    margin-top: $margin-sm;
    padding: 0;
    width: 100%;
    @include mq($screen-xlg) {
        @include flex(row, center);
    }
    &__item {
        position: relative;
        width: 31.8rem;
        &:first-child {
            margin: 0 3rem 0 0;
        }
    }
    &__item-name {
        margin: $margin-root auto;
        padding: 0 $padding-normal;
        height: 5.5rem;
        @include mq($screen-l) {
            margin: $margin-root auto;
        }
        a {
            font-family: $font-libre-bold;
            font-size: $font-size-xlarge;
            &:hover,
            &:focus {
                color: $primary;
                text-decoration: none;
            }
        }
    }
    .product-grid-item {
        margin: 0 $margin-root $margin-s $margin-root;
        @include mq($screen-xlg) {
            margin: 0 10px $margin-xlarge 10px;
        }
        &:first-child {
            margin: 0 $margin-root $margin-s $margin-root;
            @include mq($screen-xlg) {
                margin: 0 10px $margin-xlarge 0;
            }
        }
        @include mq($screen-l) {
            width: 28rem !important;
        }
        @include mq($screen-xl) {
            width: 31.8rem !important;
        }
    }
    .price-box {
        @include flex(row, flex-end);
        padding: 0 $padding-normal $padding-normal 0;
    }
    .price__value--old {
        display: inline-block;
        font-size: $font-size-lg;
        text-decoration: line-through;
        margin: 1.1rem 0 0 0;
    }
    .price__value--special {
        @include price($font-size-xlg, $primary);
    }
    .wishlist__actions {
        @include mq($screen-m) {
            width: auto;
            position: absolute;
            right: 0;
            top: -2.4rem;
        }
        @include mq($screen-l) {
            top: $margin-md;
        }
        @include mq($screen-xl) {
            top: -2.4rem;
        }
    }
    .actions-group__button {
        @include btn-secondary;
        border: none;
        color: $white;
        &:hover,
        &:focus {
            @include btn-secondary-hover;
        }
    }
}
.wishlist-noitem {
    margin: $margin-xl;
    &__container {
        @include flex(column, center);
        align-items: center;
        width: $full-width;
    }
    &__icon {
        width: $wishlist-icon-size;
        height: $wishlist-icon-size;
        svg {
            fill: $secondary;
            width: $full-width;
            height: $full-width;
            object-fit: contain;
        }
    }
    &__button {
        @include btn-primary;
        font-family: $font-open-sans-condensed;
        font-weight: $font-weight-bold;
        font-size: $font-size-md;
        line-height: $line-height-extra-large;
        color: $white;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding: 0.5rem $padding-medium;
        margin: $margin-mxl 0 0 0;
        &:hover,
        &:focus {
            @include btn-primary-hover;
            color: white;
            text-decoration: none;
        }
    }
    h3 {
        font-size: $font-size-xxlg;
        line-height: $line-height-xxl;
        text-align: center;
        margin: $margin-normal 0 0 0;
    }
    p {
        font-size: $font-size-lg;
        text-align: center;
        line-height: $line-height-large;
        margin: $margin-small 0 0 0;
    }
}
.wishlist-index-index {
    .dashboard {
        .row {
            @include mq($screen-xlg) {
                max-width: $container-desktop-width;
                margin: 0 auto;
                padding: 0;
            }
        }
        &__content {
            @include mq($screen-xlg) {
                width: 103rem;
            }
        }
    }
    .more-info {
        display: none;
    }
}
.wishlist-index-share {
    #maincontent {
        margin: 0 auto;
        background: $white;
    }
    .dashboard {
        .row {
            width: $full-width;
            margin: 0;
            padding: 0 1rem;
            @include mq($screen-xlg) {
                margin: 0 auto;
                padding: 0;
            }
        }
        &__content {
            max-width: $customer-input-width;
        }
    }
    .fieldset {
        margin: $margin-xl 0;
    }
    .input__label {
        font-family: $font-open-sans-condensed;
        font-weight: $font-weight-bold;
        font-size: $font-size-md;
    }
    .input__field--textarea {
        width: $full-width;
        max-width: $customer-input-width;
    }
    .actions-group__button {
        @include btn-primary;
        &:hover,
        &:focus {
            @include btn-primary-hover;
        }
    }
    .actions-group__link {
        @include btn-secondary;
        color: $white;
        text-transform: uppercase;
        display: block;
        font-size: $font-size-md;
        font-weight: $font-weight-bold;
        text-align: center;
        padding: 13px $padding-xlarge;
        &:hover,
        &:focus {
            @include btn-secondary-hover;
        }
    }
}
