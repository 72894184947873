$primary                        : #b21d2b;
$secondary                      : #c7a17a;
$orange                         : #ff8400;

$message__margin-top            : $spacer--medium !default;
$message__padding               : $spacer $spacer--medium !default;
$message__padding\@medium       : $spacer $spacer--extra-large !default;
$message__margin                : $spacer auto !default;
$message__font-family           : "OpenSans", monospace;
$message__font-size             : 1.4rem !default;
$message__transition            : $transition-base !default;
$message__background            : $white !default;
$message__background--success   : $white !default;
$message__background--error     : $white !default;
$message__color                 : $orange !default;
$message__color--success        : $secondary !default;
$message__color--error          : $primary !default;
$message__border-width          : 1px 1px $spacer--extra-small 1px !default;
$message__border-color          : $border-color-base $border-color-base $orange $border-color-base !default;
$message__border-style          : solid !default;
$message__border-color--success : $border-color-base $border-color-base $secondary $border-color-base !default;
$message__border-color--error   : $border-color-base $border-color-base $primary $border-color-base !default;
$message__border-radius         : 0 !default;
$message__icon-spacer           : $spacer--semi-medium !default;
$message__icon-width            : $spacer--semi-medium + $spacer--small !default;
$message__icon-order            : -1 !default;
$message__icon-margin           : 0 $spacer 0 0 !default;
$message__icon-fill             : $orange !default;
$message__icon-fill--success    : $secondary !default;
$message__icon-fill--error      : $primary !default;
