.dashboard-items__table {
    .table {
        width: $full-width;
        table-layout: fixed;
        border-spacing: 0 $margin-normal;
        border: none;
        margin: 0;
        @include mq($screen-m) {
            margin: 1rem 0 0 0;
        }
        thead {
            display: none;
            @include mq($screen-m) {
                display: table-header-group;
            }
        }
        th {
            font-family: $font-open-sans-condensed;
            font-weight: $font-weight-bold;
            font-size: $font-size-normal;
            text-transform: uppercase;
            color: $secondary;
            background: transparent;
            border: none;
            padding: 0 $padding-sm;
            &.order {
                @include mq($screen-m) {
                    padding: 0 $padding-sm 0 $padding-md;
                }
                @include mq($screen-xlg) {
                    min-width: 15rem;
                    width: 15rem;
                    max-width: 15rem;
                }
            }
            &.date {
                @include mq($screen-xl) {
                    min-width: 11rem;
                    width: 11rem;
                    max-width: 11rem;
                }
            }
            &.ship-to {
                @include mq($screen-xlg) {
                    min-width: 20rem;
                    width: 20rem;
                    max-width: 20rem;
                }
            }
            &.total {
                @include mq($screen-xlg) {
                    min-width: 11rem;
                    width: 11rem;
                    max-width: 11rem;
                }
            }
            &.status {
                @include mq($screen-xlg) {
                    min-width: 15rem;
                    width: 15rem;
                    max-width: 15rem;
                }
            }
        }
        tr {
            border-bottom: $border-gold;
            @include mq($screen-m) {
                border: none;
            }
        }
        td {
            font-family: $font-open-sans;
            font-size: $font-size-lg;
            background: $beige1;
            border: none;
            padding: $padding-small $padding-md;
            @include mq($screen-m) {
                padding: $padding-normal $padding-sm;
            }
            &:first-child {
                @include mq($screen-m) {
                    padding: $padding-normal $padding-sm $padding-normal $padding-md;
                }
            }
            &::before {
                min-width: 30%;
                width: 30%;
                max-width: 30%;
            }
            a {
                text-decoration: none;
                color: $primary;
                margin: 0 $margin-md 0 0;
            }
        }
        .price {
            font-family: $font-open-sans-bold;
        }
    }
}
.sales-order-history,
.review-customer-index,
.wishlist {
    .heading--page {
        font-family: $font-libre-bold;
        font-size: $font-size-xxlg;
        color: black;
        text-align: left;
    }
    .dashboard-items__orders {
        margin: 0;
    }
    .toolbar {
        border: none;
        &__amount-content {
            font-size: $font-size-normal;
            color: $gray6;
        }
        .select {
            margin: 0;
        }
        &--secondary {
            padding: 0 0 $padding-xxlarge 0;
            .toolbar__pager {
                flex-grow: 1;
                flex-basis: 0;
                margin: 0;
            }
        }
        .pager__item {
            font-size: $font-size-normal;
        }
        .pager__link {
            &:hover,
            &:focus {
                color: $secondary;
                text-decoration: none;
            }
            &--prev {
                svg {
                    transform: rotate(90deg);
                }
            }
            &--next {
                svg {
                    transform: rotate(-90deg);
                }
            }
        }
        .pager__icon {
            padding: $padding-xs;
        }
    }
    .dashboard__content {
        @include mq($screen-m) {
            padding: 0;
        }
    }
    .dashboard-items__content {
        padding: 0;
    }
    .toolbar__limiter-label {
        font-size: $font-size-normal;
        margin: 0 $margin-sm 0 0;
    }
    .select2-container .select2-selection--single {
        height: auto;
        border: $border-gold;
        .select2-selection__rendered {
            font-size: $font-size-normal;
            color: $black;
        }
        .select2-selection__arrow {
            b {
                border-color: $secondary;
            }
        }
    }
}
.review-customer-index {
    .dashboard-items__table {
        .table {
            th {
                &.date {
                    padding: 0 0 0 2.5rem;
                }
            }
        }
    }

}
