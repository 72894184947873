.datefinder {
    width: $full-width;
    background: $primary;
    padding: $padding-lg;
    margin: $margin-m 0 0 0;
    @include mq ($screen-l) {
        grid-column-start: 1;
        grid-column-end: span 1;
        grid-row-start: 2;
        grid-row-end: span 1;
    }
    @include mq($screen-xl) {
        margin: $margin-m 0 $margin-s 0;
    }
    &-wrapper {
        width: $full-width;
    }
    &-form {
        @include flex(row, flex-start);
        align-items: center;
        flex-wrap: wrap;
        @include mq($screen-l) {
            @include flex(row, space-between);
        }
        &__full {
            .datefinder-button {
                @include mq($screen-l) {
                    width: $full-width;
                }
                @include mq($screen-xlg) {
                    width: 30%;
                    margin: $margin-m 0 $margin-root 0;
                }
            }
        }
    }
    &-top {
        @include flex(row, center);
        align-items: center;
        width: $full-width;
        margin: 0 0 $margin-s 0;
    }
    &-icon {
        width: 3.6rem;
        height: 4.6rem;
        &__svg {
            width: $full-width;
            height: $full-width;
            object-fit: contain;
        }
    }
    &-heading {
        @include h4;
        color: $white;
        margin: $margin-xs 0 0 $margin-s;
    }
    &-picker {
        width: $full-width;
        margin: $margin-m 0;
        @include mq($screen-l) {
            width: $width-12;
            margin: $margin-m 0 0 0;
        }
        @include mq($screen-xl) {
            width: $width-20;
            margin: $margin-m 0 0 0;
        }
        @include mq($screen-xlg) {
            width: $width-13;
            margin: 0;
        }
        &__year {
            &> select {
                @include input;
                padding: 0 $padding-s;
                appearance: none;
                background: $white url('../images/icons/arrow-down.svg') no-repeat 97% center;
                background-size: 1rem;
                @include mq($screen-l) {
                    background: $white url('../images/icons/arrow-down.svg') no-repeat 93% center;
                }
                option {
                    @include placeholder;
                }
            }
        }
        &__full {
            @include flex(row, space-between);
            align-items: center;
            width: $full-width;
            @include mq($screen-xlg) {
                width: 66%;
                margin: $margin-m 0 $margin-root 0;
            }
            .datefinder-picker__select {
                width: 30%;
                &> select {
                    @include input;
                    padding: 0 $padding-s;
                    appearance: none;
                    background: $white url('../images/icons/arrow-down.svg') no-repeat 92% center;
                    background-size: 1rem;
                    option {
                        @include placeholder;
                    }
                }

            }
        }
    }
    &-button {
        width: $full-width;
        margin: 0;
        @include mq($screen-l) {
            width: fit-content;
            margin: $margin-m 0 0 0;
        }
        @include mq($screen-xlg) {
            margin: 0;
        }
        &__submit {
            @include btn-secondary;
            width: $full-width;
            display: block;
            margin: 0;
            line-height: $line-height-extra-large;
            padding: 0.5rem;
            font-size: 1.3rem;
            &:hover,
            &:focus {
                @include btn-secondary-hover;
            }
        }
    }
}
.datefinder-index-index {
    #maincontent {
        margin: 0 auto;
        background: $white;
        width: $full-width;
        padding: $padding-medium;
        @include mq($screen-m) {
            padding: 0 $padding-medium;
        }
    }
    .columns {
        max-width: $container-desktop-width;
        @include mq($screen-m) {
            flex-direction: column-reverse;
        }
        @include mq($screen-l) {
            flex-direction: row;
        }
        @include mq($screen-xlg) {
            margin: 0 auto;
        }
    }
    .column.main {
        @include mq($screen-m) {
            width: $full-width;
            flex: 1 0 $full-width;
            max-width: $full-width;
        }
        @include mq($screen-l) {
            width: 67%;
            flex: 1 0 67%;
            max-width: 67%;
        }
        @include mq($screen-xl) {
            width: 72%;
            flex: 1 0 72%;
            max-width: 72%;
        }
        @include mq($screen-xlg) {
            width: 75%;
            flex: 1 0 75%;
            max-width: 75%;
        }
    }
    .sidebar {
        @include mq($screen-m) {
            width: $full-width;
            flex: 1 0 $full-width;
            max-width: $full-width;
        }
        @include mq($screen-l) {
            width: 30%;
            flex: 1 0 30%;
            max-width: 32rem;
            margin: 0 $margin-s 0 0;
        }
    }
    .loader {
        position: fixed;
    }

}
.datefinder-list {
    &__tabs {
        @include flex(row, space-between);
        flex-wrap: wrap;
        width: $full-width;
        .title {
            position: relative;
            width: $full-width;
            background: $secondary;
            padding: $padding-medium;
            margin: 0 0 $margin-root 0;
            text-align: center;
            @include mq($screen-sm) {
                width: 32%;
            }
            a {
                @include flex(row, flex-start);
                align-items: center;
            }
            h2 {
                font-family: $font-open-sans-bold;
                font-size: $font-size-lg;
                text-align: center;
                color: $white;
                line-height: $line-height-small;
                margin: 0;
            }
            &.active {
                background: $primary;
                &::after {
                    @include mq($screen-sm) {
                        content: '';
                        width: 1.2rem;
                        height: 1.2rem;
                        background: $primary;
                        position: absolute;
                        bottom: -8%;
                        left: 50%;
                        transform: translateX(-50%) rotate(45deg);
                    }
                }
            }
        }
    }
    &__icon {
        width: 3.1rem;
        height: 3.7rem;
        margin: 0 $margin-root 0 0;
        svg {
            width: $full-width;
            height: $full-width;
            object-fit: contain;
        }
    }
    &__tabs-content {
        width: $full-width;
        .content {
            display: none;
            &.active {
                @include flex(column, center);
                align-items: center;
                width: $full-width;
                padding: 0 0 $padding-lg 0;
                @include mq($screen-m) {
                    @include flex(row, center);
                    flex-wrap: wrap;
                    scroll-margin-top: 370px;
                }
            }
        }
    }
}
.datefinder-item {
    @include flex(column, flex-start);
    position: relative;
    width: 31.8rem;
    border: $border-gold-bold;
    padding: $padding-normal;
    text-decoration: none;
    margin: $margin-md 0 0 0;
    box-shadow: $box-shadow-gold;
    transition: $transition;
    @include mq($screen-m) {
        margin: $margin-s;
    }
    @include mq($screen-l) {
        margin: $margin-s 0.5rem;
    }
    @include mq($screen-xl) {
        margin: $margin-s $margin-s 0 0;
    }
    @include mq($screen-xlg) {
        margin: $margin-s $margin-m 0 0;
        &:nth-child(3n) {
            margin: $margin-s 0 0 0;
        }
    }
    &:hover,
    &:focus {
        text-decoration: none;
        box-shadow: $box-shadow-gold-hover;
    }
    .price-box {
        text-align: right;
        .product-view__price-unit {
            margin: 0;
            color: $gray6;
            font-size: 1.6rem;
        }
    }
    &__image {
        width: $full-width;
        height: 24rem;
        @include centerItem;
        color: $white;
        img {
            width: $full-width;
            height: $full-width;
            object-fit: contain;
        }
    }
    &__logo {
        width: $full-width;
        height: 4rem;
        margin: $margin-normal 0 10px;
        display: flex;
        align-items: center;
        img {
            width: $full-width;
            height: $full-width;
            object-fit: contain;
            margin-left: 10px;
        }
    }
    &__title {
        width: $full-width;
        height: 7.6rem;
        margin: 0;
        h2 {
            font-family: $font-libre-bold;
            font-weight: $font-weight-bold;
            font-size: $font-size-xlarge;
            text-align: center;
        }
    }
    &__description {
        font-family: $font-open-sans;
        font-size: $font-size-normal;
        line-height: $line-height-normal;
        text-align: center;
    }
    &__price {
        @include flex(row, flex-end);
        font-family: $font-open-sans-condensed;
        font-weight: $font-weight-bold;
        font-size: $font-size-xlg;
        color: $primary;
        .regular-price {
            .price {
                @include price($font-size-lg, $gray2);
                margin: 0 $margin-small 0 0;
                text-decoration: line-through;
            }
        }
    }
}
.datefinder-widget-container {
    @include flex(column, flex-start);
    align-items: flex-start;
    width: $full-width;
    @include mq($screen-l) {
        max-width: 31.8rem;
    }
    .datefinder-wrapper {
        background: $primary;
        @include mq($screen-l) {
            margin: 7rem 0 0 0;
        }
    }
    .datefinder-form {
        position: relative;
        padding: 5rem $padding-lg $padding-lg $padding-lg;
    }
    .datefinder-heading {
        color: $white;
        margin: 0;
    }
    .datefinder-calendar {
        display: none;
        width: 11rem;
        border-radius: 1.1rem;
        border: $border-gold;
        align-items: center;
        overflow: hidden;
        background: $white;
        position: absolute;
        left: 50%;
        top: -18%;
        transform: translateX(-50%);
        @include mq($screen-l) {
            @include flex(column, flex-start);
        }
        &__year {
            background: $primary;
            width: $full-width;
            p {
                font-family: $font-open-sans-bold;
                font-size: $font-size-md;
                text-align: center;
                color: $white;
                margin: 0;
            }
        }
        &__day {
            p {
                font-family: $font-open-sans-bold;
                font-size: $font-size-xxxlarge;
                text-align: center;
                line-height: 4.9rem;
                margin: 0;
            }
        }
        &__month {
            p {
                font-family: $font-open-sans-bold;
                font-size: $font-size-md;
                line-height: 1.7rem;
                color: $black;
                margin: 0;
            }
        }
        &__weekday {
            p {
                font-family: $font-open-sans-bold;
                font-size: $font-size-md;
                line-height: 1.7rem;
                color: $primary;
                margin: 0;
            }
        }
    }
    .datefinder-picker__full {
        flex-direction: column;
        width: $full-width;
    }
    .datefinder-picker__select {
        width: $full-width;
        margin: $margin-small 0;
    }
    .datefinder-button {
        width: $full-width;
    }
}
.datefinder-text {
    margin: $margin-xlarge 0;
    @include mq($screen-l) {
        column-count: 2;
        column-gap: 2rem;
        margin: 0 0 $margin-xlarge 0;
    }
    @include mq($screen-xl) {
        margin: $margin-m 0 $margin-xl 0;
    }
}
