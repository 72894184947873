@import "common/variables";
@import "../Snowdog_Components/components/Atoms/variables/variables";
@import "mixins";
@import "../Snowdog_Components/components/styles/non-critical";
@import "theme";

// Show sections hidden in critical.scss
.page-main,
.footer {
    display: block !important;
}
