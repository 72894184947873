.checkout-cart {
    &-index {
        #maincontent {
            width: $full-width;
            margin-top: 0;
            padding: 2rem;
            background: $white;
            @include mq($screen-m) {
                padding: 0;
            }
            &.container {
                margin: 0 auto;
            }
            .messages {
                .message {
                    margin: 0 auto;
                    &:after {
                        flex-shrink: 0;
                    }
                    @include mq($screen-m) {
                        margin: 28px auto 0 auto; 
                    }
                }
            }
        }
        .heading--page {
            text-transform: none;
        }
    }
    &-title {
        width: $full-width;
        font-family: $font-libre;
        font-size: $font-size-xxlg;
        font-weight: $font-weight-bold;
        color: $primary;
        margin: 2.6rem 0;
        text-align: left;
        @include mq($screen-l) {
            margin: 5rem 0;
        }
    }
}
.cart {
    width: 100%;
    margin: 0;
    @include mq($screen-m) {
        justify-content: space-between;
        padding: 3rem;
    }
    &__summary {
        width: 100%;
        padding: 0;
        @include mq($screen-m) {
            max-width: 28rem;
            order: 1;
        }
        @include mq($screen-xl) {
            max-width: 32rem;
        }
    }
    &__main {
        width: 100%;
        @include mq($screen-m) {
            width: 58%;
        }
        @include mq($screen-l) {
            width: 68%;
        }
        @include mq($screen-xl) {
            width: 100%;
            max-width: 87rem;
        }
        @include mq($screen-xlg) {
            max-width: 102rem;
        }
    }
}
.cart-empty {
    width: $full-width;
    padding: 11rem 0;
    @include mq($screen-m) {
        padding: 11rem 0 20rem 0;
    }
    &-container {
        width: $full-width;
        max-width: 500px;
        @include centerItem;
        flex-direction: column;
        margin: 0 auto;
    }
    img {
        width: 10.5rem;
        height: auto;
    }
    h2 {
        font-family: $font-libre;
        font-weight: $font-weight-bold;
        font-size: $font-size-xxlg;
        text-align: center;
        color: $primary;
        line-height: 4.5rem;
        margin: 2.5rem auto 1rem auto;
    }
    p {
        font-family: $font-open-sans;
        font-size: $font-size-lg;
        text-align: center;
    }
    button {
        width: $full-width;
        max-width: 28.6rem;
        @include btn-primary;
        &:hover,
        &:focus {
            @include btn-primary-hover;
        }
    }
    a {
        @include btn-text;
    }
}
.cart-grid {
    &__button {
        @include btn-primary;
        border: none;
        color: white;
        display: none;
        &:hover,
        &:focus {
            @include btn-primary-hover;
        }
    }
}

@media screen and (max-width: $screen-m) {
    .cart {
        &__summary {
            order: 2;
        }
        &__main {
            order: 1;
        }
    }
}
