.three-gifts-link {
    position: relative;
    // margin-bottom: 50px;
    @include mq($screen-m) {
        // margin-bottom: 100px;
    }
    a {
        width: 100%;
    }
    &__title {
        text-align: center!important;
    }
    &__list {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: $full-width;
        gap: 32px;
        @include mq($screen-xl) {
            flex-direction: row;
        }
    }
    &__list-item {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        background-color: $primary;
        width: 100%;
        position: relative;
        padding: 16px 5px 16px 74px;
        border-radius: 77px 0px 0px 77px;
        @include mq($screen-m) {
            flex-direction: row;
            padding: 50px 5px 50px 150px;
        }
        h5 {
            margin: 0;
            color: $white;
            font-family: $font-libre-bold;
            font-size: 1.4rem;
            line-height: 2.2rem;
            @include mq($screen-m) {
                flex-direction: row;
                font-size: 1.8rem;
                line-height: 2.2rem;
            }
        }
    }
    &__icon {
        position: absolute;
        left: -1px;
        top: 50%;
        width: auto;
        height: auto;
        border-radius: 50%;
        display: inline-block;
        overflow: hidden;
        transform: translate(0, -50%);
        background: $white;
        @include mq($screen-m) {
            flex-direction: row;
            max-width: 100%;
        }
        img {
            width: 70px;
            height: 70px;
            border: 3px solid #fff;
            border-radius: 50%;
            @include mq($screen-m) {
                width: 146px;
                height: 146px;
                border: 5px solid #fff;
            }
        }
    }
}