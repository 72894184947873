.side-menu__container,
.mobile-menu__list,
.side-menu__close-button,
.mobile-menu {
    background: transparent;
}

.side-menu__modal {
    background: $primary;

    .modal__container.side-menu__container {
        margin-top: 70px;
    }

    .menu-icon-wrapper {
        position: absolute;
        right: 15px;
        width: 30px;
        height: 30px;
        fill: #fff;
        z-index: 9999;
    }
    li {
        display: flex;
        padding: 1rem;

        .mobile-menu__label {
            display: flex;
            width: 90%;
        }
        a {
            @include menu-typography();
            font-size: 2rem;
        }
    }
    .mobile-menu__item.mobile-menu__item--all {
        display: none;
    }
    .side-menu__close-button {
        left: 5%;
        width: 90%;
        position: absolute;

        .modal__close-button-icon {
            right: 10px;
            fill: $white;
            position: absolute;
        }
        .side-menu__close-button-text {
            @include menu-typography();
            right: 40px;
            font-family: $font-open-sans;
            position: static;
            margin-right: auto;
            margin-left: 0;
        }
    }

    .mobile-menu__item--collapse {
        display: flex;
        flex-direction: column;

        .mobile-menu__content {
            opacity: 1;
            height: 100%;
            visibility: visible;
            transition: all 0.1s ease-in-out;
            background-color: $primary-hover;
        }
        .mobile-menu--inner {
            padding-left: 10px;
        }
        &.hidden-submenu {
            .mobile-menu__content {
                height: 0;
                opacity: 0;
                visibility: hidden;
            }
        }
    }
    .mobile-menu__label {
        padding-bottom: 1rem;

        &:hover {
            color: $white;
        }
    }
}

.language-switcher-mob.dropdown-list--detailed-content {
    .dropdown-list__list {
        width: 100%;
        display: block;
    }
    .dropdown-list__item {
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
    }
    .dropdown-list__label.header-switcher__button {
        font-size: 1.8rem;
        width: 100%;
        height: auto;
        background: none;
        color: $white;
        font-family: $font-open-sans;
        font-weight: 700;
        padding: 1rem;
        opacity: 1!important;
        border: 0.5px solid $white;
        text-align: center;
        margin-top: 3rem;
        -webkit-tap-highlight-color: transparent;
        span {
            position: relative;
            &::before {
                content: url('../images/icons/historia@3x.svg');
                display: inline-block;
                vertical-align: text-top;
                margin-right: 15px;
                filter: brightness(0) invert(1);
            }
        }
    }
    .header-switcher__location {
        width: 100%;
        top: 103%;
    }
    .header-switcher__locations a {
        color: $primary;
        font-size: 1.6rem;
        line-height: 3rem;
    }
}
.side-menu__content-bottom {
    padding: 0;
    margin: 0!important;
}
.side-menu__content {
    // overflow-y: visible;
    margin: 0!important;
    height: 100%;
}
.side-menu__content-middle {
    margin: 0!important;

}