.newsletter {
    width: 100%;
    padding: $padding-l;
    background: $primary;

    &__heading {
        @include flex(row, flex-start);

        * {
            color: $white;
        }
        &-title {

            h4 {
                margin: 0;
            }
            p {
                font-size: 1.2rem;
            }
        }
        figure {
            width: 50px;
            height: auto;
            margin-right: 20px;
        }
    }
    &__controls {
        @include flex(column, flex-start);
        margin: 0;
    }
    &__form {
        margin-top: auto;
        margin-bottom: auto;
    }
}

@media screen and (min-width: $screen-s) {
    .newsletter {
        &__heading {
            justify-content: center;
        }
        &__form {
            margin: auto;
        }
        &__controls {
            max-width: 420px;
            margin-top: 20px;
            flex-direction: row;
        }
    }
}

@media screen and (min-width: $screen-l) {
    .newsletter {
        &__heading {
            justify-content: flex-start;
        }
        &__form {
            @include flex(row, flex-end);
            max-width: auto;
            .his-input {
                width: 350px;
            }
        }
        &__controls {
            margin: 0;
            max-width: 550px;
        }
    }
}
