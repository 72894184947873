.contact-index-index {
    .heading--page {
        font-family: $font-libre-bold;
        font-size: $font-size-xxlg;
        line-height: $line-height-xxl;
        text-transform: none;
        color: $primary;
        margin: 0;
        text-align: center;
        padding: 0 0 $padding-medium 0;
    }
    .captcha-box {
        align-items: flex-start;
    }
    textarea,
    input {
        font-size: 1.4rem;
    }
    .his-btn-primary::after {
        display: none;
    }
}
.cms-contacts,
.contact {
    .breadcrumbs.container {
        padding: $padding-xxlarge 0 0 0;
    }
    &__heading {
        font-family: $font-libre-bold;
        font-size: $font-size-xxlg;
        line-height: $line-height-xxl;
        text-transform: none;
        color: $primary;
        margin: 0;
        padding: $padding-xxlarge $padding-large;
        @include mq($screen-m) {
            padding: $padding-medium $padding-large $padding-xxlarge $padding-large;
        }
    }
    &__container {
        @include flex(row, space-between);
        flex-wrap: wrap;
        width: $full-width;
        padding: $padding-lg;
        &--left {
            width: $full-width;
            margin: 0 0 $margin-xl 0;
            @include mq($screen-m) {
                width: 30%;
            }
            a {
                font-weight: 700;
                color: $secondary;
                text-decoration: none;
        
                &:hover {
                    color: $secondary-hover;
                }
            }
        }
        &--right {
            width: $full-width;
            @include mq($screen-m) {
                width: 68%;
                max-width: 87.2rem;
            }
        }
        &--business {
            .cms-contacts__container--left {
                h3 {
                    margin: $margin-s 0;
                }
                @include mq($screen-m) {
                    width: $full-width;
                }
                @include mq($screen-xl) {
                    width: 48%;
                }
            }
            .cms-contacts__container--right {
                @include mq($screen-m) {
                    width: $full-width;
                }
                @include mq($screen-xl) {
                    width: 48%;
                }
            }
            .partner-program-form {
                @include mq($screen-m) {
                    padding: $padding-l;
                }
                .field {
                    margin-bottom: $margin-root;
                }
                .action.submit.primary {
                    width: auto;
                    @include mq($screen-m) {
                        margin: $margin-root 0 0 18.2rem;
                    }
                }
            }
        }
    }
    .partner-program-form {
        @include mq($screen-m) {
            padding: $padding-l;
        }
        @include mq($screen-l) {
            padding: $padding-l $padding-15 $padding-l $padding-l;
        }
        @include mq($screen-xl) {
            padding: $padding-xxlarge $padding-15 $padding-xxlarge $padding-xxlarge;
        }
        .primary {
            align-items: baseline;
        }
        .action.submit.primary {
            width: auto;
            @include mq($screen-m) {
                margin: $margin-root 0 0 $margin-17;
            }
        }
    }
    .label {
        max-width: $width-17;
    }
    .cms-contacts__container {
        .his-input {
            margin: 0;
            padding: $padding-s;
        }
    }
    .control {
        .mage-error {
            margin: 0;
        }
    }

}
