.cms-newsletter_subscription {
    #maincontent {
        width: $full-width;
        max-width: $page-wrapper-container-width;
        margin: 0 auto;
        background: $white;
        padding: $padding-lg;
        @include mq($screen-xxl) {
            margin: 0 auto 22rem auto;
        }
    }
    .cms-page__content {
        padding: 0;
        .heading.heading--page {
            display: none;
        }
    }
    .block.newsletter {
        background: $white;
        padding: $padding-md 0;
        @include mq($screen-m) {
            padding: 0;
            margin: $margin-xlarge 0 0 0;
        }
        .title {
            display: none;
        }
        .content {
            @include mq($screen-m) {
                width: $full-width;
            }
        }
        .form.subscribe {
            @include mq($screen-m) {
                width: $full-width;
            }
        }
        .control {
            width: $full-width;
            margin: $margin-root 0;
            @include mq($screen-l) {
                width: $width-31;
            }
            @include mq($screen-xl) {
                width: $full-width;
            }
        }
        input {
            width: $full-width;
            font-family: $font-open-sans-bold;
            font-size: 1.2rem;
            padding: $padding-sn $padding-s;
            border: $border-gold-bold;
            &[data-validate="{required:true}"] {
                &::after {
                    content: "\0359";
                    color: $primary;
                }
            }
        }
        .select2-container {
            @include mq($screen-l) {
                width: $width-31;
            }
            .select2-selection--single {
                border: $border-gold-bold;
                .select2-selection__arrow {
                    b {
                        border-color: $primary;
                    }
                }
            }
        }
        .field {
            &.required {
                label {
                    &:not(.checkbox__label) {
                        &:after {
                            content: '**';
                        }
                    }
                }
            }
            @include mq($screen-m) {
                width: $full-width;
            }
            @include mq($screen-l) {
                @include flex(row, flex-start);
                align-items: center;
                .label {
                    width: $width-17;
                }
            }
        }
        .field.newsletter {
            background: $white;
            padding: 0;
            @include mq($screen-m) {
                flex-direction: column;
            }
            @include mq($screen-l) {
                flex-direction: row;
            }
        }
        .field.checkbox {
            width: $full-width;
            @include flex(row, flex-start);
            margin: $margin-m 0 $margin-s 0;
            @include mq($screen-l) {
                width: $width-28;
                align-items: flex-start;
                margin: $margin-m 0 0 15.3rem;
            }
            @include mq($screen-xl) {
                width: $width-50;
                margin: $margin-m 0 $margin-s 13.5rem;
            }
            input {
                position: relative;
                appearance: none;
                width: $width-2;
                height: $width-2;
                padding: 0;
                margin: 0.5rem $margin-root 0 0;
                &:checked {
                    & + .label::before {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: $secondary
                        url("../images/icons/check-white.svg") no-repeat center;
                        background-size: 70%;
                        width: $width-2;
                        height: $width-2;
                        margin: 0.4rem 0 0 0;
                    }
                }
            }
            .label {
                @include t3;
                width: 90%;
                margin: 0;
            }
        }
        .input.gender {
            margin: 0;
            @include mq($screen-l) {
                @include flex(row, flex-start);
                align-items: center;
            }
            .label {
                @include mq($screen-l) {
                    width: $width-17;
                }
            }
            .control {
                @include mq($screen-l) {
                    min-width: $width-28;
                    width: $width-28;
                    margin: $margin-root 0 $margin-root 8.7rem;
                }
                @include mq($screen-xl) {
                    width: $full-width;
                    margin: $margin-root 0;
                }
            }
            select.select__field {
                @include mq($screen-l) {
                    min-width: $width-28;
                }
            }
            .select2-container {
                @include mq($screen-l) {
                    min-width: $width-28;
                }
                @include mq($screen-xl) {
                    width: $full-width;
                }
            }
        }
        .actions {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .required {
                margin-top: 16px;
                @include mq($screen-m) {
                    margin: $margin-xlarge 0 0 0;
                }
            }
            .action.subscribe.primary {
                @include mq($screen-m) {
                    margin: $margin-xlarge 0 0 0;
                }
                @include mq($screen-l) {
                    min-width: $width-28;
                    //margin: $margin-xlarge 0 0 15.3rem;
                }
                @include mq($screen-xl) {
                    margin: $margin-xlarge 0 0 13.5rem;
                }
            }
        }
    }
}
.newsletter_confirm-newsletter-confirm {
    #maincontent {
        padding: $padding-large;
    }
}
.newsletter-subscription {
    &__container {
        @include flex(row, space-between);
        flex-wrap: wrap;
        @include mq($screen-m) {
            margin: 0 0 $margin-xl 0;
        }
        @include mq($screen-xl) {
            margin: $margin-xlarge 0 $margin-xl 0;
        }
        p {
            @include t3;
            margin: 0 0 $margin-root 0;
            &:last-child {
                margin: 0;
            }
        }
        li {
            font-family: $font-open-sans-bold;
            font-size: $font-size-normal !important;
            line-height: $line-height-large !important;
            list-style-image: url("../images/icons/check.svg");
            margin: 0 0 0 $margin-s;
        }
    }
    &__left {
        width: $full-width;
        @include mq($screen-m) {
            width: 45%;
        }
        @include mq($screen-xl) {
            width: 52%;
            max-width: 69rem;
        }
        h1 {
            margin: 0;
        }
    }
    &__right {
        width: $full-width;
        background: $beige1;
        margin: 0 0 $margin-xlarge 0;
        padding: $padding-large;
        @include mq($screen-m) {
            width: 45%;
            margin: 0;
        }
        @include mq($screen-xl) {
            max-width: 55rem;
        }
    }
}
.newsletter-success {
    margin: 0 0 $margin-xl 0;
    width: $full-width;
    @include mq($screen-m) {
        margin: 0;
        height: $full-width;
    }
    &__container {
        @include flex(column, center);
        align-items: center;
        width: $full-width;
        @include mq($screen-m) {
            height: $full-width;
        }
    }
    &__icon {
        width: $wishlist-icon-size;
        height: $wishlist-icon-size;
        svg {
            width: $full-width;
            height: $full-width;
            object-fit: contain;
        }
    }
    &__button {
        @include btn-primary;
        @include btn-text;
        padding: 0.5rem $padding-medium;
        margin: $margin-mxl 0 0 0;
        &:hover,
        &:focus {
            @include btn-primary-hover;
            color: white;
            text-decoration: none;
        }
    }
    h3 {
        font-size: $font-size-xxxlarge;
        line-height: $line-height-xxl;
        text-align: center;
        margin: $margin-root 0 0 0;
    }
    p {
        font-size: $font-size-normal;
        text-align: center;
        line-height: $line-height-large;
        margin: $margin-small 0 0 0;
    }
}
