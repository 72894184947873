.fotorama {
    &__zoom-in-icon {
        display: none;
    }
    &__stage__frame .fotorama__img {
        max-width: 434px;
    }
    &__nav {
        &__nav__shaft {
            left: 50%;
            transform: translate3d(-50%, 0, 0);
        }
    }
    &__nav__frame {
        &.fotorama__nav__frame--thumb {
            margin: 1.6rem 1.6rem 0 0;
        }
    }
    &__nav__frame--thumb {
        display: block;
    }
    &__thumb {
        & > .fotorama__img {
            border: $border-gold-bold !important;
            max-width: 100%;
        }
    }
    //&__video {
    //    display: none;
    //}
    .fotorama__wrap--video {
        .fotorama__fullscreen-icon {
            opacity: 1!important;
        }
    }
    .fotorama__wrap--css3.fotorama__wrap--video .fotorama__fullscreen-icon {
        transform: none!important;
    }
    .fotorama__thumb-border {
        border: $border-red-bold;
    }
    &.fotorama--fullscreen {
        .fotorama__nav-wrap {
            .fotorama__nav--thumbs {
                width: fit-content !important;
                @include mq($screen-l) {
                    margin: 0 auto;
                }
            }
        }
        .fotorama__fullscreen-icon {
            background-color: $primary;
            width: 5rem;
            height: 5rem;
            @include mq($screen-m) {
                top: 2rem;
                right: 2rem;
            }
            @include mq($screen-l) {
                top: 4rem;
                right: 0;
            }
            .fullscreen-icon__close {
                width: $full-width;
                height: $full-width;
                background-size: 4rem 4rem;
            }
        }
    }
}

@media screen and (max-width: $screen-sm) {

    .fotorama--fullscreen .fotorama__stage__frame iframe {
        width: 90%!important;
        left: 5%!important;
    }

}
