.shipping-tracking-popup {
    #maincontent {
        margin: 0 auto;
        background: $white;
        padding: $padding-large;
    }
    .heading--page {
        color: $primary;
        text-align: left;
    }
    .order.subtitle.caption {
        font-family: $font-libre-bold;
        font-size: $font-size-xxlarge;
        color: $black;
    }
    .dashboard-items__subtitle {
        text-align: left;
        @include mq($screen-s) {
            margin: $margin-xlg 0 0 0;
        }
    }
    .table {
        border: none;
        tr {
            border-bottom: none;
            @include mq($screen-s) {
                @include flex(row, space-between);
            }
        }
        th {
            display: block;
            font-family: $font-open-sans-condensed;
            font-size: $font-size-normal;
            color: $secondary;
            border: none;
            width: $full-width;
            padding: 0;
            @include mq($screen-s) {
                width: 50%;
            }
            &[scope="row"] + td {
                padding: 0;
            }
        }
        td {
            border: none;
            padding: 0;
            @include mq($screen-s) {
                width: 50%;
                text-align: left;
            }
        }
    }
}
