.breadcrumbs__item,
.breadcrumbs__item:last-child:not(:first-child) {
    @include t3;
    color: $primary;
    font-weight: 700;

    &:after {
        content: url("../images/icons/menu-down.svg");
        transform: rotate(-90deg);
        margin: 0 18px;
    }
}

.breadcrumbs {
    margin-bottom: 30px;
}

.cms-page-view {
    .breadcrumbs.container {
        padding: $padding-xxlarge 0 0 0;
    }
}

@media screen and (min-width: $screen-s) {
    .breadcrumbs {
        display: block;
    }
}
