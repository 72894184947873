.advantages {
    background: $beige1;
    padding: 2rem 1.6rem 1.6rem 1.6rem;
    position: relative;

    h2 {
        font-family: $font-libre-bold;
        font-size: $font-size-xlarge;
        color: $primary;
        line-height: 4.5rem;
    }
    &-item {
        position: relative;
        list-style-type: none;
        font-family: $font-open-sans-bold;
        font-size: $font-size-normal;
        line-height: $line-height-large;
        margin: 0 0 0 2.8rem;
        &::before {
            content: '';
            position: absolute;
            width: 2rem;
            height: 2rem;
            background: url('../images/icons/check.svg') no-repeat center center;
            background-size: contain;
            margin: 0 0 0 -2.8rem;
        }
    }

    &-badge {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    p {
        font-size: $font-size-normal;
        font-weight: $font-weight-bold;
    }
}
