.customer-account-index,
.customer-address-index,
.customer-address-form,
.wishlist-index-index,
.newsletter-manage-index,
.review-customer-index,
.vault-cards-listaction,
.sales-order-history {
    #maincontent {
        margin: 0 auto;
        padding: 0 $padding-normal $padding-xxlarge $padding-normal;
        background: $white;
    }
}
.dashboard {
    .row {
        justify-content: flex-start;
        @include mq($screen-xl) {
            max-width: $container-desktop-width;
            padding: 0;
            margin: 0 auto;
        }
    }
    &__sidebar {
        width: $full-width;
        @include mq($screen-l) {
            width: 31.8rem;
            border: none;
        }
        @include mq($screen-xl) {
            padding: 0;
            margin: 3rem 0 0 0;
        }
    }
    &__content {
        position: relative;
        width: $full-width;
        @include mq($screen-m) {
            padding: 0 0 5rem 0;
        }
        @include mq($screen-l) {
            width: 65%;
            margin: 9.3rem 0 0 0;
        }
        @include mq($screen-xl) {
            width: 68%;
            margin: 12rem 0 0 2rem;
        }
        @include mq($screen-xlg) {
            width: 73%;
        }
    }
    &__content-heading {
        &.heading--page {
            font-family: $font-libre-bold;
            font-weight: $font-weight-bold;
            font-size: $font-size-xxlg;
            line-height: $line-height-xxl;
            text-transform: none;
            margin: 0;
            color: $black;
            text-align: left;
        }

    }
    &__content-welcome {
        width: $full-width;
    }
    &__content-message {
        p {
            font-family: $font-open-sans;
            font-size: $font-size-lg;
            line-height: $line-height-large;
        }
    }
    &-items__addresses {
        position: relative;
        margin: $margin-xlg 0 0 0;
        .dashboard-items__subtitle {
            max-width: 50%;
            @include mq($screen-l) {
                height: auto;
            }
            &-small {
                max-width: $full-width;
            }
        }
        .dashboard-items__content {
            border-top: $border-beige;
        }
    }
    &-items__orders {
        position: relative;
        margin: $margin-xlg 0 0 0;
        .dashboard-items__subtitle {
            padding: 0 0 $padding-medium 0;
            margin: 0;
        }
    }
    &-items__content {
        width: $full-width;
        @include flex(row, space-between);
        flex-wrap: wrap;
        padding: 0 0 5rem 0;
        @include mq($screen-m) {
            padding: 0;
        }
    }
    &-items__item {
        position: relative;
        width: $full-width;
        margin: $margin-xlg 0 0 0;
        padding: 0;
        @include mq($screen-m) {
            width: 48%;
        }
        .actions-group__handler {
            margin: 0;
        }
        .delete {
            color: $primary;
            text-decoration: none;
            &:hover,
            &:focus {
                color: $primary-hover;
            }
        }
    }
    &-items__subtitle {
        font-family: $font-open-sans-bold;
        font-weight: $font-weight-bold;
        font-size: $font-size-xlg;
        line-height: $line-height-extra-large;
        color: black;
        text-transform: none;
        margin: 0 0 $margin-m 0;
        padding: 0 10rem $padding-md 0;
        border-bottom: solid 1px $beige2;
        @include mq($screen-l) {
            height: 6rem;
        }
        &-address {
            max-width: 50%;
            margin: 0;
            border-bottom: none;
            padding: 0 0 3rem 0;
        }
        &-small {
            font-family: $font-open-sans-bold;
            font-size: $font-size-lg;
            color: black;
            line-height: $line-height-large;
            margin: $margin-normal 0;
            padding: 0;
            border-bottom: none;
            @include mq($screen-l) {
                height: auto;
            }
        }
    }
    &-items__details {
        font-size: $font-size-lg;
        .actions-group__handler {
            margin: 0;
        }
        .actions-group__link {
            padding: $padding-normal 0 0 0;
        }
    }
    &-items__address {
        font-size: $font-size-lg;
        address {
            font-style: normal;
        }
    }
    &-items__info {
        @include flex(row, space-between);
        flex-wrap: wrap;
    }
}
.dashboard-nav {
    &__logout-button {
        @include btn-primary;
        color: $white;
        border: none;
        margin: $margin-xlarge 0 0 0;
        &:hover,
        &:focus {
            @include btn-primary-hover;
        }
        &--mobile {
            margin: -3rem 0 0 0;
        }
    }
    &__mobile {
        font-family: $font-libre-bold;
        font-size: $font-size-xxlg;
        line-height: $line-height-xxl;
        color: $primary;
        border: none;
        @include mq($screen-l) {
            display: block;
            &::after {
                display: none;
            }
        }
        &::after {
            top: 42%;
            right: 0;
            border-color: transparent $secondary $secondary transparent;
        }
    }
    &__content {
        background: $beige1;
        .list {
            padding: $padding-small 0;
        }
    }
    &__section {
        margin: $margin-xl 0;
    }
    .nav.item {
        position: relative;
        margin: 0 0 0 $margin-normal;
        padding: 1.2rem $padding-normal;
        &:not(.current) > a {
            padding: 0;
        }
        &> a {
            font-family: $font-open-sans-bold;
            font-size: $font-size-lg;
            line-height: $line-height-large;
            color: $primary;
            &::after {
                display: none;
            }
            &:hover,
            &:focus {
                color: $primary-hover-darker;
                background-color: transparent;
            }
        }
        &::before {
            content: '';
            position: absolute;
            width: 0.6rem;
            height: 0.6rem;
            margin: 0.8rem 0 0 -1.6rem;
            border-color: $secondary;
            border-style: solid;
            border-width: 0 1px 1px 0;
            transform: rotate(-45deg);
        }
        &::after {
            display: none;
        }
        &.nav-border {
            height: 1px;
            padding: 0;
            border-bottom: solid 1px $beige2;
            margin: $margin-sm $margin-normal;
            &::before {
                display: none;
            }
        }
        &:last-child {
            padding: 1.2rem $padding-normal!important;
        }
    }

    .current {
        background: transparent;
        padding: $padding-sm $padding-normal;
        &:hover {
            background: transparent;
        }
        strong {
            font-family: $font-open-sans-bold;
            font-size: $font-size-lg;
            font-weight: $font-weight-bold;
            color: black;
        }
    }
}
.dashboard__link {
    font-family: $font-open-sans;
    font-size: $font-size-lg;
    color: $primary;
    text-decoration: none;
    @include mq($screen-l) {
        padding: $padding-normal 0 0 0;
    }
    &:hover {
        color: $primary-hover-darker;
        text-decoration: none;
    }
    &-edit {
        @include btn-secondary;
        position: absolute;
        top: 0;
        right: 0;
        width: 8.8rem;
        height: 4rem;
        margin: 0;
        font-family: $font-open-sans-condensed;
        font-size: $font-size-md;
        font-weight: $font-weight-bold;
        color: white;
        text-decoration: none;
        @include centerItem;
        @include mq($screen-l) {
            padding: 0;
        }
        &:hover {
            @include btn-secondary-hover;
            color: $white;
        }
    }
    &-address {
        width: fit-content;
        padding: 0 2rem;
    }
}
.customer-address-index {
    .dashboard__content-heading {
        padding: 0 17rem 0 0;
    }
    .dashboard-items {
        margin: 4rem 0 0 0;
        &:last-child {
            margin: 0;
            @include mq($screen-m) {
                margin: 4rem 0 0 0;
            }
        }
        &__subtitle {
            margin: 0;
            padding: 0 10rem 1rem 0;
            @include mq($screen-l) {
                height: auto;
            }
        }
        &__subtitle-small {
            @include mq($screen-l) {
                padding: 0 0 1rem 0;
            }
        }
        &__no-address {
            color: $gray2;
            padding: 2rem 0;
        }
    }
    .dashboard-items__item {
        &-additional {
            margin: 0;
        }
    }
}
.customer-account-index {
    .dashboard-items__table {
        .table {
            tr {
                border-bottom: $border-gold;
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}
.wishlist-list__item {
    .price__value--special {
        color: $black;
    }
}
