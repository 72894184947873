.review {
    @include flex(row, space-between);
    flex-wrap: wrap;
    margin: $margin-xlg 0;
    .dashboard-items__subtitle {
        width: $full-width;
        color: $primary;
        padding: 1rem 0;
        &:hover,
        &:focus {
            color: $primary-hover-darker;
            text-decoration: none;
        }
    }
    .ratio-container {
        height: auto;
        .ratio-image {
            position: relative;
            top: 0;
            left: 0;
            transform: translate(0, 0);
        }
    }
    &__subtitle {
        display: block;
        width: $full-width;
    }
    &__left {
        width: $full-width;
        @include mq($screen-m) {
            width: 45%;
        }
    }
    &__right {
        width: $full-width;
        @include mq($screen-m) {
            width: 50%;
        }
    }
    &__details {
        @include flex(column, flex-start);
        flex-wrap: wrap;
        width: $full-width;
        max-width: $full-width;
        flex-basis: $full-width;
        background: $beige1;
        padding: 2rem;
        margin: 4.5rem 0 0 0;
    }
    &__rating {
        margin: $margin-s 0 0 $margin-s;
        &-container {
            @include flex(row, flex-start);
            flex-wrap: wrap;
            margin: $margin-s 0 0 0;
            @include mq($screen-m) {
                margin: 0;
            }
        }
    }
    &__title {
        margin: 0;
    }
    &__text {
        font-size: $font-size-large;
        margin: $margin-xlg 0;
    }
    &__back {
        @include btn-secondary;
        font-family: $font-open-sans-condensed;
        font-weight: $font-weight-bold;
        font-size: $font-size-normal;
        text-transform: uppercase;
        color: $white;
        padding: 1rem 2rem;
        margin: $margin-xlg 0 0 0;
        @include mq($screen-m) {
            margin: $margin-s 0 0 0;
        }
        &:hover,
        &:focus {
            @include btn-secondary-hover;
            color: $white;
            text-decoration: none;
        }
    }
    .rating__label {
        font-family: $font-open-sans-condensed;
        font-size: $font-size-normal;
        font-weight: $font-weight-bold;
        line-height: $line-height-small;
        color: $black;
    }
    .rating__star {
        &::before {
            background-image: url("../images/icons/star.svg");
        }
    }
    .rating__indicator {
        &::before {
            background-image: url("../images/icons/star-red.svg");
        }
    }
}
.review-customer-view {
    #maincontent {
        margin: 0 auto;
        background: $white;
        padding: 0 1rem;
        @include mq($screen-xlg) {
            padding: 0;
        }
    }
    .row {
        width: $full-width;
        margin: 0 auto;
        @include mq($screen-xl) {
            padding: 0 1rem;
        }
        @include mq($screen-xlg) {
            padding: 0;
        }
    }
    .dashboard__content {
        padding: 0;
        @include mq($screen-l) {
            width: 68%;
        }
        @include mq($screen-xl) {
            width: 72%;
        }
        @include mq($screen-xlg) {
            width: 75%;
        }
    }
}
.review-customer-index {
    .dashboard__content {
        @include mq($screen-xl) {
            width: 70%;
        }
        @include mq($screen-xlg) {
            width: 75%;
        }
    }
}
