.related,
.crosssell,
.upsell,
.wishlist {
    margin: 0 0 2rem 0;
    padding: 0 2rem;
    h2 {
        font-family: $font-libre-bold;
        font-size: $font-size-xxlarge;
        color: $primary;
        text-align: center;
    }
    .section__content--products {
        margin: 0;
    }
    .swiper-container {
        padding: 0 0 9rem 0;
    }
    .product-grid-item {
        border: $border-gold-bold;
        width: 31.8rem !important;
        min-height: 44.5rem;
        padding: 0;
        margin: 0 2rem 0 0;
        transition: $transition;
        box-shadow: 0 0 1rem $secondary;
        &:first-child {
            margin: 0 2rem 0 0;
            @include mq($screen-m) {
                margin: 0 2rem;
            }
        }
        @include mq($screen-xlg) {
            margin: 0 3rem 0 0;
        }
        &:hover,
        &:focus {
            box-shadow: $box-shadow-gold-hover;
            .product-grid-item__actions {
                transform: translateY(0);
                opacity: 1;
                box-shadow: $box-shadow-gold-hover;
            }
        }
        &:hover .product-grid-item__image-wrapper:after {
            background-color: transparent;
        }
        &__price {
            .price__value {
                .price-from-label {
                    @extend .price__text;
                }
            }
            .price__value:not(.price__value--between) {
                display: inline;
            }
        }
        .personalize {
            @include btn-secondary;
            width: 13.5rem;
            height: 4.2rem;
            font-family: $font-open-sans-condensed;
            font-size: $font-size-normal;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            color: white;
            @include centerItem;
            &:hover {
                @include btn-secondary-hover;
            }
        }
        &:not(:root:root) {
            display: flex;
            flex-direction: column;
        }
        &__rating {
            display: none;
        }
        &__name {
            height: 7.2rem;
            a {
                font-family: $font-open-sans-bold;
                font-size: $font-size-xlarge;
                text-align: center;
                line-height: 2.6rem;
                padding: 0 1rem;
                margin: 2rem 0 0 0;
                @include mq($screen-xlg) {
                    margin: 1rem 0 0 0;
                }
            }
        }
        &__details {
            margin: 0;
            padding: 0;
        }
        &__actions {
            position: absolute;
            top: 100%;
            left: -0.2rem;
            margin: 0;
            transition: all 0.6s ease;
            padding: 0 1.6rem 1.6rem 1.6rem;
            width: 31.8rem;
            height: 6rem;
            border: $border-gold-bold;
            border-top: none;
            transform: translateY(-6rem);
            opacity: 0;
            background: $white;
        }
        &__primary-action {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin: 0;
            form {
                width: 13.5rem;
                .tocart {
                    width: $full-width;
                    height: 4.2rem;
                    min-height: 4.2rem;
                    @include btn-primary;
                    font-family: $font-open-sans-condensed;
                    font-size: $font-size-normal;
                    font-weight: $font-weight-bold;
                    text-transform: uppercase;
                    color: white;
                    margin: 0;
                    padding: 0;
                    @include centerItem;
                    &::after {
                        content: '';
                    }
                }
            }
        }
        &__secondary-action {
            display: none;
        }
        &__price {
            flex-flow: row nowrap;
            justify-content: flex-end;
            font-family: $font-open-sans-condensed;
            font-weight: $font-weight-bold;
            padding: 0 1.6rem 0 0;
            color: $primary;
            @include mq($screen-m) {
                width: 100%;
            }
            .price-container.price-final_price {
                font-size: 2.5rem;
                [data-price-type="oldPrice"] {
                    font-size: $font-size-lg;
                }
            }
            .price__value--special {
                color: $primary;
            }
        }
        .image {
            height: 100%;
        }
    }
    .ratio-container {
        min-width: $full-width;
        min-height: 31.8rem;
        max-height: 31.8rem;
        @include mq($screen-xlg) {
            height: 31.8rem;
        }
        img {
            height: auto;
            object-fit: cover;
            margin: 2rem 0 0 0;
            @include mq($screen-xlg) {
                margin: 0;
            }
        }
    }
    .add-to-wishlist {
        display: none;
    }
}

.related,
.upsell,
.crosssell {
    margin: $margin-xlarge 0 $margin-s 0;
}
