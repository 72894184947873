.giftsadviser-page-index {
    #maincontent { // sass-lint:disable-line no-ids
        @include mq($screen-xl) {
            margin: 25rem auto 0 auto;
        }
    }
    .breadcrumbs.container {
        padding: $padding-large 0 5.3rem $padding-large;
    }
}
.gifts-adviser {
    li {
        font-size: 13px;
    }

    &--block {
        display: block;
    }

    &--content {
        @include flex(row, flex-start);
        flex-wrap: wrap;
        padding: 0 3rem;
        color: #4F4F4F;
        line-height: 1.5;
        font-size: 13px;
        .title {
            width: $full-width;
            margin: 0 0 $margin-md 0;
            font-size: 24px;
            font-weight: $font-weight-normal;
            color: #4F4F4F;
            font-family: $font-family-base !important;
        }
        ul {
            width: 100%;
        }
        h2 {
            color: #4F4F4F;
            font-family: $font-family-base !important;
            font-weight: $font-weight-bold;
            width: 100%;
            font-size: 22px;
        }
        p {
            width: 100%;
            color: #4F4F4F !important;
            margin: 0 0 $margin-md 0;
            line-height: 2.1rem;
            font-size: 1.4rem;
        }
        ul {
            padding: 0;
            list-style: disc outside;
            margin-bottom: 1.5em;
            li {
                margin-left: 2em;
            }
        }
        ol {
            padding: 0;
            list-style: decimal outside;
            margin-bottom: 1.5em;
            li {
                margin-left: 2em;
            }
        }

        a {
            color: $secondary !important;
            font-weight: bold;
        }

        .box__item {
            align-items: flex-start;
            .box__title {
                margin: 0 0 30px;
                font-size: 18px;
                text-align: left;
                line-height: 1.5;
                padding: 0;
                font-family: $font-family-base !important;
                font-weight: $font-weight-bold;
            }

            .box__image {
                margin-bottom: 20px;
            }
        }
        .small-grid-cell {
            float: left;
            width: 30%;
            padding-right: 15px;

            @include mq($screen-m) {
                width: 15%;
            }

            &__img {
                max-width: 100%;
            }

            &__heading {
                font-size: 13px;
                text-align: center;
                font-weight: $font-weight_bold;
            }
        }
        .xlanding-link-inline, .block-product-link-inline {
            font-weight: $font-weight_bold;

            span {
                font-weight: $font-weight_bold;
            }
        }
        table {
            width: 100%;

            img {
                width: 100%;
            }
        }
        > div[data-content-type="html"] {
            @include flex(row, flex-start);
            flex-wrap: wrap;
            color: #4F4F4F;
            line-height: 1.5;
            font-size: 13px;
        }
    }
    &__image {
        width: $full-width;
        @include mq($screen-xl) {
            position: absolute;
            z-index: -1;
            margin: $margin-md 0 0 0;
        }
        img {
            width: $full-width;
            height: 50%;
            max-height: 51rem;
            object-fit: cover;
        }
    }
    &__frame {
        position: relative;
        width: $full-width;
        background: $secondary;
        padding: $padding-lg;
        &::after {
            content: '\275e';
            position: absolute;
            display: none;
            right: 0;
            bottom: 0;
            font-size: 50rem;
            line-height: 0;
            color: #ccaa87;
            z-index: 0;
            @include mq($screen-m) {
                display: block;
            }
            @include mq($screen-xl) {
                font-size: 60rem;
            }
        }
        h3 {
            position: relative;
            font-family: $font-libre-bold;
            font-size: $font-size-xlg;
            line-height: $line-height-xl;
            text-align: center;
            color: $white;
            margin: $margin-s 0 $margin-small 0;
            z-index: 1;
        }
        p {
            @include t2;
            position: relative;
            text-align: center;
            margin: $margin-xlarge 0 0 0;
            z-index: 1;
            font-size: 1.4rem;
            margin: 0 0 2rem 0;
            line-height: 2.1rem;
            color: $black;
        }
        a {
            @include btn-primary;
            @include btn-text;
            position: relative;
            display: block;
            max-width: fit-content;
            z-index: 1;
            margin: $margin-xlarge auto;
            padding: $padding-xs $padding-medium;
            &:hover,
            &:focus {
                @include btn-primary-hover;
            }
        }
    }
    .gift-matcher {
        margin: $margin-xlarge 0 0 0;
    }
}
.singlebox {
    width: $full-width;
    margin: 0 0 $margin-normal 0;
    @include mq($screen-m) {
        width: calc(50% - 4.3rem);
        margin: 0 $margin-sm $margin-s $margin-xs;
        &:nth-child(2n) {
            margin: 0 $margin-xs $margin-s $margin-sm;
        }
    }
}
.box {
    &__item {
        @include flex(column, flex-start);
        align-items: center;
        width: $full-width;
        transition: $transition;
        &:hover,
        &:focus {
            text-decoration: none;
            h4 {
                color: $primary-hover-darker;
            }
        }
    }
    &__header {
        @include heading($black);
        width: $full-width;
        margin: 0;
        font-size: 22px;
        color: $primary;
        font-family: $font-open-sans !important;
    }
    &__image {
        width: $full-width;
    }
    &__title {
        @include heading2($primary);
        line-height: $line-height-large;
        padding: 0 $padding-lg;
        text-align: center;
        @include mq($screen-xl) {
            @include heading($primary);
        }
    }
}
.multibox {
    @include flex(row, flex-start);
    flex-wrap: wrap;
    padding: 0 $padding-lg;
    .box__item {
        @include mq($screen-m) {
            width: 48%;
            margin: 0 0 $margin-s 0;
            padding-right: 20px;
        }
        @include mq($screen-xl) {
            width: 32%;
        }
        &:nth-child(2n) {
            @include mq($screen-m) {
                margin: 0 0 $margin-s 0;
            }
        }
    }
}
