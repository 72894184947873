.age-modal-ctn {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    display: none;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);

    .age-modal {
        width: 100%;
        height: 100%;
        margin-left: 50%;
        background-color: #fff;
        transform: translateX(-50%);
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);

        * {
            margin: 0;
            padding: 15px;
            font-family: $font-open-sans;
        }

        h4 {
            margin: 0;
            font-size: 1.8rem;
            line-height: 1.4;
            font-weight: 400;
            text-transform: none;
        }

        .verification-information {
            font-size: 1.3rem;
        }

        &-footer {
            display: flex;
            justify-content: flex-start;

            button {
                border: 0;
                width: 30%;
                color: #fff;
                cursor: pointer;
                font-weight: 700;
                line-height: 1.6;
                font-size: 1.4rem;
                border-radius: 2px;
                padding: 7px 20px 9px;
                background-color: $primary;
                text-transform: none;

                &.no {
                    margin-left: 5px;
                }

                &:hover {
                    background-color: $primary-hover;
                }
            }
        }
    }
}

@media screen and (min-width: $screen-s) {
    .age-modal-ctn {
        .age-modal {
            width: 70%;
            height: auto;
            max-width: 600px;
            margin-top: 30px;

            button {
                width: 25%;  
            }
        }
    }
}

@media screen and (min-width: $screen-l) {
    .age-modal-ctn {
        .age-modal {
            width: 50%;
        }
    }
}
