//CLS fixes for PDP
.gallery.config-swatched{
    .fotorama__stage{
        position: static !important;
    }
    .gallery-placeholder__image{
        display: none !important;
    }
}
.fotorama--fullscreen .fotorama__stage{
    position: static !important;
}
.gallery-placeholder__image {
    height: auto;
    max-width: 100%;
    margin: 2.5rem auto 0 auto;
}
//=================
html:not(.fotorama__fullscreen) .fotorama-item.fotorama{
    position: static !important;
}
.fotorama__fullscreen {
    .gallery-placeholder {
        &::after {
            display: none!important;
        }
    }
}
.fotorama__loaded--img.fotorama__active {
    background: #fff;
}
.fotorama--fullscreen {
    .fotorama__loaded--img.fotorama__active {
        background: none;
    }
}
.catalog-product-view {
    #maincontent {
        max-width: 1378px;
    }
    .body-overlay {
        display: none;
    }
    .breadcrumbs.container {
        padding: 1rem 1.6rem;
        @include mq($screen-l) {
            padding: 3rem 0 3rem 3.2rem;
        }
    }
    .breadcrumbs__item, .breadcrumbs__item:last-child:not(:first-child) {
        color: #1a1b1d;
        font-size: 1.4rem;
        line-height: 2.2rem;
        font-weight: 400;
    }
    .breadcrumbs__item::after {
        color: #1a1b1d;
    }
    .product-view__title.heading--page {
        font-size: 2rem;
        line-height: 3.2rem;
        font-weight: 700;
        @include mq($screen-m) {
            font-size: 3.2rem;
            line-height: 4.2rem;
            margin-bottom: 1rem;
        }
    }
    .product-star-widget {
        .star-rating {
            margin-right: 0.5rem;
            img {
                width: 1.6rem;
                height: 1.6rem;
            }
            .grade,
            .count {
                font-family: $font-open-sans;
                font-size: 1.4rem;
                margin: 0;
            }
            .count {
                color: #70787A;
            }
        }
    }
    .page-main {
        background: transparent;
    }
    // sass-lint:disable no-ids
    ._owyw4l, ._yzlvut { //trustbadge
        bottom: 150px !important;
        @include mq($screen-m) {
            bottom: 30px !important;
        }
    }
    ._owyw4l {
        @include mq($screen-m) {
            bottom: 20px !important;
        }
    }
    .umm-1tlp7ro { //userlike
        bottom: 150px;
        right: 120px;
        z-index: 5;
        @include mq($screen-m) {
            bottom: 30px;
            right: 130px;
        }
    }
    .umm-1alicbp {
        bottom: 130px;
        right: 10px;
    }
    .usercentrics-button.uc-button-bl .uc-privacy-button-wrapper {
        bottom: 150px !important;
        @include mq($screen-m) {
            bottom: 30px !important;
        }
    }
    ._1gwv20v { //usercentrics
        position: fixed !important;
        right: 20px !important;
        bottom: 150px !important;
        ._yzlvut {
            position: initial !important;
        }
    }
    #trustbadge-container-98e3dadd90eb493088abdc5597a70810 {
        z-index: 10 !important;
    }
    .usercentrics-button {
        z-index: 10 !important;
    }
    .add-to-cart {
        z-index: 9;
    }
    .product-custom-option-image {
        margin: 10px;
        max-width: 40%;
    }
    .gallery-placeholder {
        margin-top: 1.6rem;
        position: relative;
        z-index: 1;
        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 150px;
            height: 150px;
            transform: translate(-50%,-50%);
            z-index: -1;
            background: url(../images/loading.webp) center no-repeat;
            background-size: contain;
        }
    }
    picture.gallery-placeholder__image{
        @include mq(false,$screen-m) {
            img {
                width: 100%;
                aspect-ratio: 1 / 1;
            }
        }
    }
}

body.catalog-product-view {
    div.product-view, .product-view {
        background: #fff;
        .gallery {
            @include mq($screen-xl) {
                height: 593px;
                margin-bottom: 3rem;
            }
        }
        .bundle-option__qty {
            display: none;
        }

        &__title-after{
            display: grid;
            @include mq($screen-m) {
                margin-bottom: 1rem;
                &:has(+ .datefinder){
                    margin-bottom: 0;
                }
            }
        }
        &__customization {
            padding: 0;
            border: none!important;
        }
        .product-view__details {
            padding-right: 0;
            @include mq($screen-xl) {
                height: 593px;
                margin-bottom: 3rem;
                padding-right: 0.5rem;
                overflow-y: auto;
            }
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: none;
                background-color: #ccc;
                border-radius: 1px;
            }
            &::-webkit-scrollbar {
                width: 2px;
                background-color: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $primary;
                border-radius: 1px;
            }
        }
        iframe {
            max-width: 100%;
        }
        &.container {
            max-width: 1378px;
            margin: 0 auto;
            padding: 0 1.6rem;
            @include mq($screen-xxl) {
                padding: 0;
            }
        }
        &__wrapper {
            position: relative;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            @include mq($screen-m) {
                display: grid;
                max-width: $container-desktop-width;
                grid-template-columns: 51% 43%;
                column-gap: 3%;
            }
            @include mq($screen-xl) {
                grid-template-columns: 79rem 1fr;
                column-gap: 8rem;
                min-height: 55rem;
            }

            #ts_product_widget {
                grid-column-start: 1;
                grid-row-start: 1;
                margin: 0;
                margin-left: 10rem;
                font-size: 1.4rem;
                min-height: 18px;

                @include mq($screen-m) {
                    margin: 0;
                    margin-left: 10rem;
                }
            }
             .attribute-value.available-soon {
                 display: none;
             }
        }
        &__wrapper:has(#ts_product_widget) .attribute-stock {
            margin-top: 5px;

            .attribute-value {
                border-right: 1px solid #D8D8D8;
            }
        }
        &__wrapper:has(.attribute-value.out-of-stock) {
            .attribute-stock {
                display: none;
            }
            #ts_product_widget {
                margin: 0;
            }
        }
         &__wrapper:has(.preorder-msg) {
             .attribute-value.available-soon {
                 display: inline-block !important;
             }
             .attribute-value.in-stock {
                 display: none;
             }
             #ts_product_widget {
                 margin-left: 14rem;
             }
         }
        &__gallery {
            position: relative;
            grid-column-start: 1;
            grid-column-end: span 1;
            grid-row-start: 1;
            grid-row-end: span 1;
            align-self: flex-start;
            overflow: hidden;
            max-height: initial;
            width: 100%;
            height: auto;
            overflow: initial;
            min-height: 550px;
            order: -1;
            @include mq($screen-m) {
                height: 0;
                min-height: auto;
            }
            .gallery {
                border: 1px solid #E5E5E5;
            }
            .fotorama__nav {
                text-align: center;
                margin: 0;
            }
        }
        &__title {
            grid-column-start: 2;
            grid-column-end: span 1;
            grid-row-start: 1;
            grid-row-end: span 1;
            font-family: $font-libre-bold;
            color: $primary;
            @include mq($screen-l) {
                grid-column-end: span 2;
                margin-bottom: 3.5rem;
            }
            &.heading--page {
                font-size: 2rem;
                line-height: 3.2rem;
                margin: 1rem 0;
                text-align: left;
                @include mq($screen-m) {
                    margin: 0 0 1rem 0;
                    font-size: 3.2rem;
                    line-height: 4.2rem;
                }
            }
        }
        &__details {
            position: static;
            display: flex;
            flex-direction: column;
            @include mq($screen-m) {
                grid-row-start: 1;
                margin: 0;
            }
            @include mq($screen-l) {
                width: 100%;
            }
            @include mq($screen-xl) {
                grid-column-end: span 2;
            }
            @include mq($screen-xlg) {
                max-width: 55rem;
            }
            .back-to-list {
                display: none!important;
            }
            #price-from-label {
                font-size: 1.5rem;
            }
        }
        &__short-description {
            @include t3;
            text-align: left;
            padding: 1.6rem 0 0 0;
            height: fit-content;
            font-size: 1.4rem;
            line-height: 2.2rem;
            p {
                @include t3;
                font-size: 1.4rem;
                text-align: left;
                margin: 0 0 $margin-small 0;
            }
            ul {
                margin: 0;
            }
            li {
                display: block;
                list-style-type: none;
                position: relative;
                margin: 0 0 0.8rem 1.5rem;
                &::before {
                    @include list-bullet;
                    position: absolute;
                    top: 0.6rem;
                    margin: 0 0 0 -1.5rem;
                }
            }
            &.active {
                li {
                    display: none;
                }
                li:nth-child(1),
                li:nth-child(2),
                li:nth-child(3),
                li:nth-child(4),
                li:nth-child(5) {
                    display: block;
                }
                ~ .more-details-container {
                    .more-details-btn {
                        &::after {
                            transform: translate(0, -50%) rotate(0);
                        }
                    }
                }
            }
        }
        .more-details-container {
            position: relative;
            .more-details-btn {
                color: $primary;
                background: none;
                border: none;
                text-transform: none;
                font-size: 1.4rem;
                line-height: 2.2rem;
                font-family: $font-open-sans;
                font-weight: 400;
                padding: 0;
                margin: 0;
                position: relative;
                letter-spacing: normal;
                display: inline-block;
                width: fit-content;
                padding-right: 22px;
                min-height: auto;
                &::after {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translate(0, -50%) rotate(180deg);
                    content: url(../images/icons/show-more.svg);
                    width: 17px;
                    height: 17px;
                    display: inline-block;
                }
                &.less {
                    &::after {
                        transform: translate(0, -50%) rotate(180deg);
                    }
                }

            }
        }
        &__more-information {
            li {
                @include t3;
                font-size: 1.4rem;
            }
        }
        &__customization {
            border-bottom: $border-gold;
            margin: 0 0 $margin-md 0;
            .bundle-option__title {
                font-family: $font-open-sans-bold;
                font-size: $font-size-normal;
                line-height: $line-height-normal;
                color: $black;
                text-transform: none;
            }
            .select2-container {
                .select2-selection--single {
                    border: $border-gold;
                    .select2-selection__rendered {
                        @include placeholder;
                    }
                    .select2-selection__arrow b {
                        border-color: $primary;
                    }
                }
                .select2-results__option {
                    font-family: $font-open-sans-bold;
                    font-size: $font-size-normal;
                }
            }
        }
        &__description-bundle {
            display: none;
        }
        &__brief,
        &__stock-status {
            display: none;
        }
        &__main-actions {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            background: $beige1;
            padding: 1.5rem 1.5rem 1.5rem 2.5rem;
            margin-top: 3px;
            @include mq($screen-m) {
                margin-top: 5px;
            }
            .product-view__addtowishlist {
                order: 0;
                min-width: fit-content;
            }
            .block-bundle-summary {
                width: 100%;
                @include mq($screen-m) {
                    width: auto
                }
            }
            .stock.unavailable {
                margin: 0;
                font-size: 1.6rem;
                line-height: 2.5rem;
                font-family: $font-open-sans;
                color: $black;
                position: relative;
                padding-left: 2.9rem;
                @include mq($screen-m) {
                    margin: 0 auto 0 2rem;
                }
                &::after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: url(../images/icons/out-of-stock.svg);
                    width: 1.8rem;
                    height: 1.8rem;
                    display: inline-block;
                }
            }
            .preorder-msg {
                position: relative;
                width: 100%;
                order: -1;
                font-size: 1.2rem;
                line-height: 1.6rem;
                font-family: $font-open-sans;
                font-weight: 400;
                margin-bottom: 2rem;
                padding-left: 4.5rem;
                &::before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    content: url(../images/icons/preorder.svg);
                    display: inline-block;
                }
            }
        }
        &__main-actions:has(.price__value--old) {
            align-items: flex-end;
        }
        &__main-actions:has(.stock.unavailable) {
            align-items: center;
            .product-view__price {
                margin: 0!important;
            }
            .price__value {
                color: #70787A!important;
            }
        }
        &__summary {
            @include flex(column, flex-start);
            align-items: flex-end;
            padding: $padding-normal 0;
            font-size: 1.2rem;
            .bundle-summary {
                @include t3;
                text-transform: none;
                &.empty {
                    display: none;
                }
            }
            // .product-view__price-unit {
            //     display: none;
            // }
            .bundle-summary {
                max-width: none;
                @include mq($screen-m) {
                    max-width: 13.5rem;
                }
                .list__item {
                    padding: 0;
                }
                .product-view__summary-item-value {
                    text-align: left;
                }
            }
        }
        &__summary-price {
            width: auto;
            margin: 0;

            .price-wrapper__asterisk {
                display: none;
            }
        }
        &__summary-unit {
            @include t3;
            color: $black;
            margin: 0.7rem 0 0 0;
        }
        &__summary-item {
            .list__item {
                padding: 0;
            }
        }
        &__summary-item-value {
            text-align: right;
        }
        &__summary-item-title {
            @include t3;
            text-transform: none;
        }
        &__summary-item-value {
            font-family: $font-open-sans-condensed;
            font-size: $font-size-normal;
            line-height: $line-height-normal;
            color: $gray2;
        }
        &__add-to-cart, &__update-cart {
            background: $primary;
            width: auto;
            height: 4rem;
            min-height: 4rem;
            font-family: $font-open-sans-condensed;
            font-weight: $font-weight-bold;
            font-size: 1.2rem;
            margin: 0 1rem;
            display: flex;
            &:hover,
            &:focus {
                @include btn-primary-hover;
            }
            .button__text {
                display: block;
            }
        }
        &__addtowishlist {
            display: flex;
            width: auto;
            background: transparent;
            min-height: 36px;
            margin: 0;
            justify-content: flex-end;
            padding: 0;
            flex-grow: 0;
            min-width: fit-content;
            .button__icon {
                width: 3.2rem;
                height: 3.2rem;
            }
        }
        &__form {
            border: none;
            @include mq($screen-l) {
                padding: 0;
            }
        }
        &__form > div.product-view__main-actions > button > svg {
            width: 36px;
            height: 36px;
            margin: 0 -0.6rem 0 0;
            fill: $primary;
            &:hover,
            &:focus {
                fill: $primary-hover;
            }
        }
        .attribute-stock {
            grid-column-start: 1;
            grid-row-start: 1;
            @include mq($screen-m) {
                margin-top: 5px;
            }
            .attribute-value {
                font-size: 1.6rem;
                line-height: 1.6rem;
                font-family: $font-open-sans;
                font-weight: 600;
                color: $green;
                padding-right: 1.6rem;
                &.out-of-stock {
                    color: $primary;
                }
            }
             .attribute-value.available-soon {
                 color: #E3782A;
             }
            .attribute-label {
                &::after {
                    content: "";
                }
            }
        }
        &__attributes {
            width: 100%;
            background: transparent;
            margin: 1.6rem 0;
            padding: 1.6rem 0;
            border-top: $border-gold;
            border-bottom: $border-gold;
            font-size: $font-size-normal;
            line-height: $line-height-normal;
            ul {
                margin: 0;
            }
            .attribute-stock {
                .attribute-value {
                    color: $green;
                    &.out-of-stock {
                        color: $primary;
                    }
                }
                .attribute-label {
                    &::after {
                        content: "";
                    }
                }
            }
            .attribute-weight {
                .attribute-value {
                    &::after {
                        content: "kg";
                        margin: 0 0 0 0.3rem;
                    }
                }
            }
            .attribute-label {
                font-family: $font-open-sans;
                margin: 0 0.8rem 0 0;
                &::after {
                    content: ":";
                }
            }
        }
        .attribute-weight {
            .attribute-value {
                &::after {
                    content: "kg";
                    margin: 0 0 0 0.3rem;
                }
            }
        }
        .price--between {
            .price-to {
                display: none;
            }
            .price__text {
                min-width: unset;
            }
        }
        &__price {
            display: flex;
            justify-content: flex-start;
            font-family: $font-open-sans-condensed;
            font-weight: $font-weight-bold;
            font-size: 2.5rem;
            padding: 0;
            line-height: 4.5rem;
            margin: 0 auto 1rem 0;
            width: 100%;
            @include mq($screen-m) {
                width: auto;
                margin: 0 auto 0 0;
            }
            .price {
                display: flex;
                flex-direction: row-reverse;
                gap: 1.6rem;
                @include mq($screen-m) {
                    display: inline-block;
                    width: auto;
                    margin: 0 auto 0 0;
                }
                width: auto;
                text-align: right;
                margin: 0 auto 0 0;
                .price__value {
                    display: block;
                    margin: 0;
                    &--old {
                        color: #999;
                        text-decoration: line-through;
                        .price {
                            text-decoration: line-through;
                        }
                    }
                }
            }
            .price-wrapper__asterisk {
                display: none;
            }
            .price-container:has(#price-from-label) {
                @include mq($max-screen: $screen-m) {
                    display: flex;
                    flex-direction: row;
                    align-items: baseline;
                    gap: 1rem;
                }
            }
        }
        &__price-unit {
            width: 100%;
            font-family: $font-open-sans;
            font-size: $font-size-normal;
            line-height: $line-height-normal;
            text-align: right;
            color: $gray4;
            // margin: 0.6rem 0 1.6rem 0;
            margin: 0;
            // display: none;
        }

        &__qty {
            margin: 0;
            @include mq($screen-m) {
                width: auto;
            }
            @include mq($screen-l) {
                margin: 0;
            }
            label {
                display: none;
            }
            .quantity-update {
                font-family: $font-open-sans;
                border: 1px solid #D8D8D8;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                width: 9rem;
                .quantity-update__button {
                    width: 2.9rem;
                    height: 4rem;
                    border: none;
                    order: inherit;
                    margin: 0;
                    padding: 0;
                    min-width: auto;
                    min-height: auto;
                    .quantity-update__icon {
                        width: 1.6rem;
                        height: 1.6rem;
                    }
                }
                &__input {
                    width: 3rem;
                    height: 4rem;
                    margin: 0;
                    padding: 0;
                    border: none;
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                }
            }
        }
        &__buttons-wrapper {
            @include mq($screen-l) {
                width: auto;
            }
        }
        &__buttons {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
        }
        &__button--wishlist {
            &.button--icon-filled {
                background-color: transparent;
                &::after {
                    background-image: $catalog-heart-icon-image;
                    right: 0;
                    width: $catalog-heart-icon-size;
                    height: $catalog-heart-icon-size;
                }
                &:hover {
                    &::before {
                        background-color: transparent;
                    }
                    &::after {
                        background-image: $catalog-heart-icon-image;
                    }
                }
                svg {
                    width: $catalog-heart-icon-size;
                    height: $catalog-heart-icon-size;
                }
            }
        }
        &__tab {
            display: flex;
            flex-direction: column;
            position: relative;
            max-width: $container-desktop-width;
            margin: 0 -1.6rem;
            @include mq($screen-l) {
                display: grid;
                grid-template-columns: 51% 43%;
                column-gap: 3%;
                align-items: start;
                margin: 0;
            }
            @include mq($screen-xl) {
                // grid-template-columns: 43.4rem 90.2rem;
                grid-template-columns: 79rem 1fr;
                column-gap: 8rem;
                min-height: 55rem;
                min-height: auto;
                padding: 0;
            }
        }
        &__tabs {
            width: $full-width;
            max-width: $container-desktop-width;
            margin: 3.5rem auto;
            @include mq($screen-m) {
                margin: 0 auto;
            }
            &-sticky-wrap {
                display: none;
            }
            .tab__title {
                max-width: $full-width;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                background: transparent;
                border-top: 4px solid #F2F2F2;
                border-bottom: none;
                font-family: $font-libre-bold;
                font-size: 1.8rem;
                text-align: left;
                color: $primary;
                padding: 0 1.6rem;
                border-radius: 0;
                margin:0;
                height: 64px;
                display: none;
                text-transform: capitalize;
                @include mq($screen-l) {
                    display: none;
                    font-size: 2.4rem;
                    height: auto;
                    pointer-events: none;
                    margin: 4rem 0 2rem 0;
                    cursor: default;
                    border-top: none;
                    border-bottom: 1px solid #F2F2F2;
                    grid-column-start: 1;
                    grid-column-end: 3;
                    padding: 0;
                }
                &:last-of-type {
                    border-bottom: 4px solid #F2F2F2;
                    @include mq($screen-m) {
                        border-bottom: 1px solid #F2F2F2;
                    }
                }
            }
            .tab__content {
                padding: 0 1.6rem;
                font-size: 1.4rem;
                line-height: normal;
                border: none;
                grid-column-start: 1;
                grid-column-end: 3;

                @include mq($screen-m) {
                    padding: 0;
                    display: none;
                    opacity: 1;
                }
                .show-more-container {
                    position: relative;
                    margin-top: 0;
                    &::before {
                        display: none;
                    }
                }
                >.description:has( >.truncated) {
                    .show-more-container {
                        &::before {
                            display: block;
                            position: absolute;
                            top: -4rem;
                            left: 0;
                            right: 0;
                            content: "";
                            height: 4.5rem;
                            background: #fff;
                            background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#fff));
                            background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), #fff);
                            background: linear-gradient(180deg, rgba(255, 255, 255, 0), #fff);
                        }
                    }
                }
                h2 {
                    font-family: $font-libre-bold;
                    font-size: $font-size-xxlarge;
                    color: $primary;
                    &.review__title {
                        color: $black;
                    }
                }
                h3 {
                    font-family: $font-libre-bold;
                    font-size: 1.5rem;
                    color: $primary;
                }
                p {
                    font-size: $font-size-md;
                }
                li {
                    font-family: $font-open-sans;
                    font-size: $font-size-md;
                    list-style-type: none;
                    position: relative;
                    line-height: 1.8rem;
                    margin: 0 0 0 2rem;
                }
                ul li::before {
                    @include list-bullet;
                    margin: 0 0 0 -2rem;
                    top: 0.6rem;
                    left: 0;
                    position: absolute;
                }
                ol > li {
                        list-style-type: auto;
                        padding-left: 5px;
                        &::marker {
                            font-weight: bold;
                        }
                    }
                .ts-rating {
                    li {
                        margin: 0;
                        &:before {
                            content: none;
                        }
                    }
                }
            }
            // description
            .tab__title:has(+ .tab__content .product-attribute.description) {
                display: block;
                @include mq($screen-m) {
                    display: grid;
                    grid-column-start: 1;
                    grid-column-end: 2;
                }
            }
            .tab__content:has(.product-attribute.description) {
                @include mq($screen-m) {
                    display: grid;
                    grid-column-start: 1;
                    grid-column-end: 2;
                }
            }
            // information
            .tab__title:has(+ .tab__content .product.attribute.information) {
                display: block;
                @include mq($screen-m) {
                    display: grid;
                    grid-column-start: 2;
                    grid-column-end: 3;
                }
            }
            .tab__content:has(.product.attribute.information) {
                @include mq($screen-m) {
                    display: grid;
                    grid-column-start: 2;
                    grid-column-end: 3;
                }
            }
            // productdetails
            .tab__title:has(+ .tab__content .product.attribute.productdetails) {
                display: block;
                @include mq($screen-m) {
                    display: grid;
                    grid-column-start: 2;
                    grid-column-end: 3;
                }
            }
            .tab__content:has(.product.attribute.productdetails) {
                @include mq($screen-m) {
                    display: grid;
                    grid-column-start: 2;
                    grid-column-end: 3;
                }
            }
            .tab__content:has(.product-view__more-information li) {
                @include mq($screen-m) {
                    display: grid;
                    grid-column-start: 2;
                    grid-column-end: 3;
                }
            }
            .tab__title:has(+ .tab__content .product-view__more-information li) {
                display: block;
                @include mq($screen-m) {
                    display: grid;
                    grid-column-start: 2;
                    grid-column-end: 3;
                }
            }
            .tab__title:has(+ .tab__content .attribute-weight) {
                @include mq($screen-m) {
                    display: grid;
                    grid-column-start: 2;
                    grid-column-end: 3;
                }

            }
            .tab__content:has(.attribute-weight) {
                @include mq($screen-m) {
                    display: grid;
                    grid-column-start: 2;
                    grid-column-end: 3;
                }
            }

            .tab__title:has(+ .tab__content .attribute-nutritional_information) {
                @include mq($screen-m) {
                    display: grid;
                    grid-column-start: 2;
                    grid-column-end: 3;
                }

            }
            .tab__content:has(.attribute-nutritional_information) {
                @include mq($screen-m) {
                    display: grid;
                    grid-column-start: 2;
                    grid-column-end: 3;
                }
            }

            // reviews
            .tab__title:has(+ .tab__content .trusted-shops__review-header-text) {
                display: block;
                @include mq($screen-m) {
                    display: none;
                }
            }
            .attr-container {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                height: fit-content;
            }
        }
        &__tabs:has(.tab__content .product.attribute.information.description) {
            // productdetails
            .tab__title:has(+ .tab__content .product.attribute.productdetails) {
                display: block;
                @include mq($screen-m) {
                    display: none;
                }
            }
            .tab__content:has(.product.attribute.productdetails) {
                @include mq($screen-m) {
                    display: none;
                }
            }
            .tab__content:has(.product-view__more-information li) {
                @include mq($screen-m) {
                    display: none;
                }
            }
            .tab__title:has(+ .tab__content .product-view__more-information li) {
                display: block;
                @include mq($screen-m) {
                    display: none;
                }
            }
            .tab__title:has(+ .tab__content .attribute-weight) {
                @include mq($screen-m) {
                    display: none;
                }
            }
            .tab__content:has(.attribute-weight) {
                @include mq($screen-m) {
                    display: none;
                }
            }
        }

    }
    .product.attribute.description {
        margin: 0;
        p, td, th {
            font-family: $font-open-sans;
            font-size: $font-size-md;
            margin: 0 0 2rem 0;
            line-height: 2.1rem;
            color: black;
        }
        h2 {
            font-family: $font-libre-bold;
            font-size: $font-size-xxlarge;
            margin: 0 0 2rem 0;
            line-height: 3rem;
            color: black;
        }
        h3 {
            font-family: $font-libre-bold;
            font-size: $font-size-xlarge;
            margin: 0 0 1.5rem 0;
            color: black;
        }
    }
    .social-media-icons {
        display: flex;
        justify-content: flex-end;
        width: 27%;
        @include mq($screen-l) {
            width: 36%;
        }
        a {
            @include centerItem;
            width: 3.6rem;
            height: 3.6rem;
            background: $secondary;
            margin: 0 0 0 1rem;
            transition: $transition;
            &:hover,
            &:focus {
                background: $secondary-hover;
            }
        }
        img {
            width: 1.6rem;
            height: 1.6rem;
        }
    }
    .add-to-cart {
        &[data-aos-id="addtocart-sticky"] {
            padding: $padding-sm;
            // ADD THIS ONLY FOR WORK
            display: none!important;
        }
        &__wrapper {
            .product-view__price-unit {
                text-align: left;
            }
        }
    }
    .review {
        &.empty {
            font-size: $font-size-lg;
        }
        &__title {
            color: $black;
            &-product {
                color: $primary;
            }
        }
        .fieldset {
            &[aria-lebeledby="rating-label-legend"] {
                padding: $padding-medium 0 0 0;
                @include mq($screen-m) {
                    @include flex(row, flex-start);
                    align-items: center;
                    margin: $margin-xlarge 0;
                }
            }
            legend {
                font-size: 0;
                line-height: 0;
            }
        }
        &__rating-legend {
            color: $black;
        }
        .rating--rate {
            @include mq($screen-m) {
                margin: 0 $margin-m 0 0;
                padding: 0;
            }
        }
        &__fieldset {
            margin: 0;
        }
        .input__field {
            width: $full-width;
        }
        .submit {
            @include btn-primary;
            font-family: $font-open-sans-condensed;
            &:hover,
            &:focus {
                @include btn-primary-hover;
            }
        }
    }
    .back-to-list {
        font-family: $font-open-sans-bold;
        font-size: $font-size-normal;
        color: $primary;
        &:hover,
        &:focus {
            color: $primary-hover-darker;
            text-decoration: none;
            &::before {
                color: $primary-hover-darker;
                text-decoration: none;
            }
        }
        &::before {
            content: '\276e';
            display: inline-block;
            margin: -0.2rem 0.2rem 0 0;
        }
    }

    .fotorama {
        &__wrapper {
            padding-bottom: 0 !important;
        }
        &__nav {
            display: flex;
            justify-content: center;
            @include mq($screen-m) {
                display: block;
            }
        }
    }

    .fotorama__wrap {
        .fotorama__stage {
            width: initial !important;
            max-height: 434px !important;
        }
    }

    .fotorama.fotorama--fullscreen {
        .fotorama__wrap.fotorama__wrap--video {
            .fotorama__stage {
                .fotorama__stage__shaft {
                    @include mq($screen-l) {
                        width: 78%!important;
                        margin: auto!important;
                    }
                    @include mq($screen-xl) {
                        width: 64%!important;
                        margin: auto!important;
                    }
                    @include mq($screen-xxl) {
                        width: 46%!important;
                        margin: auto!important;
                    }
                }
            }
        }
    }
    //+++++ newspaper
    &.product_view_newspaper {
        .options-list {
            gap: 1rem;
            .option_images_gallery {
                display: none;
            }
            .checkbox  + .label,
            .radio + .label {
                padding: 10px 25px;
                background: $secondary;
                font-size: 1.4rem;
                color: $white!important;
                border: none;
                margin: 0;
                &:hover,
                &:focus {
                    background: $primary;
                    color: $white;
                }
                .price-notice {
                    display: none;
                }
            }
            .checkbox:checked + label,
            .radio:checked + label {
                background: $primary;
                color: $white;
            }
            .radio.product-custom-option + label:before,
            .checkbox.product-custom-option + label:before,
            .radio.product-custom-option + label:after,
            .checkbox.product-custom-option + label:after {
                display: none;
            }
            input.checkbox.product-custom-option {
                position: absolute;
                left: -9999px;
            }
            .field.choice.admin__field {
                border: none;
                border-radius: 0;
            }
        }
        .field.required ~ .field.required > .control,
        .field.required ~ .field.required > label {
            position: static!important;
        }
        .product-options-wrapper {
            >.field.required ~ .field.required {
                margin: 0 0 2rem 0;
            }
        }
    }

    // #wdg-8c5114cc-2faf-4c2d-aa38-092f0360b7eb
    etrusted-widget {
        font-size: 1.5rem;
    }
    .only-mobile {
        display: block;
        @include mq($screen-m) {
            display: none;
        }
    }
    .only-desktop {
        display: none;
        @include mq($screen-m) {
            display: block;
        }
    }

    //+++++ show more btn
    .show-more {
        color: $primary;
        background: none;
        border: none;
        text-transform: none;
        font-size: 1.4rem;
        line-height: 2.2rem;
        font-family: $font-open-sans;
        font-weight: 400;
        padding: 0;
        margin: 0;
        position: relative;
        letter-spacing: normal;
        display: inline-block;
        width: fit-content;
        padding-right: 22px;
        min-height: auto;
        &::after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            content: url(../images/icons/show-more.svg);
            width: 17px;
            height: 17px;
            display: inline-block;
        }
        &.less {
            &::after {
                transform: translate(0, -50%) rotate(180deg);
            }
        }
    }

    //+++++ product-options
    .product-view__options {
        position: relative;
        padding: 2rem 4rem;
        background: #f9f5f1;
        margin-bottom: 0;
        @include mq($screen-m) {
            margin-bottom: 0;
            padding: 1.5rem 1.5rem 1.5rem 4.5rem;
        }
        &::before {
            content: "";
            width: 2.4rem;
            height: 5.2rem;
            background: url(../images/icons/options-before.svg) no-repeat;
            background-size: contain;
            position: absolute;
            top: 0;
            left: 1rem;
            z-index: 1;
        }
        .product-options-wrapper {
            padding: 0;
            margin: 0;
            >.field {
                margin-bottom: 1.5rem!important;
                margin-right: 0!important;
                >.label {
                    margin: 0 0 1.5rem 0!important;
                    font-size: 1.2rem;
                    line-height: 2.2rem;
                    font-family: $font-open-sans;
                    font-weight: 700;
                }
                .label.admin__field-label {
                    font-size: 1.4rem;
                    line-height: 2.2rem;
                    color: $black;
                    font-family: $font-open-sans;
                    font-weight: 400;
                    .price-notice {
                        font-size: 1.4rem;
                        line-height: 2.2rem;
                        color: #70787A;
                        font-weight: 400;
                    }
                }
                .control {
                    margin: 0;
                    .note {
                        margin: 0;
                    }
                }
                .select {
                    margin: 0;
                }
            }
            > .field:first-of-type {
                >.label {
                    font-size: 1.4rem;
                }
            }
            > .field.required {
                border: none;
                &::before {
                    display: none;
                }
            }
            p.required {
                font-size: 1.2rem;
                line-height: 1.6rem;
                color: $black;
                font-family: $font-open-sans;
                margin: 0;
            }
            #price-from-label {
                font-size: 0;
                .price-wrapper {
                    font-size: 1.5rem;
                }
            }
        }
        div {
            &.required {
                label {
                    &:not(.checkbox__label) {
                        &:after {
                            content: '*';
                        }
                    }
                }
                input.radio ~ label:after {
                    content: ' ';
                }
                > .checkbox__label {
                    & > span,
                    & .checkbox__text {
                        &:first-of-type {
                            &:after {
                                content: ' *';
                            }
                        }
                    }
                }
            }
        }
    }

    //+++++ choose-shipping
    .choose-shipping {
        &__info {
            background: #F9F5F1;
            padding: 2.3rem 1rem;
            margin: 2.4rem 0 1.6rem 0;
            @include mq($screen-m) {
                padding: 2rem 1rem;
            }
            h6 {
                color: $black;
                font-family: $font-open-sans;
                font-size: 1.4rem;
                line-height: 1.8rem;
                font-weight: 600;
                font-style: normal;
                margin: 0;
                text-transform: none;
                padding-left: 57px;
                position: relative;
                @include mq($screen-m) {
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                }
                &:before {
                    content: "";
                    width: 4.1rem;
                    height: 4.1rem;
                    background: url(../images/icons/shipping-top-icon.svg) no-repeat;
                    background-size: contain;
                    position: absolute;
                    width: 41px;
                    height: 41px;
                    left: 0;
                    top: 50%;
                    transform: translate(0,-50%);
                }
            }
        }
        &__dropdown {
            border: 1px solid #D8D8D8;
            padding: 1.6rem;
        }
        &__dropdown-content {
            visibility: hidden;
            opacity: 0;
            height: 0;
            overflow: hidden;
        }
        &__dropdown-title {
            color: $black;
            font-family: $font-open-sans;
            font-size: 1.4rem;
            line-height: 2.4rem;
            font-style: normal;
            font-weight: 700;
            margin: 0 ;
            cursor: pointer;
            position: relative;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            max-width: 90%;
            @include mq($screen-sm) {
                max-width: 100%;
            }
            &::after {
                position: absolute;
                right: -14%;
                top: 50%;
                transform: translate(0, -50%);
                content: url(../images/icons/dropdown-footer.svg);
                text-align: center;
                width: 24px;
                height: auto;
                display: inline-block;
                @include mq($screen-sm) {
                    right: 0;
                }
            }
        }
        &__dropdown-title.active {
            &::after {
                transform: translate(0, -50%) rotate(180deg);
            }
        }
        &__dropdown-title.active ~ .dropdown-content {
            visibility: visible;
            opacity: 1;
            height: auto;
        }
        &__dropdown-item {
            margin-top: 1.6rem;
            position: relative;
            padding-left: 48px;
            @include mq($screen-m) {
                margin-top: 2.4rem;
            }
            &::before {
                content: "";
                width: 3.3rem;
                height: 3.2rem;
                background-size: contain;
                position: absolute;
                left: 0;
                top: 0;
            }
            &:nth-child(1)::before {
                background: url(../images/icons/shipping-dropdown-1.svg) no-repeat;
            }
            &:nth-child(2)::before {
                width: 3.6rem;
                background: url(../images/icons/shipping-dropdown-2.svg) no-repeat;
            }
            h6 {
                color: $black;
                font-family: $font-open-sans;
                font-size: 1.2rem;
                line-height: normal;
                font-style: normal;
                font-weight: 700;
                margin: 0;
                text-transform: none;
                @include mq($screen-m) {
                    font-size: 1.4rem;
                }
            }
            p {
                color: #70787A;
                font-family: $font-open-sans;
                font-size: 1.2rem;
                line-height: 1.6rem;
                font-style: normal;
                font-weight: 400;
                margin: 0;
                @include mq($screen-m) {
                    font-size: 1.4rem;
                }
            }
            .dropdown-item-price {
                color: $black;
                font-family: $font-open-sans-condensed;
                font-size: 1.4rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.6rem;
                flex-shrink: 0;
                @include mq($screen-m) {
                    font-size: 1.6rem;
                }
            }
            .dropdown-item-txt {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-between;
            }
        }
        &__top-info {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            margin-bottom: 1rem;
            gap: 2rem;
        }
    }
    .product-view__details:has(.stock.unavailable) {
        .choose-shipping__info {
            display: none;
        }
        .choose-shipping__container {
            margin: 0.5rem 0;
        }
        .product-view__options {
            pointer-events: none;
        }
    }
    .product-view.container {
        display: flex;
        flex-direction: column;
        max-width: 1378px;
        margin: 0 auto;
    }
    .upsell {
        padding: 0;
        margin: 3.2rem 0;
        @include mq($screen-m) {
            margin: 7rem 0;
        }
    }
    .product-items {
        padding: 0;
        margin-left: -1.6rem;
        margin-right: -1.6rem;
        gap: 1.6rem;
        .slick-list {
            width: 100%;
        }
        .slick-arrow {
            border-radius: 50%;
            width: 48px;
            height: 48px;
            line-height: 48px;
            font-size: 0;
            background: #F5E6D3 url("../images/slider-arr.svg") center no-repeat;
            position: absolute;
            top: 50%;
            transform: translate(0,-50%);
            margin: 0;
        }
        .slick-dots {
            display: flex;
            justify-content: center;
            align-items: center;
            li {
                list-style: none;
                margin: 0 8px;
                button {
                    font-size: 0;
                    width: 8px;
                    height: 8px;
                    line-height: 8px;
                    min-height: 8px;
                    border-radius: 50%;
                    padding: 0;
                    background-color: $white;
                    border: 1px solid #D8D8D8;
                }
            }
            li.slick-active button {
                background-color: $primary;
                border: 1px solid $primary;
            }
        }
        .slick-next {
            left: 100%;
        }
        .slick-prev {
            right: 100%;
            transform: translate(0,-50%) rotate(180deg);
        }
        // &::-webkit-scrollbar {
            // display: none;
        // }
        .product-item-details {
            padding: 0;
        }
        .product-item {
            border: none;
            box-shadow: none;
            margin: 0 1.6rem;
            &:hover {
                box-shadow: none;
                .product-item-info {
                    box-shadow: none;
                }
            }
            @include mq($screen-xl) {
                // width: 312px;
                // margin: 0;
            }
            .product-item-name {
                margin: 1.3rem 0 1rem 0;
                justify-content: center;
                a {
                    font-size: 1.6rem;
                    line-height: 2.2rem;
                    font-weight: 400;
                    text-align: center;
                    @include mq($screen-m) {
                        font-size: 1.8rem;
                        line-height: 2.4rem;
                    }
                }
            }
            .product-item-details .price-final_price {
                text-align: right;
                margin-bottom: 0.5rem;
            }
            .product-item-inner {
                display: none!important;
            }
        }
        .product-item-photo {
            .lazyload-wrapper {
                min-height: auto;
            }
            img {
                max-width: 225px;
                aspect-ratio: 1/1;
                margin: 0;
                border: 0.1px solid #f4f4f4;
                @include mq($screen-m) {
                    max-width: max-content;
                }
            }
        }
        .product-item-info {
            width: 100%;
        }
        .product-item-info__mark {
            top: 2px;
            right: auto;
            left: 10px;
            width: 24px;
            height: 40px;
        }
        .actions-secondary {
            top: 10px;
            z-index: 1;
            right: 5px;
            left: auto;
            position: absolute;
            padding: 0;
            .button--tocompare {
                display: none;
            }
            .button--wishlist {
                display: block;
                padding: 0;
                min-width: auto;
                min-height: auto;
                margin: 0;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.69);
                .button__icon {
                    margin: auto;
                }
            }
        }
    }

    //+++++ shipment-footnotes
    .shipment-footnotes {
        @include mq($screen-l) {
            padding: 0 1.5rem 1.5rem 2.5rem;
            background: #f9f5f1;
        }
        p {
            margin: 0;
            color: $black;
            font-family: $font-open-sans;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1.6rem;
            a {
                color: $primary
            }
        }
    }

    //+++++ age-categories
    .age-categories {
        margin: 3.2rem -1.6rem 2rem -1.6rem;
        order: 1;
        @include mq($screen-m) {
            margin: 3.2rem auto;
            order: 0;
            width: 100%;
        }
        &>div,
        &>p {
            @include flex(column, space-evenly);
            width: $full-width;
            padding: 0;
            flex-wrap: wrap;
            padding: $padding-m;
            background: #ce9f79;
            margin-bottom: 0;
            @include mq($screen-l) {
                @include flex(row, space-evenly);
                flex-wrap: nowrap;
                background: #F9F5F1;
                margin: 0;
                margin: 7.2rem auto 4.2rem auto;
                padding: 1.3rem 1.3rem 5.5rem 1.3rem;
            }
        }
        a.his-category-widget .item-button {
            bottom: -4.2rem;
        }
    }

    #block-upsell-heading {
        color: $primary;
        font-size: 1.8rem;
        line-height: 2.4rem;
        margin-bottom: 1rem;
        @include mq($screen-l) {
            color: $black;
            font-size: 3.2rem;
            line-height: 4.8rem;
            font-weight: 700;
            margin-bottom: 4rem;
        }
    }

    //+++++ reviews
    .reviews__heading {
        display: none;
    }
    .trusted-shops__review-header-text {
        margin-top: 3rem!important;
        margin-bottom: 0!important;
    }
    .reviews-bottom {
        background: #F9F5F1;
        position: relative;
        padding: 80px 0 30px 0;
        display: none;
        @include mq($screen-m) {
            display: block;
            margin: 0 -16px;
            padding: 80px 15px 30px 15px;
        }
        @include mq($screen-xxl) {
            padding: 80px 0 30px 0;
            margin: 0;
        }
        &::after {
            position: absolute;
            top: 0;
            bottom: 0;
            content: '';
            position: absolute;
            background: #F9F5F1;
            height: 100%;
            min-width: 99.5vw;
            left: 50%;
            transform: translate(-50%, 0);
            z-index: -1;
        }
        .reviews__heading {
            color: $primary;
            font-family: $font-libre-bold;
            font-size: 2.4rem;
            font-style: normal;
            font-weight: 700;
            line-height: 3.2rem;
            margin-bottom: 3rem;
            text-align: left;
            display: none;
            @include mq($screen-m) {
                display: block;
            }
        }
    }
    .footnotes {
        border: none;
    }
    //+++++ newsletter
    .newsletter {
        padding: 1.6rem 1.6rem 7.2rem 1.6rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 0;
        width: auto;
        background-color: $primary;
        @include mq($screen-l) {
            padding: 3.2rem 1.5rem 7.2rem 1.5rem;
            margin: 0;
            width: 100%;
        }
        &__heading {
            justify-content: center;
            margin: 0;
            text-align: center;
            color: $white;
            h4 {
                font-family: $font-libre-bold;
                font-size: 3rem;
                line-height: 3.2rem;
                font-weight: 700;
                margin: 0 0 1rem 0;
                @include mq($screen-l) {
                    font-size: 2.8rem;
                }
            }
            h6 {
                font-family: $font-open-sans;
                font-size: 1.6rem;
                line-height: 2.4rem;
                font-weight: 400;
                margin: 0 0 2.4rem 0;
                text-transform: none;
                @include mq($screen-l) {
                    font-size: 1.8rem;
                    line-height: 3.2rem;
                }
            }
            p {
                color: #D8D8D8;
                font-family: $font-open-sans;
                font-size: 1.2rem;
                font-weight: 400;
                line-height: 1.6rem;
                margin: 0;
                position: absolute;
                bottom: 1.6rem;
                width: 100%;
                left: 0;
                right: 0;
                text-align: left;
                padding: 0 1.6rem;
                @include mq($screen-m) {
                    bottom: 3.2rem;
                    text-align: center;
                }
            }
        }
        &__form {
            width: 100%;
            @include mq($screen-m) {
                width: auto;
            }
        }
        &__controls {
            display: flex;
            flex-direction: column;
            max-width: none;
            @include mq($screen-m) {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
            .his-input {
                width: 100%;
                border-color: $white;
                margin: 0 0 1.6rem 0;
                @include mq($screen-m) {
                    width: 256px;
                    margin: 0 1.6rem 0 0;
                }
            }
            .his-btn-secondary {
                width: 100%;
                background: $white;
                color: $primary;
                @include mq($screen-m) {
                    width: 256px;
                }
            }
        }
    }

    a.his-category-widget {
        @include flex(column, flex-start);
        max-height: 90px;
        margin: $padding-m;
        position: relative;
        box-sizing: border-box;
        &:hover {
            text-decoration: none;
        }
        .item-title {
            width: $full-width;
            margin: 0;
            &.his-btn-primary {
                @include centerItem;
                height: 4rem;
                @include mq($screen-l) {
                    display: none;
                }
            }
        }
        .item-button {
            left: 0%;
            bottom: 0;
            width: 100%;
            display: none;
            margin-bottom: 0;
            position: absolute;
            &::before,
            &::after {
                display: none;
            }
        }
        .item-image {
            display: none;
            max-height: 100%;
        }
    }

    @media screen and (min-width: $screen-l) {
        .age-categories {
            @include flex(row, space-evenly);
            flex-wrap: nowrap;
        }
        a.his-category-widget {
            max-width: 25%;
            max-height: 100%;
            width: 25%;
            .item-title {
                display: none;
            }
            .item-button {
                display: block;
            }
            .item-image {
                display: block;
                width: $full-width;
                img {
                    width: $full-width;
                }
            }
        }
    }
    .footer {
        @include mq($max-screen: $screen-m) {
            margin-top: 0;
        }
    }
}

//+++++ bundle
body.catalog-product-view.page-product-bundle {
    .product-view {
        &__main-actions {
            margin: 1rem -1.5rem 0 -4.5rem;
            border-top: 5px solid #fff;
        }
        &__summary {
            margin: 0;
            align-items: flex-start;
            padding: 0;
        }
    }
}

@media (max-width: 544px) {
    body.catalog-product-view.page-product-bundle {
        .product-view {
            &__main-actions {
                padding: 1.5rem 1.5rem 0 2.5rem;
                margin: 1rem -4rem 0 -4rem;
                border-top: 3px solid #fff;
            }
            &__summary {
                margin: 0;
                align-items: flex-start;
                padding: 0;
            }
        }
    }
    .catalog-product-view {
        .product-view {
            .product-view__gallery {
                min-height: auto;
                .gallery {
                    border: none;
                }
            }
            .fotorama {
                .fotorama__thumb-border {
                    width: 10px!important;
                    height: 10px!important;
                    border-radius: 50%;
                }
                .fotorama__nav {
                    text-align: center;
                    margin: 0 0 25px 0;
                    height: auto!important;
                }
                .fotorama__nav--thumbs .fotorama__nav__frame {
                    width: 10px!important;
                    height: 10px!important;
                    border-radius: 50%;
                    overflow: hidden;
                    border-color: #D8D8D8;
                    border-style: solid;
                    border-width: 5px;
                }
                .fotorama__nav--thumbs .fotorama__nav__frame.fotorama__active {
                    border-color: $primary;
                    border-style: solid;
                    border-width: 5px;
                }
                .fotorama__thumb-border {
                    width: 10px!important;
                    height: 10px!important;
                    border-radius: 50%;
                    border-width: 5px;
                    display: none!important;
                }
            }
        }
    }
}
.category-pdp-like-layout {
    .product-view__attributes {
        .attribute-stock {
            .attribute-value {
                color: $green;
                &.out-of-stock {
                    color: $primary;
                }
            }
            .attribute-label {
                &::after {
                    content: "";
                }
            }
        }
    }
}
