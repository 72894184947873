/*
 * Flags
 */

.datefinder-list {
    &__items{
        .flag {
            display: inline-block;
            background-repeat: no-repeat;
            width: 16px;
            height: 13px;

            &.flag {
                background-size: 16px 11px;
                &-ar {
                    background-image: url('../images/flags/ar.png');
                }
                &-at {
                    background-image: url('../images/flags/at.png');
                }
                &-au {
                    background-image: url('../images/flags/au.png');
                }
                &-be {
                    background-image: url('../images/flags/be.png');
                }
                &-bg {
                    background-image: url('../images/flags/bg.png');
                }
                &-br {
                    background-image: url('../images/flags/br.png');
                }
                &-ca {
                    background-image: url('../images/flags/ca.png');
                }
                &-ch {
                    background-image: url('../images/flags/ch.png');
                }
                &-cl {
                    background-image: url('../images/flags/cl.png');
                }
                &-cn {
                    background-image: url('../images/flags/cn.png');
                }
                &-cu {
                    background-image: url('../images/flags/cu.png');
                }
                &-cz {
                    background-image: url('../images/flags/cz.png');
                }
                &-co {
                    background-image: url('../images/flags/co.png');
                }
                &-de {
                    background-image: url('../images/flags/de.png');
                }
                &-dk {
                    background-image: url('../images/flags/dk.png');
                }
                &-do {
                    background-image: url('../images/flags/do.png');
                }
                &-dz {
                    background-image: url('../images/flags/dz.png');
                }
                &-eg {
                    background-image: url('../images/flags/eg.png');
                }
                &-es {
                    background-image: url('../images/flags/es.png');
                }
                &-fl {
                    background-image: url('../images/flags/fl.png');
                }
                &-fi {
                    background-image: url('../images/flags/fi.png');
                }
                &-fr {
                    background-image: url('../images/flags/fr.png');
                }
                &-gb {
                    background-image: url('../images/flags/gb.png');
                }
                &-gn {
                    background-image: url('../images/flags/gn.png');
                }
                &-gr {
                    background-image: url('../images/flags/gr.png');
                }
                &-hr {
                    background-image: url('../images/flags/hr.png');
                }
                &-hu {
                    background-image: url('../images/flags/hu.png');
                }
                &-il {
                    background-image: url('../images/flags/il.png');
                }
                &-in {
                    background-image: url('../images/flags/in.png');
                }
                &-is {
                    background-image: url('../images/flags/is.png');
                }
                &-it {
                    background-image: url('../images/flags/it.png');
                }
                &-jm {
                    background-image: url('../images/flags/jm.png');
                }
                &-ke {
                    background-image: url('../images/flags/ke.png');
                }
                &-lu {
                    background-image: url('../images/flags/lu.png');
                }
                &-ma {
                    background-image: url('../images/flags/ma.png');
                }
                &-mk {
                    background-image: url('../images/flags/mk.png');
                }
                &-nl {
                    background-image: url('../images/flags/nl.png');
                }
                &-no {
                    background-image: url('../images/flags/no.png');
                }
                &-pa {
                    background-image: url('../images/flags/pa.png');
                }
                &-pe {
                    background-image: url('../images/flags/pe.png');
                }
                &-pk {
                    background-image: url('../images/flags/pk.png');
                }
                &-pl {
                    background-image: url('../images/flags/pl.png');
                }
                &-pt {
                    background-image: url('../images/flags/pt.png');
                }
                &-ro {
                    background-image: url('../images/flags/ro.png');
                }
                &-rs {
                    background-image: url('../images/flags/rs.png');
                }
                &-ru {
                    background-image: url('../images/flags/ru.png');
                }
                &-se {
                    background-image: url('../images/flags/se.png');
                }
                &-si {
                    background-image: url('../images/flags/si.png');
                }
                &-sk {
                    background-image: url('../images/flags/sk.png');
                }
                &-tr {
                    background-image: url('../images/flags/tr.png');
                }
                &-ua {
                    background-image: url('../images/flags/ua.png');
                }
                &-uk {
                    background-image: url('../images/flags/uk.png');
                }
                &-us {
                    background-image: url('../images/flags/us.png');
                }
                &-za {
                    background-image: url('../images/flags/za.png');
                }
                &-cy {
                    background-image: url('../images/flags/cy.png');
                }
                &-jp {
                    background-image: url('../images/flags/jp.png');
                }
            }
        }

        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            .flag {
                &.flag {
                    &-en {
                        background-image: url('../images/flags/gb@2x.png');
                    }
                    &-de {
                        background-image: url('../images/flags/de@2x.png');
                    }
                    &-fr {
                        background-image: url('../images/flags/fr@2x.png');
                    }
                }
            }
        }
    }
}
