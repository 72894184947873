.header-placeholder {
    width: 100%;
    height: 70px;
    display: none;
}
.page-wrapper{
    overflow: initial;
}
header.header{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 50;
}
header.header.sticky {
    width: 100%;
    box-shadow: 0 4px 16px 0 rgba(71, 58, 44, 0.51);

    .header__buttons {
        padding-top: 0;

        .wrapper-h {
            margin-right: 0;
        }
        .cart-title {
            display: none;
        }
    }
    .header__navigation {
        .menu-main-desktop__item:hover .menu-main-desktop__inner-list--level1 {
            top: 45px;
        }

        .header__search-wrapper {
            top: 8px;
            right: calc(50% - #{$input-width-l} / 2);

            .search-form {
                width: $input-width-l;
            }
        }
    }
    .header__brief-info {
        visibility: hidden;
    }
    .header__wrapper {
        padding-top: 0;
    }
}

@media screen and (min-width: $screen-s) {
    header.header.sticky {
        .header__buttons .cart-title {
            display: flex;
        }
    }
}

@media screen and (min-width: $screen-l) {
    header.header.sticky {
        .minicart {
            margin-right: 8px;
        }

        .header__navigation {
            margin-top: -5px;
            margin-bottom: -40px;

            .header__search-wrapper {
                position: absolute;
                margin: 5px 0 10px 0;
                top: 15px;
            }
        }
    }
}
