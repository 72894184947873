div[data-content-type="row"]:has(.birthday-heading) {
    padding-top: 45px;
    padding-bottom: 45px;
    @include mq($screen-m) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}  
.birthday-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1.5rem;
    flex-wrap: nowrap;
    @include mq($screen-m) {
        margin-bottom: 2.5rem;
    }
    &.center {
        justify-content: center;
    }
    &.right {
        justify-content: flex-end;
    }
    &__icon {
        margin-right: 1rem;
        max-width: 48px;
        width: auto;
        @include mq($screen-m) {
            max-width: 99px;
            margin-right: 3rem;
        }
    }
    h2 {
        margin: 0!important;
        text-align: left;
    }
}