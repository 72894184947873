.catalog-product-view {
    .header-delivery-block {
        display: block;
        position: relative;
        @include mq($screen-s) {
            display: none;
        }
    }
}
.header-delivery-block {
    display: none;
    position: relative;
    &__wrapper {
        background: $primary;
        width: 100%;
        padding: 0.8rem;
    }
    &__heading {
        color: $white;
        text-align: center;
        font-family: $font-open-sans;
        font-size: 1.2rem;
        line-height: 1.4rem;
        position: relative;
        &::after {
            position: absolute;
            right: 1.6rem;
            top: 50%;
            transform: translate(0, -50%) rotate(180deg);
            content: url(../images/icons/dropdown-white.svg);
            // content: 'x';
            width: 10px;
            height: auto;
            display: inline-block;
        }
        &:last-of-type {
            display: none;
        }
        >span {
            max-width: 60%;
            display: inline-block;
        }
    }
    &__content {
        visibility: hidden;
        opacity: 0;
        height: 0;
        overflow: hidden;
        transition: visibility 0s, opacity 0.3s linear;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        z-index: 999;
        box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
        background: #F9F5F1;
        padding: 0 1.6rem 1.6rem 1.6rem;
        h4 {
            color: $primary;
            font-size: 1.6rem;
            line-height: 2.2rem;
            text-align: left;
            font-family: $font-libre-bold;
            margin: 2.5rem 0 1rem 0;
        }
        p {
            color: $black;
            font-size: 1.4rem;
            line-height: 2.2rem;
            text-align: justify;
            font-family: $font-open-sans;
            margin: 0;
        }
    }
    &.active {
        .header-delivery-block__content {
            visibility: visible;
            opacity: 1;
            height: 100vh;
        }
        .header-delivery-block__heading {
            &:first-of-type {
                display: none;
            }
            &:last-of-type {
                display: block;
            }
            &:after {
                content: url(../images/icons/close-white.svg);
                width: 1rem;
                height: 1rem;
            }
        }
    }
}

body:has(.header-delivery-block.active) {
    height: 100%;
    position: fixed;
    overflow-y: hidden!important;
}