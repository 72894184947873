.checkout-onepage-success {
    #maincontent {
        width: $full-width;
        max-width: $page-wrapper-container-width;
        margin: -2rem auto 0 auto;
        background: $white;
        padding: $padding-s;
        @include mq($screen-xxl) {
            padding: $padding-s 0 15rem 0;
        }
    }
    .header-delivery-block {
        display: none!important;
    }
}
.success-page {
    width: $full-width;
    max-width: $container-desktop-width;
    margin: 0 auto;
    padding: $padding-medium;
    @include mq($screen-m) {
        padding: $padding-xxlarge 0;
    }
    &__container {
        @include flex(row, center);
        width: $full-width;
        flex-wrap: wrap;
        @include mq($screen-m) {
            @include flex(row, space-between);
        }
    }
    &__left {
        width: $full-width;
        @include flex(column, flex-start);
        align-items: center;
        @include mq($screen-m) {
            width: 48%;

        }
        @include mq($screen-xlg) {
            padding: 7rem 0 10rem 0;
        }
        .heading--page {
            &::before {
                background: url('../images/icons/check-gold.svg') no-repeat center;
            }
        }
    }
    &__right {
        width: $full-width;
        @include flex(column, flex-start);
        align-items: center;
        margin: $margin-xl 0;
        padding: $padding-lg 0;
        background: $beige1;
        @include mq($screen-m) {
            width: 48%;
            max-width: 55rem;
            margin: 0;
            padding: 0;
        }
        @include mq($screen-xlg) {
            padding: 7rem 0 10rem 0;
        }
        .heading--page {
            &::before {
                background: url('../images/icons/user.svg') no-repeat center;
            }
        }
    }
    .heading--page {
        font-family: $font-libre-bold;
        font-size: $font-size-xxlg;
        line-height: $line-height-xxl;
        color: $primary;
        text-transform: none;
        text-align: center;
        margin: $margin-xl 0 $margin-normal 0;
        &::before {
            content: '';
            width: $width-7;
            height: $width-7;
            display: block;
            background-size: contain;
            margin: 0 0 $margin-xl 50%;
            transform: translateX(-50%);
        }
    }
    p {
        @include t3;
        margin: $margin-root 0 0 0;
        text-align: center;
    }
    &__button {
        @include flex(row, center);
        align-items: center;
        margin: $margin-s 0;
        a {
            @include btn-primary;
            @include btn-text;
            padding: $padding-xs $padding-lg;
            &:before {
                display: none;
            }
            &::after {
                display: none;
            }
            &:hover,
            &:focus {
                @include btn-primary-hover;
                &::before {
                    display: none;
                }
            }
        }
    }
}
