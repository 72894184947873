.catalogsearch-result-index {
    .category-wrapper {
        @include mq($screen-l) {
            margin: -24rem auto 0 auto;
        }
    }
    .category-title {
        .heading--page {
            width: 95%;
        }
    }
}

.catalog-category-view {
    .category-wrapper {
        @include mq($screen-l) {
            margin: -25rem auto 0 auto;
        }
    }
}
.catalog-category-view,
.catalogsearch-result-index {
    .category-wrapper {
        .page-main {
            padding: $padding-lg 0;
            position: relative;
            background: $white;
            @include mq($screen-s) {
                padding: $padding-lg;
            }
            &.homepage {
                padding: 0;
            }
        }
    }
    #maincontent {
        margin: 0 auto;
        padding: $padding-s;
        @include mq($screen-xl) {
            padding: $padding-lg;
        }
    }
    .category-image {
        display: none;
    }
    .input__field {
        width: $full-width;
    }
    .breadcrumbs.container {
        padding: 0;
        padding-bottom: $padding-lg;
    }
    .sidebar.sidebar-main {
        max-width: $full-width;
        min-width: 22rem;
        @include mq($screen-m) {
            margin-right: $margin-s;
        }
        @include mq($screen-l) {
            max-width: 23.5%;
        }
        .category-description {
            font-size: $font-size-md;

            p {
                font-size: $font-size-md;
            }
            li {
                @include paragraph;
                position: relative;
                margin: 0 0 0 $margin-s;
            }
            ol {
                padding: 0;
                margin: 0;
                list-style: decimal;
                li {
                    padding-left: 3px;
                    &::marker {
                        color: $primary;
                        font-weight: bold;
                    }
                }
            }
            ul {
                padding: 0;
                margin: 0;
                li {
                    &::before {
                        @include list-bullet;
                        position: absolute;
                        top: 0.5rem;
                        margin: 0 0 0 -2rem;
                    }
                }
            }
        }
    }
    .columns {
        justify-content: space-between;
    }
    .page-layout-2columns-left {
        max-width: initial;
    }
    .category-cms {
        h3 {
            margin-bottom: $margin-xlarge;
        }
        h4 {
            margin-top: $margin-md;
        }
    }
    .category-cms .cms-row {
        .cms-row__picture {
            margin-top: $margin-s;
        }
    }
    .cms-row__text,
    .cms-row__text-half-space {
        p {
            margin-top: $margin-s;
        }
    }
    .category-title {
        @include flex(row, center);
        position: relative;
        width: $full-width;
        background: $secondary;
        min-height: 110px;
        @include mq($screen-xl) {
            margin: 2.8rem 0 0 0;
        }
        @include mq($screen-xlg) {
            // margin: 0;
        }
        @include mq($screen-l) {
            height: $width-40;
        }
        .heading--page {
            @include h3();
            color: $white;
            font-weight: 700;
            text-align: center;
            text-transform: none;
            width: 100%;
            top: 50%;
            left: 50%;
            font-size: 30px;
            position: absolute;
            transform: translate(-50%, -50%);
            margin: 0;
            @include mq($screen-m) {
                font-size: $font-size-xxxlarge;
            }
            @include mq($screen-xlg) {
                margin: $margin-normal 0 0 0;
            }
            @include mq($screen-l) {
                top: 6rem;
                transform: translate(-50%, 0);
                margin: 0;
            }
        }
        .title-background {
            width: auto;
            height: auto;
            max-width: 100%;
            object-fit: cover;
        }
    }
    a.his-btn-primary:hover {
        text-decoration: none;
        color: $white;
    }
    .dashboard-noitem {
        margin: $margin-m auto $margin-xl auto;
        @include mq($screen-xsm) {
            margin: 6rem auto 8rem auto;
        }
        @include mq($screen-m) {
            margin: $margin-s auto 8rem auto;
        }
    }
    .row-bottom {
        width: $full-width;
    }
    .category-seo-text {
        width: $full-width;
        margin: 5rem 0;
        @include mq($screen-xl) {
            column-count: 2;
            column-gap: $margin-s;
        }
        h2 {
            font-family: $font-libre-bold;
            font-size: $font-size-xxlarge;
            line-height: $line-height-extra-large;
            margin: $margin-s 0;
            text-align: left;
            &:first-child {
                @include heading($primary);
                text-align: center;
            }
        }
        h3,
        h4,
        h5,
        h6 {
            @include heading2($black);
            margin: $margin-s 0;
        }
        p {
            @include paragraph;
        }
        li {
            @include paragraph;
            position: relative;
            margin: 0 0 0 $margin-s;
        }
        ol {
            padding: 0;
            margin: 0;
            list-style: decimal;
            li {
                padding-left: 3px;
                &::marker {
                    color: $primary;
                    font-weight: bold;
                }
            }
        }
        ul {
            padding: 0;
            margin: 0;
            li {
                &::before {
                    @include list-bullet;
                    position: absolute;
                    top: 0.5rem;
                    margin: 0 0 0 -2rem;
                }
            }
        }
        a {
            color: $black;
            text-decoration: none;
            font-family: $font-libre-bold;
        }
        iframe{
            max-width: 100%;
        }
    }
    .search.results {
        .block {
            padding-top: $padding-small;
            font-size: $font-size-md;
            a {
                color: $primary;
            }
        }
    }
}

.category-pdp-like-layout {
    .category-title {
        display: none;
    }

    .category-seo-text {
        h2 {
            color: $black !important;
            font-size: 2rem !important;
            text-align: left !important;

            &:first-child {
                margin-top: 0;
            }
        }

        &__header {
            font-family: $font-libre-bold;
            font-size: 3rem;
            line-height: 4.5rem;
            color: #b21d2b;
            margin: 20px 0;
        }
    }

    .category-wrapper {
        margin-top: 1.9rem;
    }

    .category-pdp-like {
        border-bottom: solid 2px $beige1;
        padding-bottom: 40px;

        @include mq($screen-m) {
            display: flex;
        }

        &__header {
            display: flex;
            justify-content: space-between;

            .product-item-info__mark {
                position: relative;
                top: 0;
                right: 0;
            }
        }

        .category-image {
            display:block;

            @include mq($screen-m) {
                width: 40%;
            }

            img {
                width: 100%;
                @include mq(false, $screen_m){
                    aspect-ratio: 1 / 1;
                }
                @include mq($screen-m) {
                    padding-right: 40px;
                }
            }
        }

        &__attributes {
            .product-view__attributes {
                border: none;
                padding: 0 0;
                margin: 0;
                margin-left: 40px;

                .attribute-shipping {
                    font-weight: bold;

                    .attribute-label {
                        font-weight: normal;
                    }
                }
            }

            &-wrapper {
                display: flex;
                align-items: center;
                margin-left: 50px;

                .product-view__price {
                    margin: 0;
                    display: block;
                    .product-view__price-unit {
                        margin: 0;
                        color: $gray6;
                        font-size: 1.6rem;
                    }
                }
            }
        }

        .category-description {

            margin-bottom: 80px;
            font-size: $font-size-md;

            h1 {
                color: $primary;
                font-weight: bold;
                font-family: $font-libre-bold;
                font-size: 3rem;
            }

            p {
                font-size: 1.4rem;
                line-height: 1.8rem;
                font-family: $font-open-sans;
            }
            li {
                @include paragraph;
                position: relative;
                margin: 0 0 0 $margin-s;
            }
            ol {
                padding: 0;
                margin: 0;
                list-style: decimal;
                li {
                    padding-left: 3px;
                    &::marker {
                        color: $primary;
                        font-weight: bold;
                    }
                }
            }
            ul {
                padding: 0;
                margin: 0;
                li {
                    &::before {
                        @include list-bullet;
                        position: absolute;
                        top: 0.5rem;
                        margin: 0 0 0 -2rem;
                    }
                }
            }
        }

        &__wrapper {
            @include mq($screen-m) {
                width: 60%;
            }

            .gift-matcher {
                display: block;
                background: $beige1;
                padding: 15px 25px 30px;

                &__icon {
                    @include mq($screen-m) {
                        width: 3rem;
                        margin-right: 10px;
                    }
                }

                &__button {
                    margin-left: 0;
                }

                &__input {
                    select {
                        border: none;
                        background: $white;
                        color: $black;
                        font-weight: bold;
                        font-size: 1.2rem;
                        padding-left: 10px;
                    }
                }

                &__heading-wrapper {
                    h4 {
                        font-weight: normal;
                    }

                    h3 {
                        margin-bottom: 6px;
                        margin-top: -6px;
                    }

                    h3, h4 {
                        font-size: 1.3rem;
                        font-family: $font-open-sans;
                        color: $black;
                        line-height: 1;
                    }
                }
            }
        }
    }

    .quick-search {
        &__links {
            display: flex;
            flex-wrap: wrap;

            div > p,
            & > p {
                display: flex;
                flex-wrap: wrap;
            }
            a {
                flex-basis: 50%;
                max-width: 50%;
                font-size: 12px;
                border: none;
                @include mq($screen-m) {
                    flex-basis: 25%;
                    max-width: 25%;
                }
            }
        }
    }
    .loader {
        position: fixed;
    }
}

.amasty_xlanding-page-view {
    .dashboard-noitem {
        display: none;
    }
}

.quick-search {
    margin-bottom: 10px;

    &__heading {
        font-family: $font-libre-bold;
        font-size: 3rem;
        line-height: 4.5rem;
        color: $primary;
        margin: 20px 0;
    }

    a {
        display: block;
        text-decoration: none;
        padding: 15px;
        border-bottom: 1px solid $gray7;
    }
}

.page-layout-2columns-left {
    .quick-search {
        background-color: $beige1;
        padding: 24px 24px 25px;
        margin: 20px 0;

        &__heading {
            font-size: 2rem;
            line-height: 3rem;
            font-weight: 700;
            margin: 0 0 20px;
        }

        a {
            font-size: 1.2rem;
            line-height: 1.8rem;
        }
    }
}

.form-personalize-add-to-cart {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
