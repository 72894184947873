.original-gift-bottom {
    position: relative;
    &__list {
        display: flex;
        width: $full-width;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 15px;
        @include mq($screen-m) {
            gap: 32px;
            flex-wrap: nowrap;
            justify-content: center;
        }
    }
    a {
        width: 47%;
        @include mq($screen-m) {
            width: 100%;
        }
    }
    &__list-item {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        h5 {
            color: $primary;
            font-family: $font-libre-bold;
            font-size: 1.6rem;
            line-height: 2.4rem;
            margin: 0.8rem 0;
            @include mq($screen-m) {
                font-size: 2.2rem;
                line-height: 4rem;
                margin: 0.8rem 0 1.6rem 0;
            }
        }
    }
    &__icon {
        position: relative;
        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            bottom: -1px;
            right: -1px;
            width: 24px;
            height: 24px;
            background: url("../images/four-gifts-after.svg") center no-repeat;
            background-size: contain;
            @include mq($screen-m) {
                width: 48px;
                height: 48px;
            }
        }
        img {
            width: 100%;
            aspect-ratio: 1/1;
        }
    }
}
div[data-content-type="row"]:has(.original-gift-bottom) {
    padding: 40px 15px;
    @include mq($screen-s) {
        padding: 50px 30px;
    }
    @include mq($screen-xxl) {
        padding: 100px 15px;
    }
}  



// ========== styles for using sku widget ==========
div.original-gift-bottom:has(.product-sku__item) {
    position: relative;
    display: flex;
    width: $full-width;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    @include mq($screen-m) {
        gap: 32px;
        flex-wrap: nowrap;
        justify-content: center;
    }
    a {
        display: block;
    }
    .product-sku__item {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 47%;
        @include mq($screen-m) {
            width: 100%;
        }
        .product-sku__title {
            color: $primary;
            font-family: $font-libre-bold;
            font-size: 1.6rem;
            line-height: 2.4rem;
            margin: 0.8rem 0;
            @include mq($screen-m) {
                font-size: 2.2rem;
                line-height: 4rem;
                margin: 0.8rem 0 1.6rem 0;
            }
            a {
                color: $primary;
                font-family: $font-libre-bold!important;
            }
        }
    }
    .product-sku__image {
        position: relative;
        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            bottom: -1px;
            right: -1px;
            width: 24px;
            height: 24px;
            background: url("../images/four-gifts-after.svg") center no-repeat;
            background-size: contain;
            @include mq($screen-m) {
                width: 48px;
                height: 48px;
            }
        }
        a {
            width: 100%;
        }
        img {
            width: 100%;
            aspect-ratio: 1/1;
        }
    }
}



div.original-gift-bottom:has(.category-sku__item) {
    position: relative;
    display: flex;
    width: $full-width;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    @include mq($screen-m) {
        gap: 32px;
        flex-wrap: nowrap;
        justify-content: center;
    }
    a {
        display: block;
    }
    .category-sku__item {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 47%;
        @include mq($screen-m) {
            width: 100%;
        }
        h5 {
            color: $primary;
            font-family: $font-libre-bold;
            font-size: 1.6rem;
            line-height: 2.4rem;
            margin: 0.8rem 0;
            @include mq($screen-m) {
                font-size: 2.2rem;
                line-height: 4rem;
                margin: 0.8rem 0 1.6rem 0;
            }
            a {
                color: $primary;
                font-family: $font-libre-bold!important;
            }
        }
    }
    .category-sku__img {
        position: relative;
        &::after {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: -1px;
        right: -1px;
        width: 24px;
        height: 24px;
        background: url("../images/four-gifts-after.svg") center no-repeat;
        background-size: contain;
        @include mq($screen-m) {
            width: 48px;
            height: 48px;
        }
        }
        a {
            width: 100%;
        }
        img {
            width: 100%;
            aspect-ratio: 1/1;
        }
    }
}