.amasty_xlanding-page-view {
    .datefinder {
        &-date {
            @include mq($screen-l) {
                .datefinder-top {
                    width: 100%;
                }
                .datefinder-picker__full {
                    width: 70%;
                }
            }
            @include mq($screen-xlg) {
                .datefinder-top {
                    width: 30%;
                }
                .datefinder-picker__full {
                    width: 45%;
                }
            }
        }
        @include mq($screen-l) {
            .datefinder-button {
                width: 20%;
            }
        }
        &-top {
            width: unset;
        }
    }
    #maincontent.page-main {
        margin-top: 0;
    }
    .breadcrumbs {
        padding: 0;
        margin-left: 0;
    }
    .column.main,
    .page-layout-2columns-left .main {
        max-width: 100%;
    }
    .sidebar.sidebar-main {
        order: 2;
    }
    &.catalog-category-view {
        .category-wrapper {
            margin: 0;
        }
    }
    .landing-heading {
        &__title {
            display: none;
            margin: auto 0;

            h2 {
                color: $white;
                font-size: 28px;
                line-height: 60px;
                position: relative;
                text-align: center;
                font-family: $font-libre;
            }
        }
        &__image {
            width: 100%;
            img {
                height: auto;
                max-width: 100%;
                object-fit: cover;
                width: auto;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    h2 {
        @include h4;
        margin-top: 20px;
        font-weight: 700;
    }
    h2.landing-page__title {
        @include h3;
        margin-top: 0;
        font-weight: 700;
    }
    .gift-matcher {
        margin: 0 0 20px 0;

        &__input {
            min-width: 350px;
            max-width: initial;
        }
    }
    .amasty-xlanding-top-description,
    .amasty-xlanding-bottom-description {
        margin-top: 40px;
        font-size: 1.6rem;
        margin-bottom: 40px;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;

        .block-category-link, .block-product-link {
            font-size: 1.6rem;
            display: inline-block;

            a {
                color: $secondary;
            }
        }
        a {
            color: $secondary;
            font-weight: bold;
        }
        b, strong {
            font-family: $font-open-sans-bold;
        }
        ul {
            list-style: disc outside;
            margin-bottom: 1.5em;
            li {
                margin-left: 2em;
            }
        }
        p {
            line-height: 2;
            margin-bottom: 10px;
        }
    }
    .landing-quicksearch .quicksearch {
        width: auto;
        padding: 24px;
        display: block;
        box-shadow: none;
        position: initial;
        background: $beige1;

        &-heading {
            @include h4;
            color: $primary;
            font-weight: 700;
        }
        &-links {
            margin-left: 6px;
            padding: $margin-s / 2 0;

            a {
                @include t3;
                font-size: $font-size-md;
                color: $primary;
                display: block;
                padding-bottom: 15px;
                text-decoration: none;

                &:hover {
                    color: $black;
                    font-weight: 700;
                }
                &:before {
                    content: url('../images/icons/arrow-down-gold.svg');
                    width: 10px;
                    height: 10px;
                    transform: rotate(-90deg);
                    display: inline-block;
                    vertical-align: bottom;
                    margin-right: 15px;
                }
            }
        }
    }
}
@media screen and (min-width: $screen-m) {
    .amasty_xlanding-page-view {
        .main {
            flex: initial;
        }
        .columns {
            flex-direction: column;
        }
    }
}
@media screen and (min-width: $screen-l) {
    .amasty_xlanding-page-view {
        .columns {
            flex-direction: row;
        }
        &.page-layout-2columns-left {
            .column.main {
                max-width: calc(100% - 27%);
            }
            .sidebar.sidebar-main {
                order: initial;
                max-width: 23.5%;
            }
        }
        .landing-heading {
            display: flex;
            min-height: 300px;
        }
        .gift-matcher {
            flex-direction: column;

            &__input {
                margin: 5px 0;
            }
            &__button {
                margin-top: 5px;
            }
            &__heading {
                max-width: 500px;
            }
            &__form {
                margin: auto;
            }
            &__controls {
                @include flex(column, space-between);
                flex-wrap: wrap;
            }
            &__button {
                width: 100%;
            }
        }
    }
}
@media screen and (min-width: $screen-xl) {
    .amasty_xlanding-page-view {
        .landing-heading {
            &__title h2 {
                font-size: 44px;
            }
        }
        .gift-matcher {
            flex-direction: row;

            &__input {
                min-width: 130px;
                max-width: 150px;
                margin-right: 15px;
            }

            &__form {
                width: auto;
                margin: auto 0;
            }
            &__controls {
                margin-top: 0;
                max-width: 100%;
                flex-wrap: nowrap;
                flex-direction: row;

                * {
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
            &__heading {
                margin: auto 10px auto 0;
            }
            &__button {
                width: auto;
            }
        }
    }
}
