.cms.age-categories {
    margin: 3.2rem -1.6rem 2rem -1.6rem;
    order: 1;
    @include mq($screen-m) {
        margin: 3.2rem auto;
        order: 0;
        width: 100%;
    }
    &>div,
    &>p {
        @include flex(column, space-evenly);
        width: $full-width;
        padding: 0;
        flex-wrap: wrap;
        padding: $padding-m;
        background: #ce9f79;
        margin-bottom: 0;
        @include mq($screen-l) {
            @include flex(row, space-evenly);
            flex-wrap: nowrap;
            background: #F9F5F1;
            margin: 0;
            margin: 7.2rem auto 4.2rem auto;
            padding: 1.3rem 1.3rem 5.5rem 1.3rem;
        }
    }
    a.his-category-widget .item-button {
        bottom: -4.2rem;
    }

    a.his-category-widget {
        @include flex(column, flex-start);
        max-height: 90px;
        margin: $padding-m;
        position: relative;
        box-sizing: border-box;
        &:hover {
            text-decoration: none;
        }
        .item-title {
            width: $full-width;
            margin: 0;
            &.his-btn-primary {
                @include centerItem;
                height: 4rem;
                @include mq($screen-l) {
                    display: none;
                }
            }
        }
        .item-button {
            left: 0%;
            width: 100%;
            display: none;
            margin-bottom: 0;
            position: absolute;
            &::before,
            &::after {
                display: none;
            }
        }
        .item-image {
            display: none;
            max-height: 100%;
        }
    }
}

@media screen and (min-width: $screen-l) {
    .cms.age-categories {
        @include flex(row, space-evenly);
        flex-wrap: nowrap;

        a.his-category-widget {
            max-width: 25%;
            max-height: 100%;
            width: 25%;
            .item-title {
                display: none;
            }
            .item-button {
                display: block;
            }
            .item-image {
                display: block;
                width: $full-width;
                img {
                    width: $full-width;
                }
            }
        }
    }

}

