.cart-list-item {
    padding: 1rem 0;
    border-bottom: $border-gold;
    @include mq($screen-m) {
        justify-content: space-between;
    }
    &:first-child {
        border-top: $border-gold;
    }
    &__left {
        flex-direction: column;
        @include mq($screen-m) {
            flex-direction: row;
            flex-basis: 100%;
        }
        @include mq($screen-l) {
            flex-basis: 54%;
        }
        @include mq($screen-xl) {
            flex-basis: 64%;
        }
        @include mq($screen-xlg) {
            flex-basis: 60%;
        }
    }
    &__image {
        width: 25rem;
        height: 25rem;
        margin: 0 auto;
        @include mq($screen-m) {
            margin: 0 2rem 0 0;
        }
        .ratio-container {
            height: 100%;
        }
        .ratio-image {
            width: 100%;
            height: auto;
            max-width: none !important;
            object-fit: cover;
        }
    }
    &__content {
        padding: 0;
        margin: 2rem 0;
        @include mq($screen-m) {
            margin: 0;
            padding-top: 2.5rem;
        }
    }
    &__name {
        font-family: $font-libre-bold;
        font-size: $font-size-xxlarge;
        line-height: $line-height-extra-large;
        color: $primary;
        transition: $transition;
        &:hover {
            opacity: 0.6;
            color: $primary;
            text-decoration: none;
        }
    }
    &__attributes {
        background: $beige1;
        padding: 1.1rem;
    }
    &__attributes-list {
        .list__label {
            font-weight: $font-weight-normal;
            line-height: $line-height-normal;
            color: black;
            &:first-child {
                margin: 0 0 1.8rem 0;
            }
        }
        .list__value {
            font-family: $font-open-sans-bold;
            color: black;
            line-height: $line-height-normal;
        }
    }
    &__right {
        display: flex;
        flex-direction: column-reverse;
        margin: 0;
        padding: 0;
        @include mq($screen-m) {
            flex-basis: 100%;
            flex-direction: column-reverse;
            flex-wrap: nowrap;
            align-items: flex-end;
            height: 100%;
        }
        @include mq($screen-l) {
            flex-basis: 45%;
        }
        @include mq($screen-xl) {
            flex-basis: 35%;
        }
    }
    &__actions {
        justify-content: flex-end;
        align-items: center;
    }
    &__action {
        width: $checkout-button-size;
        height: $checkout-button-size;
        min-width: $checkout-button-size;
        min-height: $checkout-button-size;
        border: $border-gold;
        padding: 0;
        margin: 0 0 0 1.6rem;
        &:hover {
            background: $secondary;
        }
        .cart-list-item__action-icon {
            width: $checkout-icon-size;
            height: $checkout-icon-size;
            padding: 0;
            fill: $secondary;
        }
    }
    &__details {
        @include flex(row, space-between);
        align-items: center;
        @include mq($screen-m) {
            width: 100%;
        }
    }
    &__label {
        font-size: 0;
    }
    &__price {
        font-family: $font-open-sans-condensed;
        font-size: $font-size-xxlarge;
    }
    &__total {
        font-family: $font-open-sans-condensed;
        font-size: $font-size-xxlarge;
        color: $primary;
    }
    &__input {
        width: $checkout-quantity-size;
        height: $checkout-quantity-size;
        border: $border-gold-bold;
        padding: 0;
        font-family: $font-open-sans-bold;
        font-size: $font-size-normal;
        color: $secondary;
        margin: 0 0.8rem;
        -moz-appearance: textfield;
        &-update {
            width: $checkout-quantity-size;
            height: $checkout-quantity-size;
            min-width: $checkout-quantity-size;
            min-height: $checkout-quantity-size;
            border: $border-gold-bold;
            margin: 0;
            &:hover,
            &:focus {
                background-color: $secondary;
                opacity: 0.5;
            }
            .quantity-update__icon {
                fill: $secondary;
                width: 1.4rem;
                height: 1.4rem;
                &:hover,
                &:focus {
                    fill: $secondary;
                    opacity: 0.5;
                }
            }
        }
    }
    &__buttons {
        display: flex;
        position: relative;
        div.mage-error {
            position: absolute;
            top: 100%;
            font-size: 10px;
        }
    }
    &__wrapper {
        @include mq($screen-m) {
            flex-basis: unset;
        }
    }
}
