.catalog-category-view,
.catalogsearch-result-index {
    .filter-options-title {
        @include h4;
        color: $primary;
        font-weight: 700;
        margin-bottom: 20px;
    }
    .filter-options-content {
        .items {
            margin: 0;
            .item {
                padding-bottom: 20px;

                &__checkbox {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                    border-radius: 2px;
                    border: 2px solid $secondary;
                }
                a,
                .filter-label,
                .filter-value {
                    @include t3;
                    text-decoration: none;
                }
                .count {
                    opacity: 0.25;
                }
                .price {
                    padding-right: 4px;
                }
            }
        }
    }
    .filter-category-content {
        background-color: $beige1;
        padding: 24px 24px 0;
        margin: 20px 0;
    }

    .filter-current.filter-options-content {
        .items .item {
            display: flex;
            &__checkbox {
                border: 2px solid $primary;
                background: $primary;

                &:hover {
                    text-decoration: none;
                }
                &::after {
                    width: 18px;
                    height: 18px;
                    content: "\2713";
                    color: $white;
                    line-height: 0.6;
                    margin-left: 1px;
                    font-size: 2.5rem;
                }
            }
        }
        .filter-label {
            font-weight: 700;
            color: $primary;
        }
        .filter-label,
        .filter-value {
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    @media screen and (min-width: $screen-m) {
        .filter-category-content {
            margin-top: 0;
        }
    }
}
