.product-item-info {
    position: relative;
    &__label {
        position: absolute;
        left: -32px;
        top: 24px;
        z-index: 10;
        width: 140px;
        height: 27px;
        font-family: $font-open-sans-condensed;
        font-size: 1.6rem;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        color: $white;
        line-height: 17px;
        text-align: center;
        padding: 0.5rem 0;
        background: $primary;
        transform: rotate(-45deg);
        clip-path: polygon(19% 0%, 80% 0%, 99% 100%, 0% 100%);
        ::after,
        ::before {
            content: "\2605";
            width: 10px;
            height: 10px;
        }
    }
    &__mark {
        top: -1px;
        z-index: 1;
        right: 15px;
        width: 34px;
        height: 47px;
        position: absolute;
        padding: 0 0 0.3rem 0;
        background: $secondary;
        clip-path: polygon(
            100% 0,
            100% 100%,
            50% 75%,
            0% 100%,
            0 50%,
            0% 0%
        );

        svg {
            width: 100%;
            height: 100%;
        }
    }
}
