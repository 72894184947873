.man-woman-gifts {
    position: relative;
    // padding: 40px 0;
    @include mq($screen-m) {
        // padding: 100px 0;
    }
    a {
        width: 100%;
    }
    h2 {
        margin-bottom: 2rem!important;
        @include mq($screen-m) {
            margin-bottom: 5rem!important;
        }
    }
    &__list {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: $full-width;
        gap: 32px;
        @include mq($screen-l) {
            flex-direction: row;
        }
    }
    &__list-item {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        background-color: $primary;
        width: 100%;
        position: relative;
        padding: 16px 50px 16px 80px;
        border-radius: 77px 0px 0px 77px;
        @include mq($screen-m) {
            padding: 54px 60px 54px 170px;
            border-radius: 86px 0px 0px 86px;        
        }
        &::after {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            position: absolute;
            right: 25px;
            top: 50%;
            transform: translate(0,-50%);
            background: url('../images/man-woman-after.svg') center no-repeat;
            background-size: contain;
            @include mq($screen-m) {
                width: 40px;
                height: 40px;       
            }
        }
        
        h5 {
            margin: 0;
            color: $white;
            font-family: $font-libre-bold;
            font-size: 1.6rem;
            line-height: 2.4rem;
            @include mq($screen-m) {
                flex-direction: row;
                font-size: 2.5rem;
                line-height: 3.2rem;
            }
        }
    }
    &__icon {
        position: absolute;
        left: 0;
        top: 50%;
        width: auto;
        height: auto;
        border: 3px solid #fff;
        border-radius: 50%;
        display: inline-block;
        overflow: hidden;
        transform: translate(0, -50%);
        // max-width: 70px;
        @include mq($screen-m) {
            flex-direction: row;
            max-width: 100%;
            border: 5px solid #fff;

        }
        img {
            width: 70px;
            height: 70px;
            @include mq($screen-m) {
                width: 160px;
                height: 160px;
            }
        }
    }
}



// ========== styles for using sku widget ==========
// div[data-content-type="row"]:has(.man-woman-gifts-block) {
//     padding: 40px 15px;
//     @include mq($screen-m) {
//         padding: 100px 15px;
//     }
// }  
.man-woman-gifts-block {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: $full-width;
    gap: 32px;
    @include mq($screen-l) {
        flex-direction: row;
    }
    a {
        width: 100%;
        display: block;
    }
    h2 {
        margin-bottom: 2rem!important;
        @include mq($screen-m) {
            margin-bottom: 5rem!important;
        }
    }
    div:has(.category-sku__item) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: $full-width;
        gap: 32px;
        @include mq($screen-m) {
            flex-direction: row;
        }
    }
    .category-sku__item {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        background-color: $primary;
        width: 100%;
        position: relative;
        padding: 16px 50px 16px 80px;
        border-radius: 77px 0px 0px 77px;
        @include mq($screen-m) {
            padding: 54px 60px 54px 170px;
            border-radius: 86px 0px 0px 86px;        
        }
        &::after {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            position: absolute;
            right: 25px;
            top: 50%;
            transform: translate(0,-50%);
            background: url('../images/man-woman-after.svg') center no-repeat;
            background-size: contain;
            @include mq($screen-m) {
                width: 40px;
                height: 40px;       
            }
        }
        
        h5 {
            margin: 0;
            color: $white;
            font-family: $font-libre-bold;
            font-size: 1.6rem;
            line-height: 2.4rem;
            @include mq($screen-m) {
                flex-direction: row;
                font-size: 2.5rem;
                line-height: 3.2rem;
            }
            a {
                color: $white;
                font-family: $font-libre-bold!important;
            }
        }
    }
    .category-sku__img {
        position: absolute;
        left: 0;
        top: 50%;
        width: auto;
        height: auto;
        border: 3px solid #fff;
        border-radius: 50%;
        display: inline-block;
        overflow: hidden;
        transform: translate(0, -50%);
        @include mq($screen-m) {
            flex-direction: row;
            max-width: 100%;
            border: 5px solid #fff;
        }
        img {
            width: 70px;
            height: 70px;
            @include mq($screen-m) {
                width: 160px;
                height: 160px;
            }
        }
    }
}