.pagebuilder-column-line:has(.original-gift-top) {
    position: relative;
    @include mq($screen-m) {
        padding-bottom: 0;
    }
    .pagebuilder-column .pagebuilder-button-primary {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 25px;
        width: 100%;
        @include mq($screen-m) {
            position: static;
            width: auto;
        }
    }
}
.original-gift-top {
    position: relative;
    &__list {
        display: flex;
        width: $full-width;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 15px;
        @include mq($screen-m) {
            gap: 32px;
            flex-wrap: nowrap;
        }
        @include mq($max-screen: $screen-s) {
            width: auto;
            margin-right: -15px;
            justify-content: flex-start;
            overflow-x: scroll;
            flex-wrap: nowrap;          
        }
        &::-webkit-scrollbar {
            display: none;
        }
    }
    a {
        width: 50%;
        @include mq($screen-m) {
            width: 100%;
        }
        @include mq($max-screen: $screen-s) {
            min-width: 63%;
        }
    }
    &__list-item {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        position: relative;
        h5 {
            color: $black;
            text-align: center;
            font-family: $font-open-sans;
            font-size: 1.6rem;    
            line-height: 2.2rem;
            margin-bottom: 10px;
            margin: 1.6rem 0 2rem 0;
            @include mq($screen-m) {
                font-size: 2rem;
                line-height: 2.4rem;
                margin: 1.6rem 0 3.8rem 0;
            }
        }
    }
    &__icon {
        position: relative;
        max-width: 100%;
        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 8px;
            width: 24px;
            height: 41px;
            background: url("../images/slider-item-pointer.svg") center no-repeat;
        }
        img {
            width: 100%;
            aspect-ratio: 1/1;
        }
    }
}
// div[data-content-type="row"]:has(.original-gift-top) {
//     padding: 25px 15px;
//     @include mq($screen-s) {
//         padding: 50px 30px;
//     }
//     @include mq($screen-xxl) {
//         padding: 100px 15px;
//     }
// }



// ========== styles for using sku widget ==========
div.original-gift-top:has(.product-sku__item) {
    position: relative;
    display: flex;
    width: $full-width;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    @include mq($screen-m) {
        gap: 32px;
        flex-wrap: nowrap;
    }
    @include mq($max-screen: $screen-s) {
        width: auto;
        margin-right: -15px;
        justify-content: flex-start;
        overflow-x: scroll;
        flex-wrap: nowrap;          
    }
    &::-webkit-scrollbar {
        display: none;
    }
    div.product-sku__item {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        min-width: 63%;
        @include mq($screen-m) {
            min-width: auto;
            width: 50%;
        }
        .product-sku__title {
            color: $black;
            text-align: center;
            font-family: $font-open-sans;
            font-size: 1.6rem;    
            line-height: 2.2rem;
            margin-bottom: 10px;
            margin: 1.6rem 0 2rem 0;
            @include mq($screen-m) {
                font-size: 2rem;
                line-height: 2.4rem;
                margin: 1.6rem 0 3.8rem 0;
            }
            a {
                font-weight: 400;
                color: $black;
            }
        }
    }
    .product-sku__image {
        position: relative;
        max-width: 100%;
        width: 100%;
        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 8px;
            width: 24px;
            height: 41px;
            background: url("../images/slider-item-pointer.svg") center no-repeat;
        }
        a {
            width: 100%;
        }
        img {
            width: 100%;
            aspect-ratio: 1/1;
        }
    }
}



div.original-gift-top:has(.category-sku__item) {
    position: relative;
    display: flex;
    width: $full-width;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    @include mq($screen-m) {
        gap: 32px;
        flex-wrap: nowrap;
    }
    @include mq($max-screen: $screen-s) {
        width: auto;
        margin-right: -15px;
        justify-content: flex-start;
        overflow-x: scroll;
        flex-wrap: nowrap;          
    }
    &::-webkit-scrollbar {
        display: none;
    }
    div.category-sku__item {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        position: relative;
        min-width: 63%;
        @include mq($screen-m) {
            min-width: auto;
            width: 50%;
        }
        h5 {
            color: $black;
            text-align: center;
            font-family: $font-open-sans;
            font-size: 1.6rem;    
            line-height: 2.2rem;
            margin-bottom: 10px;
            margin: 1.6rem 0 2rem 0;
            @include mq($screen-m) {
                font-size: 2rem;
                line-height: 2.4rem;
                margin: 1.6rem 0 3.8rem 0;
            }
            a {
                font-weight: 400;
                color: $black;
            }
        }
    }
    .category-sku__img {
        position: relative;
        max-width: 100%;
        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 8px;
            width: 24px;
            height: 41px;
            background: url("../images/slider-item-pointer.svg") center no-repeat;
        }
        a {
            width: 100%;
        }
        img {
            width: 100%;
            aspect-ratio: 1/1;
        }
    }
}