.page-layout-landing-pages-2-col,
.page-layout-landing-pages-3-col {
    .collaps-block {
        p {
            font-size: 1.4rem;
            line-height: 2rem;
            margin-bottom: 1rem;
            text-align: justify;
            @include mq($screen-m) {
                font-size: 1.8rem;
                line-height: 2.8rem;
                margin-bottom: 1.5rem;
            }
        }
        ol {
            padding-left: 24px;
            margin: 16px 0;
            @include mq($screen-m) {
                margin: 16px 0 32px 0;
            }
        }
        li {
            display: list-item;
            padding-left: 10px;
            font-size: 1.4rem;
            line-height: 2rem;
            margin-bottom: 15px;
            text-align: justify;
            @include mq($screen-m) {
                font-size: 1.8rem;
                line-height: 2.8rem;
            }
            &::marker {
                color: $primary;
                font-family: $font-libre-bold;
                font-weight: 700;
                font-size: 1.4rem;
                line-height: 2rem;
                @include mq($screen-m) {
                    font-size: 1.8rem;
                    line-height: 2rem;
                }
            }
        }
        &.collaps-block-ln3 {
            height: 60px!important;
            @include mq($screen-m) {
                height: 90px!important;
            }
        }
        &.collaps-block-ln5 {
            height: 115px!important;
            @include mq($screen-m) {
                height: 145px!important;
            }
        }
        &.collaps-block-ln7 {
            height: 155px!important;
            @include mq($screen-m) {
                height: 200px!important;
            }
        }
        &.collapsed-block {
            height: auto!important;
        }
    }
    .show-more-link-container {
        position: relative;
        margin-top: 1.6rem;
        &::before {
            position: absolute;
            top: -5.5rem;
            left: 0;
            right: 0;
            content: "";
            height: 4rem;
            background: #f5e6d3;
            background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#f5e6d3));
            background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), #f5e6d3);
            background: linear-gradient(180deg, rgba(255, 255, 255, 0), #f5e6d3);
        }
    }
    .show-more-link-container:has(.collapsed) {
        &::before {
            display: none;
        }
    }
    .show-more-link {
        background: none;
        border: none;
        position: relative;
        color: $primary;
        font-weight: 400;
        text-transform: none;
        min-height: auto;
        margin: 0;
        padding: 0;
        font-family: $font-open-sans;
        font-size: 1.4rem;
        line-height: 2.2rem;
        // margin-left: 30px;
        @include mq($screen-m) {
            font-size: 1.8rem;
            line-height: 2.8rem;
        }
        &::after {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: -17px;
            background: url('../images/show-more-after.svg') center no-repeat;
            background-size: contain;
            @include mq($screen-m) {
                width: 24px;
                height: 24px;
                right: -25px;
            }
        }
    }
    .show-more-link.collapsed {
        &::after {
            transform: translate(0, -50%) rotate(180deg); 
        }
    }

    .collaps-white ~ .show-more-link-container {
        &::before {
            background: #fff;
            background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#fff));
            background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), #fff);
            background: linear-gradient(180deg, rgba(255, 255, 255, 0), #fff);
        }
    }
}