.order-summary {
    background-color: $beige1;
    padding: 1.6rem;
    margin: 0 0 3.2rem 0;
    .summary.title {
        display: none;
    }
    &__title {
        font-family: $font-libre-bold;
        font-size: $font-size-xxlarge;
        line-height: 4.5rem;
        color: $primary;
        &-total {
            font-family: $font-open-sans-bold;
            font-size: $font-size-normal;
            color: black;
        }
    }
    &__amount {
        font-family: $font-open-sans-condensed;
        font-size: $font-size-xxlarge;
        font-weight: $font-weight-bold;
        color: $primary;
    }
    &__subtitle {
        font-family: $font-open-sans-bold;
        font-size: $font-size-normal;
        color: black;
        line-height: 1.2rem;
        svg {
            fill: $primary;
        }
    }
    &__label {
        font-family: $font-open-sans-bold;
        font-size: $font-size-normal;
        line-height: 1.8rem;
    }
    &__value {
        font-family: $font-open-sans-condensed;
        font-size: $font-size-lg;
    }
    &__divider {
        display: none;
    }
    &__list {
        border: none;
        padding: 0;
        &-item {
            min-height: auto;
            &:not(:first-child):not(:last-child) {
                padding: 0 1.6rem;
                margin: 0 -1.6rem;
            }
            &:first-child {
                .order-summary__label,
                .order-summary__value {
                    font-size: 1.8rem;
                    line-height: 2.2rem;
                    margin-bottom: 10px;
                }
                .order-summary__value {
                    color: $primary;
                }
            }
            &.before-discount,
            &.subtotal-suma {
                .order-summary__label,
                .order-summary__value {
                    font-size: 1.8rem;
                    line-height: 2.2rem;
                    margin-bottom: 10px;
                }
                .order-summary__value {
                    color: $primary;
                }
            }
            &.hide {
                display: none!important;
            }
            &.total-suma {
                .total-suma-title {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    font-size: 1.4rem;
                }
                .order-summary__title-total {
                    font-size: 2rem;
                    line-height: 2.4rem;
                    margin-right: 3px;
                }
                // .taxes-notes {
                //     font-size: 1.2rem;
                //     line-height: 2rem;
                // }
            }
        }
        
    }
    button {
        &.button.checkout {
            @include btn-primary;
            &:hover,
            &:focus {
                @include btn-primary-hover;
                &::after {
                    display: none;
                }
            }
        }
    }
    #block-shipping {
        display: none;
    }
}

.checkout-agreement {
    padding-top: 20px;
}
