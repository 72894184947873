.sales-order-view,
.sales-order-shipment,
.sales-order-invoice,
.sales-order-creditmemo,
.sales-order-print {
    #maincontent {
        background: $white;
        margin: 0;
        @include mq($screen-xl) {
            margin: 0 auto;
        }
        .row {
            padding: 0 1em;
            margin: 0;
            @include mq($screen-xl) {
                max-width: $container-desktop-width;
                margin: 0 auto;
            }
            @include mq($screen-xlg) {
                padding: 0;
            }
        }
    }
    .heading--page {
        font-family: $font-libre-bold;
        color: $black;
        text-align: left;
        text-transform: none;
    }
    .dashboard__content {
        @include mq($screen-l) {
            width: 64%;
        }
        @include mq($screen-xl) {
            margin: 12rem 0 0 2rem;
        }
        @include mq($screen-xlg) {
            width: 74%;
        }
    }
    .dashboard-items__subtitle {
        margin: 0;
        &.sales-order__payment-method {
            margin: $margin-m 0 0 0;
        }
    }
    .dashboard-items__item {
        @include mq($screen-m) {
            width: 33%;
        }
    }
    .ratio-container {
        width: 10rem;
        height: 10rem;
        @include mq($screen-m) {
            width: 4.6rem;
            height: 4.6rem;
        } 
    }
    .table {
        border: none;
        margin: 0;
        th {
            font-family: $font-open-sans-condensed;
            font-size: $font-size-normal;
            font-weight: $font-weight-bold;
            color: $secondary;
            background: none;
            border: none;
            &:first-child {
                @include mq($screen-m) {
                    width: 7.8rem;
                }
            }
            &:last-child {
                text-align: right;
                padding: $padding-normal 0 $padding-normal $padding-normal;
            }
        }
        tr {
            border-bottom: none;
        }
        td {
            font-family: $font-open-sans-condensed;
            font-weight: $font-weight-bold;
            font-size: $font-size-lg;
            border: none;
            padding: $padding-sm $padding-normal;
            img {
                width: 10rem;
                height: auto;
                @include mq($screen-m) {
                    width: 4.6rem;
                }            
            }
            &[data-th="Product Name"] {
                font-family: $font-libre-bold;
                font-size: $font-size-md;
                color: $primary;
            }
            &[data-th="Subtotal"] {
                padding: $padding-small $padding-normal;
                @include mq($screen-m) {
                    padding: $padding-normal 0 $padding-normal $padding-normal;
                }  
            }
            &::before {
                font-family: $font-open-sans-condensed;
                font-size: $font-size-normal;
                font-weight: $font-weight-bold;
                color: $secondary;
            }
            &:last-child {
                @include mq($screen-m) {
                    text-align: right;
                }
            }
        }
        tfoot {
            tr {
                @include flex(row, flex-end);
                align-items: center;
                border-bottom: none;
                padding: $padding-small 0;
                @include mq($screen-m) {
                    display: table-row;
                }
                td {
                    padding: 0;
                    @include mq($screen-m) {
                        border-right: none;
                        border-bottom: none;
                    }
                    &.mark {
                        font-family: $font-open-sans-bold;
                        font-size: $font-size-normal;
                        text-align: left;
                        @include mq($screen-m) {
                            text-align: right;
                        }
                    }
                    &.amount {
                        width: 10rem;
                        text-align: right;
                    }
                }
                &.subtotal {
                    .mark,
                    .amount {
                        padding: $padding-md 0 0 0;
                        @include mq($screen-m) {
                            border-top: $border-beige;
                        }
                    }
                }
                &.grand_total {
                    .price {
                        @include price($font-size-xlg, $primary);
                        line-height: $line-height-large;
                    }
                }
            }
        }
    }
    .actions-group {
        margin: $margin-xlarge 0;
    }

}
.dashboard {
    &__status {
        border: none;
        padding: 0;
    }
    &__status-span {
        font-family: $font-open-sans-bold;
    }
    &__order-date {
        font-size: $font-size-normal;
        margin: $margin-s 0;
        span {
            font-weight: $font-weight-normal;
        }
        date {
            font-weight: $font-weight-bold;
        }
    }
}
.sales-order {
    &__nav {
        @include flex(row, center);
        align-items: center;
        width: $full-width;
        border-bottom: $border-beige;
        margin: $margin-m 0;
        flex-wrap: wrap;
        text-align: center;
        @include mq($screen-m) {
            margin: $margin-m 0 0 0;
            flex-wrap: nowrap;
        }
        li {
            font-family: $font-open-sans-bold;
            font-size: $font-size-md;
            color: $secondary;
            line-height: $line-height-extra-large;
            padding: 0 $padding-medium;
            width: 50%;
            &.nav.item {
                a {
                    color: $secondary;
                    font-family: $font-open-sans-bold;
                }
            }
            &.current {
                color: $primary;
                border-bottom: $border-red;
            }
        }
    }
    &__buttons {
        a {
            font-family: $font-open-sans;
            font-size: $font-size-lg;
            color: $primary;
            text-decoration: none;
            &:hover,
            &:focus {
                color: $primary-hover-darker;
            }
        }
    }
    &__back {
        @include btn-primary;
        font-family: $font-open-sans-condensed;
        font-weight: $font-weight-bold;
        font-size: $font-size-normal;
        text-transform: uppercase;
        color: $white;
        padding: $padding-sm $padding-medium;
        &:hover,
        &:focus {
            @include btn-primary-hover;
            color: $white;
            text-decoration: none;
        }
    }
    &__payment-method {
        margin: $margin-m 0 0 0;
    }
}
.sales-order-shipment,
.sales-order-invoice,
.sales-order-creditmemo {
    .dashboard-table__header {
        background: transparent;
        padding: 0 0 $padding-normal 0;
        border: none;
        border-bottom: $border-beige;
        @include mq($screen-m) {
            padding: $padding-normal 0;
        }
    }
    .dashboard-table__link {
        color: $primary;
        text-decoration: none;
        padding: 0;
    }
    .dashboard-items__subtitle {
        @include mq($screen-xl) {
            margin: $margin-xlg 0 0 0;
        }
    }
    .table {
        table-layout: fixed;
        .image {
            img {
                width: 4.6rem;
            }
        }
        .th-name {
            @include mq($screen-m) {
                width: 60%;
            }
            @include mq($screen-xlg) {
                width: 70%;
            }
        }
    }
}
.sales-order-invoice {
    .table {
        .th-name {
            @include mq($screen-m) {
                width: 45%;
            }
        }
        .th-subtotal {
            @include mq($screen-m) {
                width: 12rem;
            }
        }
    }
}
.sales-order-creditmemo {
    .table {
        .row {
            margin: 0;
        }
    }
}
.sales-order-print {
    #maincontent {
        padding: 0 1rem;
        @include mq($screen-xlg) {
            padding: 3rem;
        }
    }
    .heading--page {
        color: $primary;
        text-align: left;
    }
    .table {
        thead {
            tr {
                th {
                    &:first-child {
                        display: none;
                    }
                }
            }
        }
        td {
            padding: $padding-xs;
            &[data-th="Image"] {
                display: none;
            }
            &[data-th="Subtotal"] {
                padding: $padding-small $padding-xs;
            }
        }
    }
    .information {
        @include mq($screen-m) {
            margin: $margin-xlg 0;
        }
    }
}
.sales-order-printcreditmemo {
    #maincontent {
        width: $full-width;
        margin: 0 auto;
        padding: 0 1rem;
        background: $white;
        @include mq($screen-xlg) {
            padding: 3rem;
        }
    }
    .heading--page {
        font-family: $font-libre-bold;
        color: $black;
        text-align: left;
        text-transform: none;
        width: 80%;
        display: inline-block;
        vertical-align: middle;
    }
    .order-status {
        display: inline-block;
        padding: 0;
        border: none;
        border-radius: 0;
        font-size: 12px;
        vertical-align: middle;
    }
    .dashboard-table {
        margin: $margin-xlg 0;
        @include mq($screen-m) {
            margin: $margin-xlg 0 0 0;
        }
    }
    .dashboard-table__header {
        background: transparent;
        border-bottom: $border-beige;
        padding: $padding-normal 0;
        @include mq($screen-m) {
            margin: 0;
        }
    }
    .table {
        border: none;
        .image {
            display: none;
        }
        th {
            background: transparent;
            border: none;
            font-family: $font-open-sans-condensed;
            font-weight: $font-weight-bold;
            font-size: $font-size-normal;
            color: $secondary;
            padding: $padding-normal 0;
            border-bottom: $border-gold;
            &:last-child {
                @include mq($screen-m) {
                    text-align: right;
                }
            }
        }
        td {
            font-family: $font-open-sans-condensed;
            font-weight: $font-weight-bold;
            font-size: $font-size-normal;
            border: none;
            padding: $padding-sm 0;
            &::before {
                display: block;
                font-family: $font-open-sans-condensed;
                font-weight: $font-weight-bold;
                font-size: $font-size-normal;
                color: $secondary;
                content: attr(data-th);
                margin-right: $margin-small;
                max-width: 25%;
                width: 25%;
                min-width: 25%;
                @include mq($screen-m) {
                    display: none;
                }
            }
            &[data-th="Product Name"] {
                font-family: $font-libre-bold;
                font-size: $font-size-md;
                color: $primary;
            }
            &[data-th="Total"] {
                @include mq($screen-m) {
                    text-align: right;
                }
            }
        }
        tr {
            border-bottom: $border-beige;
        }
        tfoot {
            tr {
                padding: 0;
                border: none;
                .amount {
                    width: 8rem;
                    font-size: $font-size-lg;
                    text-align: right;
                }
            }
            td {
                @include mq($screen-m) {
                    text-align: right;
                }
            }
        }
    }
    .dashboard-items__content {
        @include mq($screen-m) {
            margin: $margin-s 0 0 0;
        }
    }
    .dashboard-items__subtitle {
        @include mq($screen-m) {
            margin: 0;
        }
    }
    .dashboard-items__item {
        @include mq($screen-m) {
            margin: 0;
            width: 32%;
        }
    }
}
.sales-order-history {
    .dashboard__sidebar {
        @include mq($screen-m) {
            padding: 0 0.5rem;
        }
    }
    .dashboard__content {
        padding: 0 0.5rem;
    }
}
