.header-top-bar {
    width: $full-width;
    background: $gray7;
    &__container {
        @include flex(row, space-between);
        align-items: center;
        width: $full-width;
        max-width: $container-desktop-width;
        margin: 0 auto;
        padding: $padding-small $padding-s;
        @include mq($screen-l) {
            @include flex(row, flex-start);
        }
    }
    &__promotional-banner {
        // @include mq($screen-l) {
        //     display: flex;
        // }
        display: none;
        // @include flex(row, space-between);
        align-items: center;
        width: $full-width;
        max-width: $container-desktop-width;
        margin: 0 auto;
        padding: $padding-small $padding-s;
        @include mq($screen-l) {
            @include flex(row, flex-start);
        }

        position: absolute;
        bottom: calc(-100% - 23px);
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center!important;
        width: 100%;
        background-color: #E9C7A4;
        border: none;
        font-family: $message__font-family;
        transition: $message__transition;
        padding: 0;
        margin: 0;
        z-index: -1;
        color: $primary;
        font-size: 16px;
        font-weight: 600;

        p, span, div {
            line-height: 40px;
            margin: 0;
            color: $primary;
            font-weight: 600;
        }
    }

    &__promotional-banner.promotional-banner_mobile {
        display: flex;
        position: static;
        z-index: 1;

        @include mq($screen-l) {
            display: none;
        }

        p, span, div {
            line-height: 25px;
            padding: 10px;
        }
    }
}
body.catalog-product-view {
    .header-top-bar {
        &__container {
            display: none;
            @include mq($screen-m) {
                @include flex(row, flex-start);
            }
        }
    }
}
.header-contact {
    @include flex(row, flex-start);
    align-items: center;
    &__icon {
        width: 2.1rem;
        height: 2.1rem;
        object-fit: contain;
        margin-right: 5px;
    }
    &__link {
        transition: $transition;
        &:hover,
        &:focus {
            opacity: 0.6;
            text-decoration: none;
        }
        img {
            margin: 0 $margin-root 0 0;
        }
    }
    &__text {
        @include t3;
        margin: 0;
    }
}
.header-multistore {
    @include mq($screen-m) {
        margin: 0;
    }
    @include mq($screen-l) {
        margin: 0 auto;
        transform: translateX(-10rem);
    }
    &__link {
        @include flex(row, flex-start);
        align-items: center;
        transition: $transition;
        &:hover,
        &:focus {
            filter: grayscale(1);
            text-decoration: none;
        }
    }
    &__image {
        width: 7.2rem;
        height: 3.8rem;
        object-fit: contain;
        margin: 0;
        @include mq($screen-m) {
            margin-right: 10px;
        }
        @include mq($screen-l) {
            margin-right: 10px;
        }
    }
    span {
        display: none;
        font-family: $font-open-sans-bold;
        font-size: $font-size-normal;
        color: $primary;
        @include mq($screen-l) {
            display: inline-block;
        }
    }
}
.header-switcher {
    &__location {
        position: absolute;
        top: $margin-xlarge;
        right: 0;
        @include flex(column, center);
        align-items: center;
        width: $width-30;
        background: $beige1;
        z-index: 100;
        padding: $padding-normal;
        border: $border-gold-bold;
        .list {
            width: $full-width;
        }
    }
    &__regions {
        margin: $margin-normal 0 0 0;
        h2 {
            @include heading2($black);
        }
    }
    &__locations {
        column-count: 2;
        column-gap: 2rem;
        a {
            font-family: $font-open-sans;
            font-size: $font-size-normal;
            line-height: $line-height-small;
            color: $primary;
            &:hover,
            &:focus {
                color: $primary-hover-darker;
                text-decoration: none;
            }
        }
    }
    &__languages {
        h2 {
            @include heading2($black);
        }
    }
}
.dropdown-list--detailed-content {
    &.header-switcher {
        background-color: transparent;
        position: relative;
        width: auto;
        .dropdown-list__label {
            padding: 0;
            font-size: 0;
            width: 5rem;
            height: 5rem;
            display: block;
            margin: 0;
            background: transparent url('../images/icons/historia@3x.svg') no-repeat right center;
            transition: $transition;
            text-decoration: none;
            &:hover,
            &:focus {
                opacity: 0.6;
                background-color: transparent;
            }
        }
    }
}
.language-switcher {
    width: $full-width;
    &__list {
        @include flex(row, flex-start);
        width: $full-width;
        flex-wrap: wrap;
    }
    &__list-item {
        position: relative;
        width: 1.6rem;
        height: 1.2rem;
        margin: 0 $margin-normal 0 0;
    }
    &__link {
        position: absolute;
        top: 0;
        left: 0;
        width: $full-width;
        height: $full-width;
        background-repeat: no-repeat;
        background-size: contain;
        transition: $transition;
        &:hover,
        &:focus {
            opacity: 0.6;
        }
        span {
            font-size: 0;
        }
    }
}
