.quick-search {
    position: relative;
    // padding: 40px 0;
    margin-right: -15px;
    @include mq($screen-m) {
        // padding: 100px 0;
        margin-right: 0;
    }
    h2 {
        margin-bottom: 2rem!important;
        @include mq($screen-m) {
            margin-bottom: 4rem!important;
        }
    }
    &__title {
        text-align: center;
        margin-bottom: 5rem;
    }
    &__list {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        overflow-x: scroll;
        flex-wrap: nowrap;
        width: $full-width;
        gap: 1rem;
        margin-right: -15px;
        @include mq($screen-m) {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            margin-right: 0;
            gap: 2rem;
        }
        &::-webkit-scrollbar { 
            display: none;
        }
    }
    &__list-item {
        a {
            padding: 8px 48px 8px 18px;
            align-items: center;
            gap: 24px;
            border-radius: 44px;
            background: #F9F5F1;
            position: relative;
            width: max-content;
            &::after {
                content: '';
                display: inline-block;
                width: 16px;
                height: 16px;
                position: absolute;
                right: 18px;
                top: 50%;
                transform: translate(0,-50%);
                background: url('../images/quick-search-after.svg') center no-repeat;
            }
        }
        h5 {
            color: $black;
            font-family: $font-open-sans;
            font-size: 1.6rem;
            line-height: 3.2rem;
            margin: 0;
            @include mq($screen-m) {
                font-size: 1.8rem;
            }
        }
    }
}