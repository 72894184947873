.price__value {
    @include price(2.5rem, $primary);
    text-align: right;
    padding: 0;
    &.price__value--old {
        @include price($font-size-large, $gray3);
    }
}
.product-options-wrapper {
    width: $full-width;
    background: $beige1;
    margin: 1.6rem 0;
    padding: 2.5rem;
    position: relative;
}
.product-options-wrapper > .field.required {
    @include divider;
    margin: 0 0 2rem 0;
    position: relative;
    &::before {
        content: "";
        width: 1.7rem;
        height: 2.4rem;
        background: url(../images/pen.png) no-repeat;
        background-size: contain;
        position: absolute;
    }
    & > label {
        margin: 0 0 1.8rem 2.5rem;
    }
}

.product-options-wrapper > .field.required ~ .field.required {
    border-bottom: none;
    margin: 0;
    &::before {
        content: none;
    }
    & > label {
        margin: 0;
    }
}


.label {
    font-family: $font-open-sans-bold;
    font-size: $font-size-normal;
    color: black;
}
.control {
    margin: 1rem 0 0 0;
}

.product-options-wrapper {
    .input {
        &.field {
            @include mq($screen-xl) {
                width: 45%;
                padding-right: 5px;
            }
        }
    }
}

.input {
    &.field {
        @include mq($screen-sm) {
            display: inline-block;
            width: 46%;
            margin: 0 2rem 1.6rem 0;
        }
        @include mq($screen-m) {
            width: $full-width;
        }
        @include mq($screen-xl) {
            width: 43%;
        }
        &.textarea {
            width: $full-width;
        }
    }
}
.select--native {
    min-width: $full-width;
    width: $full-width;
    select {
        border: $border-gold;
        @include mq($screen-m) {
            min-width: 295px;
            width: 295px;
        }
        option {
            font-family: $font-open-sans;
            font-size: $font-size-normal;
            &:first-child {
                font-style: italic;
                color: $gray2;
            }
        }
    }
}
.control {
    >.select__field {
        @include mq($screen-m) {
            min-width: 295px;
            width: 100%;
        }
        option {
            font-family: $font-open-sans;
            font-size: $font-size-normal;
            &:first-child {
                font-style: italic;
                color: $gray2;
            }
        }
    }
    .select2-container {
        .select2-selection--single {
            border: 1px solid $secondary!important;
            .select2-selection__rendered {
                font-size: 1.2rem;
                color: #bfbfbf;
                font-family: $font-open-sans-bold;
            }
            .select2-selection__arrow b {
                border-color: $primary;
            }
        }
    }

}

.admin__field-label {
    font-family: $font-open-sans-condensed;
    font-size: $font-size-normal;
    font-weight: bold;
    .price-notice {
        font-family: $font-open-sans;
        font-size: $font-size-root;
        font-weight: 400;
        margin: 0 0 0 0.4rem;
    }
}
.options-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .field.choice {
        position: relative;
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        margin: 0 0.8rem 0 0;
    }
}
.radio.product-custom-option,
.radio-no-details {
    position: absolute;
    left: -9999px;
    & + .label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
        width: auto;
        height: auto;
        font-size: $font-size-normal;
        margin: 0 2.3rem 0 0;
    }

    & + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 2rem;
        height: 2rem;
        border: $border-red;
        border-radius: 50%;
        background: #fff;
    }
    & + label:after {
        content: "";
        width: 1rem;
        height: 1rem;
        background: $primary;
        position: absolute;
        top: 5px;
        left: 5px;
        border-radius: 50%;
        transition: all 0.2s ease;
    }
    &:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
    }
    &:checked + label:after {
        opacity: 1;
        transform: scale(1);
    }
}

.admin__control-checkbox ~ .option_images_gallery {
    order: 1;
    img {
        margin-right: 10px;
    }
}

.radio-details.admin__control-checkbox {
    position: relative;
    visibility: hidden;
    height: 40px;
    width: 40px;
    left: auto;
    order: -1;

    &:before {
        content: "";
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translate(0,-50%);
        width: 2rem;
        height: 2rem;
        border: $border-red;
        background: #fff;
        visibility: visible;
        cursor: pointer;
    }

    &:after {
        content: "";
        width: 1rem;
        height: 1rem;
        background: $primary;
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translate(0,-50%);
        transition: all 0.2s ease;
        visibility: visible;
        cursor: pointer;
    }

    &:not(:checked):after {
        transform: scale(0) translate(0,-50%);
    }

    &:checked:after {
        border: none;

    }
}

.field.choice:has(.admin__control-checkbox) {
    border: 1px solid $primary;
    border-radius: 5px;
    margin-bottom: 10px;
}

.field.choice:has(.admin__control-checkbox:checked + label) {
    border: 1px solid $primary;
}

.product-custom-option.admin__control-checkbox {
    position: relative;
    visibility: hidden;
    height: 40px;
    width: 40px;
    left: auto;

    &:before {
        content: "";
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translate(0,-50%);
        width: 2rem;
        height: 2rem;
        border: $border-red;
        background: #fff;
        visibility: visible;
        cursor: pointer;
    }

    &:after {
        content: "";
        width: 1rem;
        height: 1rem;
        background: $primary;
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translate(0,-50%);
        transition: all 0.2s ease;
        visibility: visible;
        cursor: pointer;
    }

    &:not(:checked):after {
        transform: scale(0) translate(0,-50%);
    }

    &:checked:after {
        border: none;

    }
}

.admin__control-checkbox {
    & + .label {
        height: auto;
        width: auto;
        padding: 10px 15px 10px 0px;
        border: none!important;
        margin: 0;
        order: 1;

        span {
            font-size: 14px;
            vertical-align: middle;
        }

    }

    & + label:before {
        display: none;
        left: 10px;
        left: 999;
        top: 50%;
        transform: translate(0,-50%);
    }

    & + label:after {
        display: none;
        top: calc(50% - 5px);
        left: 15px;
        left: 999;
        transform: translate(0,-50%);
    }
}

.radio-details {
    visibility: hidden;
    position: relative;
    width: 4.3rem;
    height: 4.3rem;
    & + label {
        width: 4.3rem;
        height: 4.3rem;
        margin: 0 0.8rem 0 0;
        background: no-repeat center center;
        background-size: contain;
        font-size: 0;
        position: absolute;
        border: $border-gold;
    }
    &:checked + label {
        border: $border-red;
    }
}
.admin__control-checkbox {
    position: absolute;
    left: -9999px;
    & + .label {
        position: relative;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
    }

    & + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 2rem;
        height: 2rem;
        border: $border-red;
        background: #fff;
    }
    & + label:after {
        content: "";
        width: 1rem;
        height: 1rem;
        background: $primary;
        position: absolute;
        top: 5px;
        left: 5px;
        transition: all 0.2s ease;
    }
    &:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
    }
    &:checked + label:after {
        opacity: 1;
        transform: scale(1);
    }
}
.admin__control-select {
    border: $border-gold;
    width: $full-width;
    max-width: 29.5rem;
}
.input__field {
    border: $border-gold;
    width: 22rem;
    height: 4rem;
    margin: 0 0.8rem 0 0;
    font-family: $font-open-sans;
    font-size: 16px;
    @include mq($screen-xss) {
        width: 25rem;
    }
    @include mq($screen-m) {
        width: 30rem;
        font-size: $font-size-normal;
    }
    &--textarea {
        min-width: 295px;
        @include mq($screen-m) {
            min-width: 270px;
        }
    }
    &::placeholder {
        @include placeholder;
    }
}
.control {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .input__field {
        width: 25rem;
        order: 0;
        @include mq($screen-l) {
            width: 23rem;
        }
        @include mq($screen-xl) {
            width: 13rem;
        }
        @include mq($screen-xlg) {
            width: 18rem;
        }
        &.mage-error {
            order: 0;
            width: 25rem;
            @include mq($screen-l) {
                width: 23rem;
            }
            @include mq($screen-xl) {
                width: 13rem;
            }
            @include mq($screen-xlg) {
                width: 18rem;
            }
        }
        &:focus-visible {
            outline: none;
            border: $border-gold-bold;
        }
    }
    .note {
        font-family: $font-open-sans;
        font-size: $font-size-normal;
        color: $gray2;
        &.mage-error {
            order: 1;
            width: 10%;
            text-align: right;
        }
    }
    .mage-error {
        display: block;
        width: $full-width;
        order: 2;
    }
}
.field.street.admin__control-fields {
    .control {
        justify-content: flex-start;
        align-items: flex-end;
    }
}
div.required label:not(.checkbox__label):after {
    color: $primary;
}
.add-to-cart__action {
    .button--add-to {
        @include btn-primary;
        &:hover,
        &:focus {
            @include btn-primary-hover;
        }
    }
}
.add-to-cart {
    &__wrapper {
        .heading {
            font-family: $font-libre-bold;
            font-size: $font-size-xxlarge;
            text-align: left;
        }
    }
    &__action {
        @include mq($screen-m) {
            flex-flow: row-reverse nowrap;
        }
        button {
            margin: 0;
        }
    }
    &__button-additional {
        &[data-testid="product-compare-button"] {
            display: none;
        }
    }
    .price__value {
        @include price(2.5rem, $primary);
        text-align: right;
        padding: 0;
        &.price__value--old {
            @include price($font-size-large, $gray3);
        }
        .price {
            padding: 0;
        }
    }
}
