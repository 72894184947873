// Breakpoints
$screen-xss: 360px;
$screen-xsm: 414px;
$screen-sm: 640px;
$screen-ml: 992px;
$screen-xlg: 1440px;

// Colors
$white: #fff;
$black: #000;
$gray: #d8d8d8;
$gray2: #bfbfbf;
$gray3: #ccc;
$gray4: #c0c0c0;
$gray5: #dadada;
$gray6: #7b7b7b;
$gray7: #e5e5e5;
$gray8: #bbb8b5;
$green: #229300;
$beige1: #f9f5f1;
$primary: #b21d2b;
$secondary: #c7a17a;
$primary-hover: #8e1722;
$secondary-hover: #dbb68f;
$primary-hover-darker: #711d1c;
$beige2: #ede0d3;
$orange: #ff8400;

// Margins
$margin-xs: 0.6rem;
$margin-small: 0.8rem;
$margin-root: 1rem;
$margin-sm: 1.2rem;
$margin-normal: 1.6rem;
$margin-s: 2rem;
$margin-md: 2.4rem;
$margin-m: 3rem;
$margin-mxl: 3.6rem;
$margin-xlarge: 4rem;
$margin-xlg: 4.5rem;
$margin-xl: 5rem;
$margin-17: 17rem;

// Padding
$padding-xs: 0.5rem;
$padding-small: 0.8rem;
$padding-s: 1rem;
$padding-sm: 1.2rem;
$padding-sn: 1.4rem;
$padding-normal: 1.6rem;
$padding-medium: 2rem;
$padding-md: 2.4rem;
$padding-l: 2.6rem;
$padding-m: $padding-l / 2;
$padding-lg: 3rem;
$padding-large: 3.2rem;
$padding-xlarge: 4rem;
$padding-xxlarge: 5rem;
$padding-15: 15rem;

// Other
$header-margin-bottom: 48px;
$footer-info-min-width: 245px;
$container-desktop-width: 1368px;
$page-wrapper-container-width: 1435px;
$final-price-margin-bottom: $margin-m;

// Fonts
$font-open-sans: "OpenSans", sans-serif;
$font-open-sans-bold: "OpenSansBold", sans-serif;
$font-family-base: "OpenSans", sans-serif;
$font-libre: "LibreBaskerville", serif;
$font-libre-bold: "LibreBaskervilleBold", serif;
$font-open-sans-condensed: "OpenSansCondensed", sans-serif;

$font-size-root: 1rem;
$font-size-normal: 1.2rem;
$font-size-md: 1.4rem;
$font-size-lg: 1.6rem;
$font-size-xlarge: 1.8rem;
$font-size-xxlarge: 2rem;
$font-size-xlg: 2.4rem;
$font-size-xxlg: 3rem;
$font-size-xxxlarge: 4rem;

$font-weight-normal: 400;
$font-weight-bold: 700; 

$line-height-small: 1.4rem;
$line-height-normal: 1.8rem;
$line-height-large: 2.4rem;
$line-height-extra-large: 3rem;
$line-height-xl: 3.8rem;
$line-height-xxl: 4.5rem;

$border-gold: solid 1px $secondary;
$border-red: solid 1px $primary;
$border-gold-bold: solid 2px $secondary;
$border-red-bold: solid 2px $primary;
$border-beige: solid 1px $beige2;

$box-shadow: 0 0 0.5rem $gray2;
$box-shadow-gold: 0 0.4rem 0.8rem 0 $secondary;
$box-shadow-gold-hover: 0 1rem 2rem $secondary;

$full-width: 100%;
$half-width: $full-width / 2;

$width-2: 2rem;
$width-7: 7rem;
$width-10: 10rem;
$width-12: 12rem;
$width-13: 13rem;
$width-15: 15rem;
$width-16: 16rem;
$width-17: 17rem;
$width-19: 19rem;
$width-20: 20rem;
$width-21: 21rem;
$width-25: 25rem;
$width-26: 26rem;
$width-28: 28rem;
$width-30: 30rem;
$width-31: 31rem;
$width-40: 40rem;
$width-42: 42rem;
$width-45: 45rem;
$width-50: 50rem;
$width-52: 52rem;

$transition: all 0.3s ease;

// Snowdog override
%button-before:before {
    background-color: transparent;
}

$checkout-image-size: 10rem;
$checkout-button-size: 3.6rem;
$checkout-icon-size: 1.7rem;
$checkout-quantity-size: 4rem;
$checkout-main-size: 87.2rem;

// Language switcher
$language-switcher-width: 200px;
$sidebar-image-size: 8.4rem;
$sidebar-icon-size: 1.6rem;

$wishlist-icon-size: 7.4rem;
$input-height: 4rem;
$input-icon-size: 1rem;
$select-icon-size: 3rem;

$catalog-heart-icon-image: linear-gradient(to right, $primary 50%, transparent 50%);
$catalog-heart-icon-size: 3.6rem;

$input-width-l: 35rem;
$input-width-m: 28rem;
$customer-input-width: 52rem;
$progress-bar-icon-width: 4.8rem;
$button__after-content: "";