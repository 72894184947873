.cms-block-hero-slider {
    display: flex;
    flex-direction: column;
    // margin-bottom: 40px;
    @include mq($screen-m) {
        // margin-bottom: 100px;
    }
    .slick-track {
        display: flex !important;
        justify-content: center;
    }
    > a {
        order: 2;
        color: $white;
        font-family: $font-open-sans-condensed;
        text-align: center;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 4.8rem;
        text-transform: uppercase;
        display: block;
        background-color: $primary;
        max-width: 100%;
        width: 100%;
        margin: auto;
        @include mq($screen-m) {
            max-width: 260px;
        }
    }
    p {
        font-size: 1.4rem;
        line-height: 2.2rem;
        margin-bottom: 2.4rem!important;
        text-align: center;
        @include mq($screen-m) {
            font-size: 1.6rem;
            line-height: 2.2rem;
            margin-bottom: 4rem!important;
        }
    }
    .hero-slider-wrapper {
        margin-right: -15px;
        @include mq($screen-m) {
            margin-right: 0;
        }
    }
    @media (max-width: 600px) {
        .hero-slide.slick-slide {
            &:last-child {
                .hero-slide-inner {
                    position: relative;
                    background: #F5E6D3;
                    &::after {
                        display: none;
                    }
                    &::before {
                        content: '+';
                        display: inline-block;
                        color: $primary;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 32px;
                        font-weight: 100;
                        border-radius: 50%;
                        width: 64px;
                        height: 64px;
                        background: $white;
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, 0);
                        z-index: 3;
                        top: 23%;
                    }
                }
                .hero-slide-image {
                    visibility: hidden;
                }
                .hero-slide-copy-container {
                    position: absolute;
                    bottom: 22%;
                    max-width: 70%;
                    left: 15%;
                    .hero-slide-heading {
                        color: $primary;
                        font-family: $font-libre-bold;
                        margin: 0;
                    }
                }
            }
        }
    }
    .hero-slide {
        margin: 0 15px;
    }
    .hero-slide-inner {
        display: flex;
        flex-direction: column;
        position: relative;
        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 8px;
            width: 24px;
            height: 41px;
            background: url("../images/slider-item-pointer.svg") center no-repeat;
        }
    }
    .hero-slide-copy-container {
        width: 100%;
    }
    .slick-arrow {
        border-radius: 50%;
        width: 48px;
        height: 48px;
        line-height: 48px;
        font-size: 0;
        background: #F5E6D3 url("../images/slider-arr.svg") center no-repeat;
        position: absolute;
        top: 50%;
        transform: translate(0,-50%);
        margin: 0;
    }
    .slick-dots {
        display: flex;
        justify-content: center;
        align-items: center;
        li {
            list-style: none;
            margin: 0 8px;
            button {
                font-size: 0;
                width: 8px;
                height: 8px;
                line-height: 8px;
                min-height: 8px;
                border-radius: 50%;
                padding: 0;
                background-color: $white;
                border: 1px solid #D8D8D8;
            }
        }
        li.slick-active button {
            background-color: $primary;
            border: 1px solid $primary;
        }
    } 
    .slick-next {
        left: 100%;
    }
    .slick-prev {
        right: 100%;
        transform: translate(0,-50%) rotate(180deg);
    }
    .hero-slide-heading {
        color: $black;
        text-align: center;
        font-family: $font-open-sans;
        font-size: 1.4rem;    
        line-height: 2.2rem;
        margin-bottom: 10px;
        margin: 1.6rem 0 2rem 0;
        @include mq($screen-m) {
            font-size: 1.8rem;
            line-height: 2.4rem;
            margin: 1.6rem 0 3.8rem 0;
        }
    }
}

// ========== styles for using sku widget ==========
.block-hero-slider {
    margin-bottom: 40px;
    @include mq($screen-m) {
        margin-bottom: 100px;
    }
    p {
        font-size: 1.4rem;
        line-height: 2.2rem;
        margin-bottom: 2.4rem!important;
        text-align: center;
        @include mq($screen-m) {
            font-size: 1.6rem;
            line-height: 2.2rem;
            margin-bottom: 4rem!important;
        }
    }
}
.btn-hero-slider {
    .product-sku__image,
    .category-sku__img {
        display: none;
    }
    .category-sku__item {
        h5 {
            margin: 0;
            text-align: center;
        }
        a {
            color: $white;
            font-family: $font-open-sans-condensed;
            text-align: center;
            font-size: 1.4rem;
            font-weight: 700;
            line-height: 4.8rem;
            text-transform: uppercase;
            display: block;
            background-color: $primary;
            max-width: 100%;
            width: 100%;
            margin: auto;
            padding: 0;
            margin-bottom: 1rem;
            @include mq($screen-m) {
                max-width: none;
                width: auto;
                display: inline-block;
                padding: 0 3rem;
                margin-bottom: 0;
            }
        } 
    }
    .product-sku__item {
        .product-sku__title {
            margin: 0;
            text-align: center;
            a {
                color: $white;
                font-family: $font-open-sans-condensed;
                text-align: center;
                font-size: 1.4rem;
                font-weight: 700;
                line-height: 4.8rem;
                text-transform: uppercase;
                display: block;
                background-color: $primary;
                max-width: 100%;
                width: 100%;
                margin: auto;
                padding: 0;
                margin-bottom: 2rem;
                @include mq($screen-m) {
                    max-width: none;
                    width: auto;
                    display: inline-block;
                    padding: 0 3rem;
                    margin-bottom: 3rem;
                }
            } 
        }
    }
} 
.btn-category-primary {
    .category-sku__item {
        h5 {
            margin: 1rem 0;
            max-width: 100%;
            display: inline-block;
            width: 100%;
            @include mq($screen-m) {
                margin: 1rem 0;
                max-width: 260px;
            }
        }
        a {
            color: $white;
            font-family: $font-open-sans-condensed;
            text-align: center;
            font-size: 1.4rem;
            font-weight: 700;
            line-height: 4.8rem;
            text-transform: uppercase;
            display: block;
            background-color: $primary;
            max-width: 100%;
            width: 100%;
            margin: auto;
        } 
    }
} 
.hero-slider {
    .slick-track {
        display: flex !important;
        justify-content: center;
    }
}
.hero-slider {
    margin-right: -15px;
    margin-top: 1.5rem;
    @include mq($screen-m) {
        margin-right: 0;
        margin-top: 3.5rem;
    }
    // .hide-on-desktop {
    //     display: flex!important;
    //     @include mq($screen-m) {
    //         display: none!important;
    //     }
    // }
    .slick-slide {
        margin: 0 15px;
    }
    // .slick-slide:last-child {
    //     display: block;
    //     position: relative;
    //     background: #F5E6D3;
    //     aspect-ratio: 1/1;
    //     &::after {
    //         display: none;
    //     }
    //     &::before {
    //         content: '+';
    //         display: inline-block;
    //         color: $primary;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         font-size: 32px;
    //         font-weight: 100;
    //         border-radius: 50%;
    //         width: 64px;
    //         height: 64px;
    //         background: $white;
    //         position: absolute;
    //         left: 50%;
    //         transform: translate(-50%, 0);
    //         z-index: 3;
    //         top: 23%;
    //     }
    //     h5 {
    //         position: absolute;
    //         bottom: 22%;
    //         max-width: 70%;
    //         left: 50%;
    //         transform: translate(-50%, 0);
    //         color: $primary;
    //         font-family: $font-libre-bold;
    //         margin: 0;
    //         a {
    //             color: $primary;
    //             font-family: $font-libre-bold!important;
    //         }
    //     }
    //     img {
    //         visibility: hidden;
    //     }
    //     @include mq($screen-s) {
    //        display: none;
    //     }
    // } 
    .category-sku__item,
    .product-sku__item {
        display: flex;
        flex-direction: column;
        position: relative;
        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 8px;
            width: 24px;
            height: 41px;
            background: url("../images/slider-item-pointer.svg") center no-repeat;
        }
    }
    .slick-arrow {
        border-radius: 50%;
        width: 48px;
        height: 48px;
        line-height: 48px;
        font-size: 0;
        background: #F5E6D3 url("../images/slider-arr.svg") center no-repeat;
        position: absolute;
        top: 50%;
        transform: translate(0,-50%);
        margin: 0;
    }
    .slick-arrow.slick-disabled {
        opacity: .3;
    }
    .slick-dots {
        display: flex;
        justify-content: center;
        align-items: center;
        li {
            list-style: none;
            margin: 0 8px;
            button {
                font-size: 0;
                width: 8px;
                height: 8px;
                line-height: 8px;
                min-height: 8px;
                border-radius: 50%;
                padding: 0;
                background-color: $white;
                border: 1px solid #D8D8D8;
            }
        }
        li.slick-active button {
            background-color: $primary;
            border: 1px solid $primary;
        }
    } 
    .slick-next {
        left: 100%;
    }
    .slick-prev {
        right: 100%;
        transform: translate(0,-50%) rotate(180deg);
    }
    .category-sku__img,
    .product-sku__image {
        display: block;
        width: 100%;
        aspect-ratio: 1/1;
        border: 0.5px solid #ccc;
        a {
            display: block;
            width: 100%;
        }
        img {
            display: block;
            width: 100%;
            aspect-ratio: 1/1;
        }
    }
    .product-sku__title,
    h5 {
        color: $black;
        text-align: center;
        font-family: $font-open-sans;
        font-size: 1.4rem;    
        line-height: 2.2rem;
        margin-bottom: 10px;
        margin: 1.6rem 0 2rem 0;
        font-weight: 400;
        @include mq($screen-m) {
            font-size: 1.8rem;
            line-height: 2.4rem;
            margin: 1.6rem 0 3.8rem 0;
        }
        a {
            color: $black!important;
            font-family: $font-open-sans;
            font-weight: 400!important;
        }
    }
}