.page-layout-landing-pages-2-col,
.page-layout-landing-pages-3-col {
    div[data-appearance="full-width"]:has(.head-top) {
        margin-bottom: 60px;
        @include mq($screen-m) {
            margin-bottom: 90px;
        }
    }

    .head-top {
        background-color: #F5E6D3;
        color: #000;
        position: absolute;
        bottom: -40px;
        padding: 16px;
        width: calc(100% - 30px);
        z-index: 1;
        height: auto;
        left: 50%;
        transform: translate(-50%, 0);
        @include mq($screen-xlg) {
            left: auto;
            transform: none;
            padding: 16px;
            width: 660px;
            height: 390px;
            padding: 50px;
            bottom: -50px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        h1 {
            font-family: $font-libre-bold;
            color: $primary;
            font-size: 2.4rem;
            line-height: 3rem;
            margin: 0 0 1rem 0!important;
            @include mq($screen-xlg) {
                font-size: 5rem;
                line-height: 6rem;
                margin: 0 0 3rem 0!important;
            }
        }
        p {
            font-family: $font-open-sans;
            color: $black;
            font-size: 1.4rem;
            line-height: 2.2rem;
            margin: 0;
            @include mq($screen-xlg) {
                font-size: 1.8rem;
                line-height: 2.8rem;
            }
        }
    }
}

